import { CircularProgress } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import DashboardLayout from "../LayoutContainers/DashboardLayout";
import DashboardNavbar from "../Navbars/DashboardNavbar";

export const LoadingSpinner = ({ isLoading }) => {
  return (
    <Backdrop
      open={isLoading ?? false}
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, color: "#fff" }}
    >
      <CircularProgress color="info" />
    </Backdrop>
  );
};
