import { useEffect, useMemo, useRef, useState } from "react";

// 3rd libraries
import { toast } from "react-toastify";

// @mui material components
import { Stack, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

// Material Dashboard 2 React components

import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import MDButton from "../../../components/MDButton";
import Table from "../../../components/Tables/Table";

// components

// services
import { deleteProduct, getProducts } from "../../../services/productService";
import { LoadingSpinner } from "../../../components/LoadingSpinner";
import {convertToDate, convertToUtcLongDate} from "../../utils/time";

export const DashboardProductTable = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [maxPage, setMaxPage] = useState();
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    fetchData();
  }, [page, limit]);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const { data, pagination_info } = await getProducts({ page, limit });
      setData(data);
      setFileList([{ preview: data.logo }]);
      setMaxPage(pagination_info.total_page);
      setIsLoading(false);
    } catch (error) {
      console.log("err", error);
      setIsLoading(false);
    }
  };

  const excelColumns = [
    "ID",
    "Name",
    "Color",
    "Color Type",
    "Logo",
    "Created At",
    "Updated At",
  ];

  const excelData = data.map(item => {
    return [
      item.id,
      item.name,
      item.color,
      item.color_type?.name,
      item.logo,
      convertToDate(item.created_at),
      convertToDate(item.updated_at),
    ];
  });

  const columns = useMemo(
    () => [
      {
        Header: "Image",
        accessor: "image",
        align: "left",
        width: 130,
        Cell: ({ row }) => (
          <MDBox display="flex" alignItems="center" lineHeight={1}>
            <img
              src={row.original.logo}
              alt={row.original.name}
              style={{ width: 110, borderRadius: 8 }}
            />
          </MDBox>
        ),
      },
      {
        Header: "Name",
        accessor: "name",
        align: "left",
        width: "100%",
        Cell: ({ row }) => (
          <MDBox lineHeight={1}>
            <MDTypography display="block" variant="button" fontWeight="medium">
              {row.original.name}
            </MDTypography>
          </MDBox>
        ),
      },
      {
        Header: "Color",
        accessor: "color",
        align: "left",
        Cell: ({ row }) => (
          <Stack flexDirection="row">
            <MDBox
              display="flex"
              alignItems="center"
              lineHeight={1}
              sx={{
                background: row.original.color,
                width: "80px",
                height: "30px",
                borderRadius: 2,
              }}
            ></MDBox>
          </Stack>
        ),
      },
    ],
    []
  );

  if (isLoading) return <LoadingSpinner isLoading={isLoading} />;

  return (
    <Table
      title="Products"
      isLoading={isLoading}
      limit={limit}
      setLimit={setLimit}
      setPage={setPage}
      page={page}
      setIsLoading={setIsLoading}
      maxPage={maxPage}
      prevFunc={() => setPage(page - 1)}
      nextFunc={() => setPage(page + 1)}
      columns={columns}
      search={search}
      setSearch={setSearch}
      rows={data}
      excelButton={{
        activeExcel: true,
        excelFunc: () => console.log("excel"),
        excelColumns: excelColumns,
        exportData: excelData,
      }}
    />
  );
};
