import React, { useState } from "react";
// 3rd libraries
import { toast } from "react-toastify";
import { useFormik } from "formik";
// @mui
import { Divider, Grid } from "@mui/material";
import { styled } from "@mui/system";
// components
import MDBox from "../../components/MDBox";
import MDInput from "../../components/MDInput";
import MDButton from "../../components/MDButton";
// services
import { getCategories, updateCategory } from "../../services/categoryService";
// validation
import { validationSchema } from "./validationSchema";

const Text = styled("h4")({
  color: "#203662",
});

export const UpdateCategory = ({
  closeModal,
  setData,
  data,
  setMaxPage,
  limit,
  page,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: data.name,
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const payload = {
        id: data.id,
        name: { tr: values.name },
      };
      try {
        setIsLoading(true);
        await updateCategory(payload);
        const { data, pagination_info } = await getCategories({ limit, page });
        setData(data);
        setMaxPage(pagination_info.total_page);
        toast.success("Category added successfully.");
        setIsLoading(false);
        closeModal();
      } catch (error) {
        toast.error(`Something went wrong. ${error}`);
        setIsLoading(false);
      }
    },
  });

  return (
    <MDBox bgColor="white" sx={{ borderRadius: 4 }}>
      <Text>Update category</Text>
      <Divider />
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} md={12}>
            <MDInput
              fullWidth
              label="Name"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </Grid>

          <Grid item container xs={12} spacing={2}>
            <Grid item xs={7} md={8}>
              <MDButton
                disabled={isLoading}
                variant="contained"
                color="info"
                fullWidth
                type="submit"
              >
                Save
              </MDButton>
            </Grid>
            <Grid item xs={5} md={4}>
              <MDButton
                disabled={isLoading}
                variant="outlined"
                color="error"
                fullWidth
                onClick={closeModal}
              >
                Cancel
              </MDButton>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </MDBox>
  );
};
