import {  styled, Box } from "@mui/material";

const Container = styled(Box)({
  position: "relative",
  width: 40,
  height: 55,
  borderRadius: 7,
});

const SmallImage = styled(Box)(({ src }) => ({
  backgroundImage: `url(${src})`,
  backgroundSize: "cover",
  width: "100%",
  height: "100%",
  position: "relative",
  zIndex: 1,
  borderRadius: 7,
}));

export const ImageContainer = ({ url, alt }) => {
  return (
    <Container>
      <SmallImage src={url} alt={alt} />
    </Container>
  );
};
export const TitleContainer = styled("div")({
  maxWidth: 400,
  height: 55,
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "wrap",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
});
