import { appInstance } from "./http";
import errorHandler from "./errorHandler";

export const getEventSpeakers = async ({ page, limit, lang, event }) => {
  const payload = {
    params: { event },
  };
  try {
    const { data } = await appInstance(lang).get(
      `/event-speaker/?pagination=1&page=${page}&limit=${limit}`,
      payload
    );
    return data;
  } catch (error) {
    throw errorHandler(error);
  }
};

export const createSection = async ({
  lang,
  event,
  speakers,
  description,
  start_date,
  end_date,
  is_moderator,
}) => {
  const payload = {
    event,
    speakers,
    description,
    start_date,
    end_date,
    is_moderator,
  };
  try {
    const { data } = await appInstance(lang).post(`/event-speaker`, payload);
    return data;
  } catch (error) {
    throw errorHandler(error);
  }
};

export const updateSection = async ({
  lang,
  event,
  id,
  speakers,
  description,
  start_date,
  end_date,
  is_moderator,
}) => {
  const payload = {
    event,
    speakers,
    description,
    start_date,
    end_date,
    is_moderator,
  };
  try {
    const { data } = await appInstance(lang).put(
      `/event-speaker/${id}`,
      payload
    );
    return data;
  } catch (error) {
    throw errorHandler(error);
  }
};

export const deleteSection = async ({ lang, id }) => {
  try {
    const { data } = await appInstance(lang).delete(`/event-speaker/${id}`);
    return data;
  } catch (error) {
    throw errorHandler(error);
  }
};
