import { userInstance } from "./http";
import errorHandler from "./errorHandler";

export const getUsers = async (lang) => {
  try {
    const { data } = await userInstance(lang).get(`/me`);
    return data;
  } catch (error) {
    throw errorHandler(error);
  }
};

export const getAllUser = async ({ status, page, limit, lang }) => {
  try {
    const { data } = await userInstance(lang).get(
      `/filter/${status}?pagination=1&page=${page}&limit=${limit}&order_desc=1`
    );
    return data;
  } catch (error) {
    throw errorHandler(error);
  }
};

export const getUsersByFilter = async ({ page, limit, search }) => {
  try {
    const { data } = await userInstance.get(
      `/me?page=${page}&perpage=${limit}&search=${search}`
    );
    return data;
  } catch (error) {
    throw errorHandler(error);
  }
};

export const getUserById = async ({ id, lang }) => {
  try {
    const { data } = await userInstance(lang).get(`/${id}`);
    return data;
  } catch (error) {
    throw errorHandler(error);
  }
};

export const approveUser = async ({ id, lang }) => {
  try {
    const { data } = await userInstance(lang).put(`/approve/${id}`);
    return data;
  } catch (error) {
    throw errorHandler(error);
  }
};

export const rejectUser = async ({ id, lang }) => {
  try {
    const { data } = await userInstance(lang).put(`/reject/${id}`);
    return data;
  } catch (error) {
    throw errorHandler(error);
  }
};

export const unApproveUser = async ({ id, lang, message }) => {
  try {
    const { data } = await userInstance(lang).put(`/un-approve/${id}`, {
      message,
    });
    return data;
  } catch (error) {
    throw errorHandler(error);
  }
};
