import * as yup from "yup";

export const validationSchema = yup.object({
  name_surname: yup
    .string("Name and surname cannot be left empty")
    .required("Name and surname is required"),
  institution: yup
    .string("Instution cannot be left empty")
    .required("Instution is required"),
  title: yup
    .object()
    .shape({
      label: yup.string().required("Title is required"),
      value: yup.string().required("Title is required"),
    })
    .required(),
  branch: yup
    .object()
    .shape({
      label: yup.string().required("Branch is required"),
      value: yup.string().required("Branch is required"),
    })
    .required(),
});
