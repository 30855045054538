import { appInstance } from "./http";
import errorHandler from "./errorHandler";

export const getUrlType = async (lang) => {
  try {
    const { data } = await appInstance(lang).get("/url-type");
    return data;
  } catch (error) {
    throw errorHandler(error);
  }
};

export const getBannerType = async (lang) => {
  try {
    const { data } = await appInstance(lang).get("/banner-type");
    return data;
  } catch (error) {
    throw errorHandler(error);
  }
};

export const getCity = async (lang) => {
  try {
    const { data } = await appInstance(lang).get("/city");
    return data;
  } catch (error) {
    throw errorHandler(error);
  }
};

export const getEventType = async (lang) => {
  try {
    const { data } = await appInstance(lang).get("/event-type");
    return data;
  } catch (error) {
    throw errorHandler(error);
  }
};

export const getAppProducts = async (lang) => {
  try {
    const { data } = await appInstance(lang).get("/product");
    return data;
  } catch (error) {
    throw errorHandler(error);
  }
};

export const getColorTypes = async (lang) => {
  try {
    const { data } = await appInstance(lang).get("/color-type");
    return data;
  } catch (error) {
    throw errorHandler(error);
  }
};

export const getAppCategories = async (lang) => {
  try {
    const { data } = await appInstance(lang).get("/category");
    return data;
  } catch (error) {
    throw errorHandler(error);
  }
};

export const getAppBranches = async (lang) => {
  try {
    const { data } = await appInstance(lang).get("/branch");
    return data;
  } catch (error) {
    throw errorHandler(error);
  }
};

export const getAppBanner = async (lang) => {
  try {
    const { data } = await appInstance(lang).get("/banner");
    return data;
  } catch (error) {
    throw errorHandler(error);
  }
};
export const getEventQuestions = async ({ lang, limit, page, event }) => {
  try {
    const { data } = await appInstance(lang).get(
      `/event-question?pagination=1&page=${page}&limit=${limit}&order_desc=1&event=${event}`
    );
    return data;
  } catch (error) {
    throw errorHandler(error);
  }
};

// Event Participant Service
export const getEventParticipant = async ({
  lang,
  limit,
  page,
  filter,
  event,
}) => {
  try {
    const { data } = await appInstance(lang).get(
      `/event-participant/filter/${filter}?pagination=1&page=${page}&limit=${limit}&order_desc=1&event=${event}`
    );
    return data;
  } catch (error) {
    throw errorHandler(error);
  }
};

export const approveParticipant = async ({ lang, id }) => {
  try {
    const { data } = await appInstance(lang).put(
      `/event-participant/approve/${id}`
    );
    return data;
  } catch (error) {
    throw errorHandler(error);
  }
};

export const rejectParticipant = async ({ lang, id }) => {
  try {
    const { data } = await appInstance(lang).put(
      `/event-participant/reject/${id}`
    );
    return data;
  } catch (error) {
    throw errorHandler(error);
  }
};

export const awsUploadS3 = async ({ lang, file }) => {
  const formData = new FormData();
  formData.append("file", file);
  try {
    const { data } = await appInstance(lang).post("/aws/upload-s3", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return data;
  } catch (error) {
    throw errorHandler(error);
  }
};

export const getEventQuestion = async ({
  lang,
  event,
  limit,
  page,
  order_desc = 1,
}) => {
  try {
    const { data } = await appInstance(lang).get(
      `/event-question?pagination=1&page=${page}&limit=${limit}&order_desc=${order_desc}&event=${event}`
    );
    return data;
  } catch (error) {
    throw errorHandler(error);
  }
};
