import React, { useEffect, useState } from "react";

//3rd libraries
import { toast } from "react-toastify";
import { useFormik } from "formik";
import Select from "react-select";

// @mui
import { styled } from "@mui/system";
import { Divider, Grid } from "@mui/material";

// components
import MDBox from "../../../components/MDBox";
import MDInput from "../../../components/MDInput";
import MDButton from "../../../components/MDButton";
import Uploader from "../../../components/Uploader";

// services
import { getTitles } from "../../../services/titleService";
import { getBranches } from "../../../services/branchService";
import { createSpeaker } from "../../../services/speakersService";

// styles
import { styles } from "./style";

// validation
import { validationSchema } from "./validationSchema";

const Text = styled("h4")({
  color: "#203662",
});

export const AddSpeaker = ({ closeModal, setData, closeFunc }) => {
  const [loading, setLoading] = useState(false);
  const [fileList, setFiles] = useState([]);
  const [title, setTitle] = useState([]);
  const [branch, setBranch] = useState([]);
  const [image, setImage] = useState(null);

  const handleOnClose = () => {
   closeModal?.();
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const data = await Promise.all([getBranches(), getTitles()]);
      setBranch(data[0]);
      setTitle(data[1]);
    } catch (error) {
      toast.error("Kullanıcı verileri çekilemedi.");
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      name_surname: "",
      institution: "",
      title: null,
      branch: null,
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      if (!image) return toast.error("Please upload an image");
      const payload = {
        name_surname: values.name_surname,
        institution: values.institution,
        branch: values.branch.value,
        title: values.title.value,
        image: image,
      };
      try {
        setLoading(true);
        const data = await createSpeaker(payload);
        setData((prevState) => [
          ...prevState,
          {
            id: data.id,
            name_surname: data.name_surname,
            institution: data.institution,
            title: values.title.label,
            branch: values.branch.label,
            image: data.image,
          },
        ]);
        toast.success("Speaker created successfully");
        setTimeout(() => {
          closeFunc?.();
        }, 200);
      } catch (error) {
        toast.error(`Something went wrong. ${error}`);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <MDBox bgColor="white" sx={{ borderRadius: 4 }}>
      <Text>Add new speaker</Text>
      <Divider />
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} md={4}>
            <Uploader
              fileList={fileList}
              onFileChange={(e) => setFiles(e)}
              setLoading={setLoading}
              loading={loading}
              setImage={setImage}
              width="173px"
              height="220px"
            />
          </Grid>
          <Grid item container xs={12} md={8} spacing={2}>
            <Grid item xs={12}>
              <MDInput
                fullWidth
                label="Name surname"
                name="name_surname"
                value={formik.values.name_surname}
                onChange={formik.handleChange}
                error={
                  formik.touched.name_surname &&
                  Boolean(formik.errors.name_surname)
                }
                helperText={
                  formik.touched.name_surname && formik.errors.name_surname
                }
              />
            </Grid>

            <Grid item xs={12}>
              <MDInput
                fullWidth
                label="Institution"
                name="institution"
                value={formik.values.institution}
                onChange={formik.handleChange}
                error={
                  formik.touched.institution &&
                  Boolean(formik.errors.institution)
                }
                helperText={
                  formik.touched.institution && formik.errors.institution
                }
              />
            </Grid>

            <Grid item xs={12}>
              <Select
                fullWidth
                placeholder="Title"
                styles={styles}
                name="title"
                menuPlacement="top"
                noOptionsMessage={({ inputValue }) =>
                  `${inputValue || "Title"} not found`
                }
                value={formik.values.title}
                onChange={(value) => formik.setFieldValue("title", value)}
                options={title?.map((item) => {
                  return {
                    label: item.name,
                    value: item.id,
                  };
                })}
                error={formik.touched.title && Boolean(formik.errors.title)}
                helperText={
                  formik.touched.institution && formik.errors.institution
                }
              />
              {formik.touched.title && formik.errors.title && (
                <div
                  style={{
                    color: "#DF2B1C",
                    marginTop: ".1rem",
                    fontSize: 12,
                    marginLeft: 15,
                    fontWeight: "lighter",
                  }}
                >
                  {"You must make at least one choice"}
                </div>
              )}
            </Grid>

            <Grid item xs={12}>
              <Select
                fullWidth
                placeholder="Branches"
                styles={styles}
                menuPlacement="top"
                noOptionsMessage={({ inputValue }) =>
                  `${inputValue || "Branch"} not found`
                }
                value={formik.values.branch}
                onChange={(value) => formik.setFieldValue("branch", value)}
                options={branch?.map((item) => {
                  return {
                    label: item.name,
                    value: item.id,
                  };
                })}
                error={formik.touched.branch && Boolean(formik.errors.branch)}
              />
              {!!formik.touched.branch && formik.errors.branch && (
                <div
                  style={{
                    color: "#DF2B1C",
                    marginTop: ".1rem",
                    fontSize: 12,
                    marginLeft: 15,
                    fontWeight: "lighter",
                  }}
                >
                  {"You must make at least one choice"}
                </div>
              )}
            </Grid>
          </Grid>

          <Grid item container xs={12} spacing={2}>
            <Grid item xs={7} md={8}>
              <MDButton
                disabled={loading}
                variant="contained"
                color="info"
                fullWidth
                type="submit"
              >
                Save
              </MDButton>
            </Grid>
            <Grid item xs={5} md={4}>
              <MDButton
                disabled={loading}
                variant="outlined"
                color="error"
                fullWidth
                onClick={closeFunc}
              >
                Cancel
              </MDButton>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </MDBox>
  );
};
