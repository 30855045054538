import * as yup from "yup";

export const validationSchema = yup.object({
  status: yup
    .object()
    .nullable()
    .shape({
      label: yup.string().required("Status is required"),
      value: yup.string().required("Status is required"),
    })
    .required(),
});
