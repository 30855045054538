import { bannerInstance } from "./http";
import errorHandler from "./errorHandler";

export const getBanners = async ({ page, limit, lang }) => {
  try {
    const { data } = await bannerInstance(lang).get(
      `/?pagination=1&page=${page}&limit=${limit}&order_desc=1`
    );
    return data;
  } catch (error) {
    const err = errorHandler(error);
    throw err;
  }
};

export const getBanner = async ({ id, lang, isCrud }) => {
  const payload = {
    isCrud,
  };
  try {
    const { data } = await bannerInstance(lang).get(`/${id}`, payload);
    return data;
  } catch (error) {
    const err = errorHandler(error);
    throw err;
  }
};
export const createBanner = async ({ name, image, lang }) => {
  const payload = {
    name,
    image,
  };
  try {
    const { data } = await bannerInstance(lang).post(`/`, payload);
    return data;
  } catch (error) {
    const err = errorHandler(error);
    throw err;
  }
};

export const updateBanner = async ({ id, name, image, lang }) => {
  const payload = {
    name,
    image,
  };
  try {
    const { data } = await bannerInstance(lang).put(`/${id}`, payload);
    return data;
  } catch (error) {
    const err = errorHandler(error);
    throw err;
  }
};

export const deleteBanner = async ({ id, lang }) => {
  try {
    const { data } = await bannerInstance(lang).delete(`/${id}`);
    return data;
  } catch (error) {
    const err = errorHandler(error);
    throw err;
  }
};
