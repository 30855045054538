import React, { useEffect, useRef, useState } from "react";
import Plyr from "plyr";
import "plyr/dist/plyr.css";
import DashboardLayout from "../../components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../components/Navbars/DashboardNavbar";
import MDBox from "../../components/MDBox";
import { Card } from "@mui/material";

function PlyrPlayer() {
  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const PlyrRef = useRef(null);

  const { IVSPlayer } = window;
  const { isPlayerSupported } = IVSPlayer;
  const { READY } = IVSPlayer.PlayerState;

  useEffect(() => {
    const init = async () => {
      if (videoRef.current && !playerRef.current) {
        const videoElement = videoRef.current;

        if (!videoElement) return;

        playerRef.current = await IVSPlayer.create();

        playerRef.current.attachHTMLVideoElement(videoRef.current);
        playerRef.current.load(
          "https://fcc3ddae59ed.us-west-2.playback.live-video.net/api/video/v1/us-west-2.893648527354.channel.DmumNckWFTqz.m3u8"
        );

        playerRef.current.addEventListener(READY, onStateChange);
      }
    };

    init();
  }, [videoRef]);

  const onStateChange = () => {
    if (!PlyrRef.current) {
      const availableQualities = playerRef.current.getQualities();
      const qualityOptions = availableQualities.map((l) => l.height);

      const defaultOptions = {
        controls: [
          "play-large",
          "play",
          "mute",
          "volume",
          "settings",
          "pip",
          "airplay",
          "fullscreen",
        ],
        settings: ["quality"],
      };
      defaultOptions.quality = {
        default: qualityOptions[0],
        options: qualityOptions,
        forced: true,
        onChange: (e) => {
          const match = availableQualities.find((x) => x.height === e);
          if (match) {
            console.log("Selected Quality -> ", match);
            playerRef.current.setQuality(match);
          }
        },
      };

      const player = new Plyr("#amazon-ivs-plyr", defaultOptions);
      PlyrRef.current = player;
    }
  };

  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player) {
        player.delete();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox p={2}>
        <Card>
          <MDBox p={3}>
            <video
              id="amazon-ivs-plyr"
              controls
              autoPlay
              playsInline
              ref={videoRef}
            ></video>
          </MDBox>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default PlyrPlayer;
