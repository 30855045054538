import * as yup from "yup";

export const validationSchema = yup.object({
  switch: yup.boolean(),
  description: yup.string().when('switch', {
    is: true,
    then: yup.string().optional(),
    otherwise: yup.string().required('Description is required'),
  }),
  speakers: yup
  .array()
  .nullable()
  .min(1, "Speaker is required")
  .of(
      yup.object().shape({
        label: yup.string().required(),
        value: yup.string().required(),
      })
  ).required(),
  start_date: yup.string().required("Start date is required"),
  end_date: yup.string().required("End date is required"),
});
