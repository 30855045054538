import { v4 as uuidv4 } from 'uuid';

export const getLocalStorage = (key) => {
  const data = window.localStorage.getItem(key);
  try {
    return JSON.parse(data);
  } catch (_) {
    return data;
  }
};

export const setLocalStorage = (key, value) => {
  window.localStorage.setItem(key, JSON.stringify(value));
};

export const removeLocalStorage = (key) => {
  window.localStorage.removeItem(key);
};

export const getDeviceId = () => {
  return getLocalStorage('device_uuid') || (setLocalStorage('device_uuid', uuidv4()), getLocalStorage('device_uuid'))
};
    