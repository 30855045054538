import { Divider, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import MDBox from "../../../components/MDBox";
import MDInput from "../../../components/MDInput";
import { validationSchema } from "./validationSchema";
import MDButton from "../../../components/MDButton";
import Uploader from "../../../components/Uploader";
import { styled } from "@mui/system";
import Select from "react-select";
import { styles } from "./style";
import { getTitles } from "../../../services/titleService";
import { getBranches } from "../../../services/branchService";
import {
  getSpeaker,
  getSpeakers,
  updateSpeaker,
} from "../../../services/speakersService";

const Text = styled("h4")({
  color: "#203662",
});

export const UpdateSpeaker = ({
  closeModal,
  setData,
  selectedUser,
  setMaxPage,
  page,
  limit,
}) => {
  const [loading, setLoading] = useState(false);
  const [fileList, setFiles] = useState([
    { preview: selectedUser.original.image },
  ]);
  const [titles, setTitles] = useState([]);
  const [branches, setBranches] = useState([]);

  const [speaker, setSpeaker] = useState([]);
  const [image, setImage] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const data = await Promise.all([
        getBranches(),
        getTitles(),
        getSpeaker({ id: selectedUser.original.id }),
      ]);
      setBranches(data[0]);
      setTitles(data[1]);
      setSpeaker(data[2]);
    } catch (error) {
      toast.error("Something went wrong.");
    } finally {
      setLoading(false);
    }
  };

  const { title, branch } = speaker;
  const titleId = title && title.id ? title.id : "";
  const branchId = branch && branch.id ? branch.id : "";

  const formik = useFormik({
    initialValues: {
      name_surname: selectedUser.original.name_surname,
      institution: selectedUser.original.institution,
      title: { label: selectedUser.original.title, value: titleId },
      branch: { label: selectedUser.original.branch, value: branchId },
      image: [{ preview: selectedUser.original.image }],
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (!values.image && !image)
        return toast.error("Please upload an image.");
      const payloadWithImage = {
        id: selectedUser.original.id,
        name_surname: values.name_surname,
        institution: values.institution,
        branch: values.branch.value,
        title: values.title.value,
        image: image,
      };
      const payload = {
        id: selectedUser.original.id,
        name_surname: values.name_surname,
        institution: values.institution,
        branch: values.branch.value,
        title: values.title.value,
      };
      try {
        setLoading(true);
        image
          ? await updateSpeaker(payloadWithImage)
          : await updateSpeaker(payload);
        const { data, pagination_info } = await getSpeakers({ page, limit });
        setData(
          data.map((item) => ({
            id: item.id,
            image: item.image,
            institution: item.institution,
            name_surname: item.name_surname,
            title: item.title.name,
            branch: item.branch.name,
          }))
        );
        setMaxPage(pagination_info.total_page);
        setTimeout(() => {
          closeModal?.();
        }, 200);
        toast.success("Speaker updated successfully.");
      } catch (error) {
        toast.error(`Something went wrong. ${error}`);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <MDBox bgColor="white" sx={{ borderRadius: 4 }}>
      <Text>Update new speaker</Text>
      <Divider />
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} md={6}>
            <Uploader
              width="173px"
              height="220px"
              fileList={fileList}
              onFileChange={(e) => setFiles(e)}
              loading={loading}
              setLoading={setLoading}
              setImage={setImage}
            />
          </Grid>
          <Grid item container xs={12} md={6} spacing={2}>
            <Grid item xs={12}>
              <MDInput
                fullWidth
                label="Ad soyad"
                name="name_surname"
                value={formik.values.name_surname}
                onChange={formik.handleChange}
                error={
                  formik.touched.name_surname &&
                  Boolean(formik.errors.name_surname)
                }
                helperText={
                  formik.touched.name_surname && formik.errors.name_surname
                }
              />
            </Grid>

            <Grid item xs={12}>
              <MDInput
                fullWidth
                label="Kurum"
                name="institution"
                value={formik.values.institution}
                onChange={formik.handleChange}
                error={
                  formik.touched.institution &&
                  Boolean(formik.errors.institution)
                }
                helperText={
                  formik.touched.institution && formik.errors.institution
                }
              />
            </Grid>

            <Grid item xs={12}>
              <Select
                required
                fullWidth
                placeholder="Ünvan"
                styles={styles}
                name="title"
                menuPlacement="top"
                noOptionsMessage={({ inputValue }) =>
                  `${inputValue || "Branş"} bulunamadı`
                }
                value={formik.values.title}
                onChange={(value) => formik.setFieldValue("title", value)}
                options={titles?.map((item) => {
                  return {
                    label: item.name,
                    value: item.id,
                  };
                })}
                error={formik.touched.title && Boolean(formik.errors.title)}
                helperText={formik.touched.title && formik.errors.title}
              />
              {formik.touched.title && formik.errors.title && (
                <div
                  style={{
                    color: "#DF2B1C",
                    marginTop: ".1rem",
                    fontSize: 12,
                    marginLeft: 15,
                    fontWeight: "lighter",
                  }}
                >
                  {"En az bir seçim yapmalısınız"}
                </div>
              )}
            </Grid>

            <Grid item xs={12}>
              <Select
                required
                fullWidth
                placeholder="Branşlar"
                styles={styles}
                menuPlacement="top"
                noOptionsMessage={({ inputValue }) =>
                  `${inputValue || "Branş"} bulunamadı`
                }
                value={formik.values.branch}
                onChange={(value) => formik.setFieldValue("branch", value)}
                options={branches?.map((item) => {
                  return {
                    label: item.name,
                    value: item.id,
                  };
                })}
                error={formik.touched.branch && Boolean(formik.errors.branch)}
              />
              {!!formik.touched.branch && formik.errors.branch && (
                <div
                  style={{
                    color: "#DF2B1C",
                    marginTop: ".1rem",
                    fontSize: 12,
                    marginLeft: 15,
                    fontWeight: "lighter",
                  }}
                >
                  {"En az bir seçim yapmalısınız"}
                </div>
              )}
            </Grid>
          </Grid>

          <Grid item container xs={12} spacing={2}>
            <Grid item xs={7} md={8}>
              <MDButton
                disabled={loading}
                variant="contained"
                color="info"
                fullWidth
                type="submit"
              >
                Kaydet
              </MDButton>
            </Grid>
            <Grid item xs={5} md={4}>
              <MDButton
                disabled={loading}
                variant="outlined"
                color="error"
                fullWidth
                onClick={closeModal}
              >
                İptal et
              </MDButton>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </MDBox>
  );
};
