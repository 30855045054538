/**
 =========================================================
 * Material Dashboard 2 React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "../../../MDBox";
import MDTypography from "../../../MDTypography";
import { ExpandCircleDown } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";

function ComplexStatisticsCard({
  color,
  title,
  count,
  percentage,
  icon,
  clickCount,
  lwCount,
  is_active = true,
}) {
  return (
    <Card sx={{ boxShadow: is_active ?  5 : 0.5, cursor: "pointer" }} onClick={clickCount}>
      <MDBox display="flex" justifyContent="space-between" pt={1} px={2} shadow={is_active ?  "possibleBlue" : "active"}>
        <MDBox
          variant="gradient"
          bgColor={color}
          color={color === "light" ? "dark" : "white"}
          coloredShadow={color}
          borderRadius="xl"
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="4rem"
          height="4rem"
          mt={-3}
        >
          <Icon fontSize="medium" color="inherit">
            {icon}
          </Icon>
        </MDBox>
        <MDBox textAlign="right" lineHeight={1.25}>
          <MDTypography variant="button" fontWeight="light" color="text">
            {title}
          </MDTypography>
          <MDTypography variant="h4" onClick={clickCount}>
            {count}
          </MDTypography>
        </MDBox>
      </MDBox>
      <Divider />
      {/*<MDBox*/}
      {/*  pb={2}*/}
      {/*  px={2}*/}
      {/*  sx={{*/}
      {/*    display: "flex",*/}
      {/*    flexDirection: "row",*/}
      {/*  }}*/}
      {/*>*/}
      {/*  /!*<MDTypography*!/*/}
      {/*  /!*  component="p"*!/*/}
      {/*  /!*  variant="button"*!/*/}
      {/*  /!*  color="text"*!/*/}
      {/*  /!*  display="flex"*!/*/}
      {/*  /!*>*!/*/}
      {/*  /!*  <Tooltip title={`Last week ${lwCount ?? 0}`}>*!/*/}
      {/*  /!*    <MDTypography*!/*/}
      {/*  /!*      component="span"*!/*/}
      {/*  /!*      variant="button"*!/*/}
      {/*  /!*      fontWeight="bold"*!/*/}
      {/*  /!*      color={percentage.color}*!/*/}
      {/*  /!*    >*!/*/}
      {/*  /!*      {percentage.amount}*!/*/}
      {/*  /!*    </MDTypography>*!/*/}
      {/*  /!*  </Tooltip>*!/*/}
      {/*  /!*  &nbsp;{percentage.label}*!/*/}
      {/*  /!*</MDTypography>*!/*/}
      {/*  <IconButton size="medium" onClick={clickCount} sx={{ marginLeft: "auto", mt: -1 }}>*/}
      {/*    <ExpandCircleDown color={is_active ?  "possibleBlue" : "active"} />*/}
      {/*  </IconButton>*/}
      {/*</MDBox>*/}
    </Card>
  );
}

// Setting default values for the props of ComplexStatisticsCard
ComplexStatisticsCard.defaultProps = {
  color: "info",
  percentage: {
    color: "success",
    text: "",
    label: "",
  },
};

// Typechecking props for the ComplexStatisticsCard
ComplexStatisticsCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  title: PropTypes.string.isRequired,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  percentage: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "white",
    ]),
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
  }),
  icon: PropTypes.node.isRequired,
};

export default ComplexStatisticsCard;
