import React, { useEffect, useState } from "react";

//3rd libraries
import { toast } from "react-toastify";
import { useFormik } from "formik";
import Select from "react-select";

// @mui
import { Divider, FormControlLabel, Grid, TextField } from "@mui/material";

// components
import MDBox from "../../../../components/MDBox";
import MDInput from "../../../../components/MDInput";
import MDButton from "../../../../components/MDButton";

// services
import { getAllSpeaker } from "../../../../services/speakersService";
import { createSection } from "../../../../services/eventSpeakerService";

// styles
import { styles } from "./style";

// validation
import { validationSchema } from "./validationSchema";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {convertToDateTime, convertToFullDate} from "../../../utils/time";
import { getEvent } from "../../../../services/eventService";
import { CustomSwitch, TextTitle } from "./tableItems";
import {ErrorText, ErrorTextLong} from "../../../../components/ErrorText";

export const AddEventSection = ({ closeModal, event, setData }) => {
  const [loading, setLoading] = useState(false);
  const [speaker, setSpeaker] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const data = await getAllSpeaker();
      setSpeaker(data);
    } catch (error) {
      toast.error("Data fetch error");
    } finally {
      setLoading(false);
    }
  };



  const formik = useFormik({
    initialValues: {
      description: "",
      speakers: null,
      start_date: convertToDateTime(event.start_date),
      end_date: convertToDateTime(event.end_date),
      switch: false,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const payload = {
        speakers: values.speakers.map((item) => item.value),
        event: event.id,
        start_date: convertToFullDate(values.start_date),
        end_date: convertToFullDate(values.end_date),
        description: values.switch
          ? { tr: "Moderator" }
          : { tr: values.description },
        is_moderator: values.switch,
      };
      try {
        setLoading(true);
        await createSection(payload);
        const data = await getEvent({ id: event.id });
        setData(data);
        toast.success("Session created successfully");
        closeModal?.();
      } catch (error) {
        toast.error(`Something went wrong. ${error}`);
      } finally {
        setLoading(false);
      }
    },
  });


  const options = speaker?.map((item) => {
    return {
      label: `${item.title.name} ${item.name_surname}`,
      value: item.id,
    };
  });

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MDBox bgColor="white" sx={{ borderRadius: 4 }}>
        <TextTitle>Add new session</TextTitle>
        <form onSubmit={formik.handleSubmit}>
          <Divider />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {!formik.values.switch && (
                <Grid item container spacing={2} xs={12}>
                  <Grid item xs={6}>
                    <DateTimePicker
                      label="Start Date"
                      value={formik.values.start_date}
                      onChange={(value) =>
                        formik.setFieldValue("start_date", value)
                      }
                      ampm={false}
                      format="YYYY-MM-DD HH:mm:ss"
                      renderInput={(params) => <TextField {...params} />}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.start_date &&
                        Boolean(formik.errors.start_date)
                      }
                      minTime={dayjs(convertToDateTime(event.start_date))}
                      maxTime={dayjs(convertToDateTime(event.end_date))}
                    />
                    {!!formik.touched.start_date &&
                      formik.errors.start_date && (
                        <ErrorText text={formik.errors.start_date} />
                      )}
                  </Grid>
                  <Grid item xs={6}>
                    <DateTimePicker
                      label="End Date"
                      ampm={false}
                      format="YYYY-MM-DD HH:mm:ss"
                      value={formik.values.end_date}
                      onChange={(value) =>
                        formik.setFieldValue("end_date", value)
                      }
                      renderInput={(params) => <TextField {...params} />}
                      minTime={dayjs(convertToDateTime(event.start_date))}
                      maxTime={dayjs(convertToDateTime(event.end_date))}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.end_date &&
                        Boolean(formik.errors.end_date)
                      }
                    />
                    {!!formik.touched.end_date && formik.errors.end_date && (
                      <div
                        style={{
                          color: "#DF2B1C",
                          marginTop: ".1rem",
                          fontSize: 12,
                          marginLeft: 15,
                          fontWeight: "lighter",
                        }}
                      >
                        {formik.errors.end_date}
                      </div>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <MDInput
                      fullWidth
                      multiline
                      label="Description"
                      name="description"
                      value={formik.values.description}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.description &&
                        Boolean(formik.errors.description)
                      }
                      helperText={
                        formik.touched.description && formik.errors.description
                      }
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid item xs={12}>
              <Select
                fullWidth
                placeholder="Speaker"
                styles={styles}
                name="speakers"
                isMulti
                noOptionsMessage={({ inputValue }) =>
                  `${inputValue || "Speakers"} not found`
                }
                value={formik.values.speakers}
                onChange={(value) => formik.setFieldValue("speakers", value)}
                options={options}
                error={
                  formik.touched.speakers && Boolean(formik.errors.speakers)
                }
              />
              {formik.touched.speakers && formik.errors.speakers && (
                <ErrorTextLong text="Speaker is required" />
              )}
            </Grid>

            <Grid item xs={4}>
              <FormControlLabel
                control={
                  <CustomSwitch
                    color="possibleBlue"
                    checked={formik.values.switch}
                    onChange={formik.handleChange}
                    name="switch"
                  />
                }
                label="Moderator"
              />
            </Grid>

            <Grid item container xs={12} spacing={2}>
              <Grid item xs={7} md={8}>
                <MDButton
                  disabled={loading}
                  variant="contained"
                  color="info"
                  fullWidth
                  type="submit"
                >
                  Save
                </MDButton>
              </Grid>
              <Grid item xs={5} md={4}>
                <MDButton
                  disabled={loading}
                  variant="outlined"
                  color="error"
                  fullWidth
                  onClick={closeModal}
                >
                  Cancel
                </MDButton>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </MDBox>
    </LocalizationProvider>
  );
};
