import { useEffect, useRef, useState } from "react";

// 3rd libraries
import { toast } from "react-toastify";

// @mui
import { Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

// Material Dashboard 2 React components
import Modal from "../../components/Modal";
import DashboardLayout from "../../components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../components/Navbars/DashboardNavbar";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import MDButton from "../../components/MDButton";
import Table from "../../components/Tables/Table";

// components
import { AddCategory } from "./addCategory";
import { UpdateCategory } from "./updateCatogry";

// services
import { deleteCategory, getCategories } from "../../services/categoryService";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { ConfirmModal } from "../../components/ConfirmModal";

export const Categories = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [maxPage, setMaxPage] = useState(1);
  const [data, setData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [search, setSearch] = useState("");
  const [modal, setModal] = useState(false);
  const [idX, setIdX] = useState("");

  const addCategoryRef = useRef(null);
  const updateCategoryRef = useRef(null);

  useEffect(() => {
    fetchData();
  }, []);

  const handleConfirm = (id) => {
    deleteSectionRef?.current.open();
    setIdX(id);
  };

  const deleteSectionRef = useRef(null);
  const fetchData = async () => {
    try {
      setIsLoading(true);
      const { data, pagination_info } = await getCategories({ page, limit });
      setData(data);
      setMaxPage(pagination_info.total_page);
      setIsLoading(false);
    } catch (error) {
      console.log("err", error);
      setIsLoading(false);
    }
  };

  const removeCategory = async () => {
    const payload = { id: idX };
    try {
      setIsLoading(true);
      await deleteCategory(payload);
      fetchData();
      toast.success("Category deleted successfully");
    } catch (error) {
      toast.error("Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  const updateCategory = (e) => {
    setSelectedCategory(e);
    updateCategoryRef.current?.open();
  };

  const columns = [
    {
      Header: "Name",
      accessor: "name",
      width: "90%",
      align: "left",
      Cell: ({ row }) => (
        <MDTypography variant="title1" fontWeight="medium">
          {row.original.name}
        </MDTypography>
      ),
    },
    {
      Header: "Actions",
      align: "left",
      Cell: ({ row }) => (
        <>
          <Tooltip title="Update">
            <MDButton
              size="small"
              sx={{ marginRight: 1, maxWidth: 30, minWidth: 30, width: 30 }}
              color="warning"
              onClick={() => updateCategory(row.original)}
            >
              <EditIcon />
            </MDButton>
          </Tooltip>
          <Tooltip title="Delete">
            <MDButton
              size="small"
              sx={{ maxWidth: 30, minWidth: 30, width: 30 }}
              color="error"
              onClick={() => handleConfirm(row.original.id)}
            >
              <DeleteIcon />
            </MDButton>
          </Tooltip>
        </>
      ),
    },
  ];

  if (isLoading) return <LoadingSpinner isLoading={isLoading} />;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Modal
        ref={addCategoryRef}
        closed={modal}
        children={
          <AddCategory
            closeModal={() => setModal((prev) => !prev)}
            setData={setData}
          />
        }
        customStyle={{
          width: { xs: "90%", md: "60%" },
          maxHeight: "90vh",
          overflow: "auto",
        }}
      />
      <Modal
        ref={updateCategoryRef}
        children={
          <UpdateCategory
            closeModal={updateCategoryRef.current?.close}
            setData={setData}
            data={selectedCategory}
            setMaxPage={setMaxPage}
            limit={limit}
            page={page}
          />
        }
        customStyle={{
          width: { xs: "90%", md: "60%" },
          maxHeight: "90vh",
          overflow: "auto",
        }}
      />
      <Modal
        ref={deleteSectionRef}
        children={
          <ConfirmModal
            id={idX}
            closeModal={deleteSectionRef.current?.close}
            deleteFunc={() => removeCategory()}
            title="Are you sure that you wish to delete this category?"
          />
        }
      />

      <MDBox>
        <Table
          title="Categories"
          isLoading={isLoading}
          limit={limit}
          setLimit={setLimit}
          setPage={setPage}
          page={page}
          setIsLoading={setIsLoading}
          maxPage={maxPage}
          prevFunc={() => setPage(page - 1)}
          nextFunc={() => setPage(page + 1)}
          columns={columns}
          search={search}
          setSearch={setSearch}
          rows={data}
          customButton={{
            active: true,
            name: "Add new category",
            func: () => setModal(true),
          }}
        />
      </MDBox>
    </DashboardLayout>
  );
};
