
import React, {  useState } from "react";

// 3rd libraries
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";

// @mui
import { styled } from "@mui/system";
import { Divider, Grid, } from "@mui/material";

// components
import MDBox from "../../../components/MDBox";
import MDInput from "../../../components/MDInput";
import MDButton from "../../../components/MDButton";

// services
import {createContent} from "../../../services/contentService";

// validation
import { validationSchema } from "./validationSchema";
import Uploader from "../../../components/Uploader";
import DashboardLayout from "../../../components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../components/Navbars/DashboardNavbar";



const Text = styled("h4")({
  color: "#203662",
});

export const AddContent = ({ closeModal }) => {
  const [loading, setLoading] = useState(false);
  const [fileList, setFiles] = useState([]);
  const [image, setImage] = useState(null);

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      title: "",
      image: "",
      description: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (!image) return toast.error("Please upload an image");
      const payload = {
        title: { tr: values.title },
        image: image,
        description: { tr: values.description },
      };
      try {
        setLoading(true);
        await createContent(payload);
        toast.success("Content added successfully");
        closeModal?.();
        navigate("/content");
      } catch (error) {
        toast.error(`Something went wrong. ${error}`);
      } finally {
        setLoading(false);
      }
    },
  });

  const handleCancel = () => {
    navigate("/content");
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox bgColor="white" sx={{ borderRadius: 4, padding: 5 }}>
        <Text>Add new content</Text>
        <Divider />
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2} mt={1}>
            <Grid item xs={12}>
              <Uploader
                fileList={fileList}
                onFileChange={(e) => setFiles(e)}
                name="Content Image"
                setImage={setImage}
                loading={loading}
                setLoading={setLoading}
              />
            </Grid>

            <Grid item container xs={12} spacing={2}>
              <Grid item xs={12}>
                <MDInput
                  fullWidth
                  label="Title"
                  name="title"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  error={formik.touched.title && Boolean(formik.errors.title)}
                  helperText={formik.touched.title && formik.errors.title}
                />
              </Grid>
              <Grid item xs={12}>
                <MDInput
                  fullWidth
                  multiline
                  rows={20}
                  label="Description"
                  name="description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.description &&
                    Boolean(formik.errors.description)
                  }
                  helperText={
                    formik.touched.description && formik.errors.description
                  }
                />
              </Grid>

            </Grid>
            <Grid item container xs={12} spacing={2}>
              <Grid item xs={7} md={8}>
                <MDButton
                  disabled={loading}
                  variant="contained"
                  color="info"
                  fullWidth
                  type="submit"
                >
                  Save
                </MDButton>
              </Grid>
              <Grid item xs={5} md={4}>
                <MDButton
                  disabled={loading}
                  variant="outlined"
                  color="error"
                  fullWidth
                  onClick={handleCancel}
                >
                  Cancel
                </MDButton>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </MDBox>
    </DashboardLayout>
  );
};
