import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";

export const Users = ({ image, name_surname, title }) => (
  <MDBox display="flex" alignItems="center" lineHeight={1}>
    <img src={image} alt={name_surname} width="30px" height="45px" style={{borderRadius: 4}}/>
    <MDBox ml={2} lineHeight={1}>
      <MDTypography variant="caption">{title}</MDTypography>
      <MDTypography display="block" variant="button" fontWeight="medium">
        {name_surname}
      </MDTypography>
    </MDBox>
  </MDBox>
);

export const Organization = ({ value }) => (
  <MDBox display="flex" alignItems="center" lineHeight={1}>
    <MDBox lineHeight={1}>
      <MDTypography variant="button" color="text" fontWeight="medium">
        {value}
      </MDTypography>
    </MDBox>
  </MDBox>
);
