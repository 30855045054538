/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import SignIn from "./pages/authentication/sign-in";
import SignUp from "./pages/authentication/sign-up";
import Cover from "./pages/authentication/reset-password/cover";
import Dashboard from "./pages/dashboard";
//import Tables from "./pages/tables";
//import Profile from "./pages/profile";
// import Billing from "./layouts/billing";
// import Notifications from "./layouts/notifications";
// import Profile from "./layouts/profile";

// @mui icons
import Icon from "@mui/material/Icon";
import NotFound from "./pages/NotFound";
import Calendar from "./pages/calendar";
import DraftEditor from "./pages/drafteditor";
import Kanban from "./pages/kanban";
import VideoPlayer from "./pages/videoplayer";
import IvsPlayerPage from "./pages/ivsplayer";
import PlyrPlayer from "./pages/plyrPlayer";
import UsersTable from "./pages/user-table";
import SpeakersTable from "./pages/speaker-table";
import { Categories } from "./pages/category";
import { Products } from "./pages/product";
import { Banners } from "./pages/banner";
import { AddEvent } from "./pages/event/addEvent";
import { EventDetails } from "./pages/event/eventDetails";
import { UpdateEvent } from "./pages/event/updateEvent";
import EventTable from "./pages/event/event-table";
import {Consent} from "./pages/consent";
import {AddContent} from "./pages/consent/add";
import {UpdateContent} from "./pages/consent/update";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/",
    component: <Dashboard />,
    protected: true,
  },
  {
    type: "collapse",
    name: "Users",
    key: "users",
    icon: <Icon fontSize="small">group</Icon>,
    route: "/users",
    component: <UsersTable />,
    protected: true,
  },
  {
    type: "collapse",
    name: "Speakers",
    key: "speakers",
    icon: <Icon fontSize="small">interpreter_mode</Icon>,
    route: "/speakers",
    component: <SpeakersTable />,
    protected: true,
  },
  {
    type: "collapse",
    name: "Categories",
    key: "categories",
    icon: <Icon fontSize="small">category</Icon>,
    route: "/categories",
    component: <Categories />,
    protected: true,
  },
  {
    type: "collapse",
    name: "Products",
    key: "products",
    icon: <Icon fontSize="small">store</Icon>,
    route: "/products",
    component: <Products />,
    protected: true,
  },
  {
    type: "collapse",
    name: "Banner",
    key: "banner",
    icon: <Icon fontSize="small">image</Icon>,
    route: "/banner",
    component: <Banners />,
    protected: true,
  },
  {
    type: "collapse",
    name: "Add Event",
    key: "event-table",
    icon: <Icon fontSize="small">event</Icon>,
    route: "/event-table/add",
    component: <AddEvent />,
    protected: true,
    hidden: true,
  },
  {
    type: "collapse",
    name: "Event Update",
    key: "event-table",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/event-table/update",
    component: <UpdateEvent />,
    protected: true,
    hidden: true,
  },
  {
    type: "collapse",
    name: "Event Table",
    key: "event-table",
    icon: <Icon fontSize="small">event</Icon>,
    route: "/event-table",
    component: <EventTable />,
    protected: true,
  },
  {
    type: "collapse",
    name: "Add Details",
    key: "event-table",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/event-table/details",
    component: <EventDetails />,
    protected: true,
    hidden: true,
  },
  {
    type: "collapse",
    name: "Content",
    key: "content",
    icon: <Icon fontSize="small">book_icon</Icon>,
    route: "/content",
    component: <Consent />,
    protected: true,
  },
  {
    type: "collapse",
    name: "Add Content",
    key: "content",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/content/add",
    component: <AddContent />,
    protected: true,
    hidden: true,
  },
  {
    type: "collapse",
    name: "Update Content",
    key: "content",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/content/update",
    component: <UpdateContent />,
    protected: true,
    hidden: true,
  },
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
    protected: false,
    hidden: true,
  },
  {
    type: "collapse",
    name: "Sign Up",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/sign-up",
    component: <SignUp />,
    protected: false,
    hidden: true,
  },
  {
    type: "collapse",
    name: "Not Found",
    key: "not-found",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/notfound",
    component: <NotFound />,
    protected: false,
    hidden: true,
  },
];

export default routes;
