import { Grid, styled } from "@mui/material";
import MDButton from "./MDButton";
import { useEffect } from "react";

const TextTitle = styled("h4")({
  color: "#203662",
});

export const ConfirmModal = ({ closeModal, deleteFunc, title, buttonTitle }) => {
  const abortController = new AbortController();

  const handleConfirm = () => {
    deleteFunc?.(abortController.signal);
    setTimeout(() => {
      closeModal?.();
    }, 200);
  };

  useEffect(() => {
    return () => {
      abortController.abort();
    };
  }, [abortController]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextTitle>
          {title ?? "Are you sure that you wish to delete?"}
        </TextTitle>
      </Grid>
      <Grid item container spacing={2} mt={2}>
        <Grid item xs={8}>
          <MDButton
            fullWidth
            variant="outlined"
            color="error"
            onClick={handleConfirm}
          >
            {buttonTitle ? buttonTitle : "Delete"}
          </MDButton>
        </Grid>
        <Grid item xs={4}>
          <MDButton
            fullWidth
            variant="contained"
            color="info"
            onClick={() => closeModal?.()}
          >
            Cancel
          </MDButton>
        </Grid>
      </Grid>
    </Grid>
  );
};
