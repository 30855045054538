import React from "react";
import MDTypography from "./MDTypography";

export const ErrorText = ({ text }) => (
  <MDTypography
    sx={{
      color: "#DF2B1C",
      marginTop: ".1rem",
      fontSize: 12,
      marginLeft: 1.75,
      fontWeight: "lighter",
    }}
  >
    {text}
  </MDTypography>
);

export const ErrorTextLong = ({ text }) => (
  <MDTypography
    sx={{
      color: "#00000099",
      marginTop: ".1rem",
      fontSize: "0.75rem",
      marginLeft: 1.75,
      fontWeight: 300,
      lineHeight: 1.25,
      letterSpacing: " 0.03333em",
    }}
  >
    {text}
  </MDTypography>
);

