import Grid from "@mui/material/Grid";
import MDBox from "../../components/MDBox";
import DashboardLayout from "../../components/LayoutContainers/DashboardLayout";
import Footer from "../../components/Footer";
import ComplexStatisticsCard from "../../components/Cards/StatisticsCards/ComplexStatisticsCard";
import { useEffect, useState } from "react";
import {
  getEventsCount,
  getProductsCount,
  getSpeakerCount,
  getUserCount,
} from "../../services/analyticService";
import { toast } from "react-toastify";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import DashboardUserTable from "./Tables/DashboardUserTable";
import DashboardSpeakerTable from "./Tables/DashboardSpeakerTable";
import { DashboardProductTable } from "./Tables/DashboardProductTable";
import DashboardEventTable from "./Tables/DashboardEventTable";
import DashboardNavbar from "../../components/Navbars/DashboardNavbar";

function Dashboard() {
  const [loading, setLoading] = useState(false);
  const [userCount, setUserCount] = useState({ now: 0, last_week: 0 });
  const [speakerCount, setSpeakerCount] = useState({ now: 0, last_week: 0 });
  const [productCount, setProductCount] = useState({ now: 0, last_week: 0 });
  const [eventCount, setEventCount] = useState({ now: 0, last_week: 0 });
  const [activeCount, setActiveCount] = useState(null);

  const lastWeekPercentage = (count) => {
    if (count.last_week === 0 && count.now > 0) return 100;
    if (count.last_week === 0 && count.now === 0) return 0;
    const percentage = ((count.now - count.last_week) / count.last_week) * 100;
    return percentage.toFixed(0);
  };

  const handleClick = (count) => {
    setActiveCount(count);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const data = await Promise.all([
        getUserCount(),
        getSpeakerCount(),
        getProductsCount(),
        getEventsCount(),
      ]);
      setUserCount(data[0]);
      setSpeakerCount(data[1]);
      setProductCount(data[2]);
      setEventCount(data[3]);
    } catch (error) {
      setTimeout(() => {
        toast.error("Error occurred while fetching data");
      }, 1000);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <LoadingSpinner isLoading={loading} />;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                clickCount={() => handleClick(0)}
                color="secondary"
                icon="event"
                is_active={activeCount === 0 || activeCount === null}
                title="Events"
                lwCount={eventCount.last_week}
                count={eventCount.now}
                percentage={{
                  color:
                    lastWeekPercentage(eventCount) > 0 ? "success" : "error",
                  amount: `${lastWeekPercentage(eventCount)}%`,
                  label:
                    lastWeekPercentage(eventCount) > 0
                      ? "more than last week"
                      : "less than last week",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                clickCount={() => handleClick(1)}
                icon="interpreter_mode"
                title="Speakers"
                count={speakerCount.now}
                lwCount={speakerCount.last_week}
                is_active={activeCount === 1}
                percentage={{
                  color:
                    lastWeekPercentage(speakerCount) >= 0 ? "success" : "error",
                  amount: `${lastWeekPercentage(speakerCount)}%`,
                  label:
                    lastWeekPercentage(speakerCount) > 0
                      ? " more than last week"
                      : "less than last week",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                clickCount={() => handleClick(2)}
                color="success"
                icon="store"
                title="Products"
                lwCount={productCount.last_week}
                count={productCount.now}
                is_active={activeCount === 2}
                percentage={{
                  color:
                    lastWeekPercentage(productCount) >= 0 ? "success" : "error",
                  amount: `${lastWeekPercentage(productCount)}%`,
                  label:
                    lastWeekPercentage(productCount) > 0
                      ? " more than last week"
                      : "less than last week",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                clickCount={() => handleClick(3)}
                color="dark"
                icon="group"
                title="Users"
                lwCount={userCount.last_week}
                count={userCount.now}
                is_active={activeCount === 3}
                percentage={{
                  color:
                    lastWeekPercentage(userCount) >= 0 ? "success" : "error",
                  amount: `${lastWeekPercentage(userCount)}%`,
                  label:
                    lastWeekPercentage(userCount) > 0
                      ? " more than last week"
                      : "less than last week",
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
        <MDBox mt={2.5}></MDBox>
        <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              {activeCount === 0 && <DashboardEventTable />}
              {activeCount === 1 && <DashboardSpeakerTable />}
              {activeCount === 2 && <DashboardProductTable />}
              {activeCount === 3 && <DashboardUserTable />}
              {activeCount === null && <DashboardEventTable />}
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
