import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { FileContent, PreviewWrapper, ImageSrc, CloseIcon } from "./style";
import {  CircularProgress } from "@mui/material";
import MDBox from "../MDBox";

const Preview = ({
  fileList,
  editList,
  videoStyle,
  imgStyle,
  loading,
  width,
  height,
}) => {
  const [isDragging, setDragging] = React.useState(false);

  const removeFile = (file) => {
    const fileListCopy = [...fileList];
    const index = fileListCopy.indexOf(file);
    fileListCopy.splice(index, 1);
    editList?.(fileListCopy);
    console.log("remove file", fileList);
  };

  const changeFileName = (e, file) => {
    const { value } = e.target;
    file.fileName = value;
    editList?.([...fileList]);
  };

  const changeFileBufferTime = (val, file) => {
    file.bufferForDecision = parseInt(val);
    editList?.([...fileList]);
  };

  const statusFormatter = (stat) => {
    let msg = "";
    // status: 'inited' | 'uploading' | 'error' | 'finished';
    switch (stat) {
      case "inited":
        msg = "Seçildi";
        break;
      case "uploading":
        msg = "Yükleniyor";
        break;
      case "error":
        msg = "Yükleneme Başarısız";
        break;
      case "finished":
        msg = "Yükleme başarılı! Video dönüştürülüyor...";
        break;
      case "type_error":
        msg = "Dosya tipi hatalı";
        break;

      default:
        msg = "";
        break;
    }
    return msg;
  };

  if (!Array.isArray(fileList) || fileList.length === 0) {
    return (
      <PreviewWrapper>
        <p>Dosya yok</p>
        <div
          style={{ height: "500px", background: "#28282855", zIndex: 1 }}
          id="drop_zone"
          // onDrop={dropHandler}
          // onDragOver={dragOverHandler}
          // onDragEnter={handleDragEnter}
          // onDragLeave={handleDragLeave}
        >
          <p style={{ zIndex: 0 }}>
            Drag one or more files to this{" "}
            <i>drop zone {isDragging.toString()}</i>.
          </p>
        </div>
      </PreviewWrapper>
    );
  }

  if (loading) {
    return (
      <PreviewWrapper h={height} w={width}>
        <CircularProgress
          size={50}
          sx={{
            color: "info.main",
            position: "absolute",
            zIndex: 1,
          }}
        />
      </PreviewWrapper>
    );
  }

  return (
    <PreviewWrapper h={height} w={width}>
      {Array.isArray(fileList) &&
        fileList?.length > 0 &&
        fileList.map((f, idx) => {
          return (
            <Fragment key={`image_preview_${Math.random() + idx}`}>
              <MDBox sx={{ display: "flex", flexDirection: "row" }}>
                <FileContent key={`image_prev_${idx}`} h={height} w={width}>
                  <ImageSrc
                    h={height}
                    w={width}
                    src={f.preview}
                    role={"presentation"}
                    alt={"preview"}
                    style={imgStyle}
                  />
                </FileContent>
                {!loading && (
                  <CloseIcon
                    fontSize="medium"
                    title="Temizle"
                    onClick={() => removeFile(f)}
                  >
                    deleteforever
                  </CloseIcon>
                )}
              </MDBox>
            </Fragment>
          );
        })}
    </PreviewWrapper>
  );
};

Preview.propTypes = {
  fileList: PropTypes.array.isRequired,
  editList: PropTypes.func.isRequired,
  videoStyle: PropTypes.object,
  imgStyle: PropTypes.object,
  color: PropTypes.string,
};

Preview.defaultProps = {
  fileList: [],
  editList: () => {},
  videoStyle: {},
  imgStyle: {},
  color: "#007CFF",
};

export default Preview;
