import * as yup from "yup";

export const validationSchema = yup.object({
   name: yup.string().required("Name is required"),
   colorType: yup
      .object()
      .shape({
         label: yup.string().required("Color type is required"),
         value: yup.string().required("Color type is required"),
      })
      .required(),
   solidColor: yup.string().when("colorType.label", {
      is: "Solid",
      then: yup.string().matches(/^#([0-9a-fA-F]{3}){1,2}$/, "Solid color must be a valid hexadecimal color code"),
   }),
   gradient: yup.string().when("colorType.label", {
      is: "Gradient",
      then: yup.string().matches(/^(linear|conic|radial)-gradient\((.+)\)$/, "Gradient must be a valid CSS gradient"),
   }),
});
