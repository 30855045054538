import { Stack, Tooltip } from "@mui/material";
import MDTypography from "../../../../components/MDTypography";
import { convertToTimeUTC } from "../../../utils/time";
import MDButton from "../../../../components/MDButton";
import EditIcon from "@mui/icons-material/Edit";
import Modal from "../../../../components/Modal";
import React, { useRef, useState } from "react";
import { UpdateSection } from "../sectionCrud/updateSectionModal";
import MDBox from "../../../../components/MDBox";
import DeleteIcon from "@mui/icons-material/Delete";
import { deleteSection } from "../../../../services/eventSpeakerService";
import { getEvent } from "../../../../services/eventService";
import { toast } from "react-toastify";
import { ConfirmModal } from "../../../../components/ConfirmModal";
import { useSearchParams } from "react-router-dom";

const EventCalender = ({ event_speaker, setData, setLoading, data }) => {
  const [value, setValue] = useState();
  const [sectionId, setSectionId] = useState();

  const updateSectionRef = useRef(null);
  const deleteSectionRef = useRef(null);

  const [searchParams] = useSearchParams();
  const event = searchParams.get("id");

  const updateSection = (speaker) => {
    setValue(speaker);
    updateSectionRef.current?.open();
  };

  const handleConfirm = (item) => {
    deleteSectionRef?.current.open();
    setSectionId(item.id);
  };
  const handleDelete = async () => {
    const payload = {
      id: sectionId,
    };
    try {
      setLoading(true);
      await deleteSection(payload);
      const data = await getEvent({ id: event });
      setData(data);
      toast.success("Session deleted successfully.");
    } catch (err) {
      toast.error("Session delete failed.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal
        ref={updateSectionRef}
        children={
          <UpdateSection
            closeModal={updateSectionRef.current?.close}
            event={value}
            setData={setData}
            data={data}
          />
        }
        customStyle={{
          width: { xs: "80%", md: "35%" },
          maxHeight: "90vh",
          overflow: "auto",
        }}
      />
      <Modal
        ref={deleteSectionRef}
        children={
          <ConfirmModal
            id={sectionId}
            closeModal={deleteSectionRef.current?.close}
            deleteFunc={() => handleDelete()}
            title="Are you sure that you wish to delete this session?"
          />
        }
      />
      <Stack mt={2} component="div">
        {event_speaker &&
          event_speaker
            .filter((speaker) => !speaker.is_moderator)
            .map((speaker, i) => {
              return (
                <Stack
                  flexDirection="row"
                  key={i}
                  mt={1}
                  alignItems="flex-start"
                >
                  <MDTypography
                    mr={4}
                    sx={{ minWidth: 110 }}
                    fontSize={16}
                    fontWeight="medium"
                    color="possibleBlue"
                  >
                    {`${convertToTimeUTC(
                      speaker.start_date
                    )} - ${convertToTimeUTC(speaker.end_date)}`}
                  </MDTypography>
                  <MDTypography
                    variant="subtitle1"
                    fontSize={15}
                    fontWeight="light"
                    component="p"
                  >
                    {speaker.description}
                  </MDTypography>
                  <MDBox
                    sx={{
                      marginLeft: "auto",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Tooltip title="Update">
                      <MDButton
                        sx={{ marginLeft: 2, marginRight: 1 }}
                        color="warning"
                        onClick={() => updateSection(speaker)}
                      >
                        <EditIcon />
                      </MDButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                      <MDButton
                        color="error"
                        onClick={() => handleConfirm(speaker)}
                      >
                        <DeleteIcon />
                      </MDButton>
                    </Tooltip>
                  </MDBox>
                </Stack>
              );
            })}
      </Stack>
    </>
  );
};

export default EventCalender;
