import { useEffect, useRef, useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";

//3rd libraries
import { toast } from "react-toastify";

// @mui material components
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import EditIcon from "@mui/icons-material/Edit";
import { Tooltip } from "@mui/material";

// components
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import Footer from "../../../components/Footer";
import Table from "../../../components/Tables/Table";
import MDButton from "../../../components/MDButton";
import { Date, ImageContainer, TitleContainer } from "./components/tableItems";
import DashboardLayout from "../../../components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../components/Navbars/DashboardNavbar";

// Data
import Modal from "../../../components/Modal";
import { EditUserModal } from "./components/editModal";
import { getEvents } from "../../../services/eventService";

import { LoadingSpinner } from "../../../components/LoadingSpinner";
import {
   convertToTimeUTC, convertToUtcWithoutTime,
  convertToDate,
} from "../../utils/time";
import { getEventType } from "../../../services/appService";

const EventTable = () => {
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [filter, setFilter] = useState(null);
  const [eventType, setEventType] = useState([]);

  const navigate = useNavigate();

  const editModalRef = useRef(null);

  useEffect(() => {
    fetchData();
  }, [page, limit, filter]);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const eType = await getEventType();
      setEventType([{label: "All", value: null}, ...eType.map((item) => ({label: item.name, value: item.id}))]);
      const { data, pagination_info } = await getEvents({
        page,
        limit,
        filter,
      });
      setData(
        data.map((item) => ({
          id: item.id,
          title: item.title,
          category: item.category.name ?? "",
          start_date: item.start_date,
          end_date: item.end_date,
          event_type: item.event_type.name ?? "",
          is_active: item.is_active,
          image: item.image ?? "",
          // branch: item.branch.name ?? {},
          created_at: convertToDate(item.created_at),
          updated_at: convertToDate(item.updated_at),
          // speaker: item.event_speaker ?? [],
          description: item.description ?? "",
        }))
      );

      setMaxPage(pagination_info.total_page);
    } catch (error) {
      toast.error("Something went wrong");
    }
    setIsLoading(false);
  };

  const columns = [
    {
      Header: "Image",
      accessor: "image",
      align: "left",
      width: 50,
      Cell: ({ row }) => (
        <ImageContainer url={row.original.image} alt="event" />
      ),
    },
    {
      Header: "Title",
      accessor: "name",
      align: "left",
      Cell: ({ row }) => (
        <TitleContainer>
          <MDTypography
            sx={{
              "&:hover": { textDecoration: "underline", cursor: "pointer" },
            }}
            onClick={() =>
              navigate({
                pathname: `/event-table/details`,
                search: createSearchParams({
                  id: row.original.id,
                }).toString(),
              })
            }
            fontSize={14}
            fontWeight="bold"
            color="possibleBlue"
          >
            {row.original.title.length > 70
              ? row.original.title.substring(0, 70) + "..."
              : row.original.title}
          </MDTypography>
        </TitleContainer>
      ),
    },
    {
      Header: "Description",
      accessor: "description",
      align: "left",
      Cell: ({ row }) => (
        <TitleContainer>
          <MDTypography variant="subtitle1" fontSize={12} fontWeight="light">
            {row.original.description.length > 85
              ? row.original.description.substring(0, 85) + "..."
              : row.original.description}
          </MDTypography>
        </TitleContainer>
      ),
    },
    {
      Header: "Date",
      accessor: "start_date",
      align: "left",
      Cell: ({ row }) => (
        <Date
          start={`${convertToUtcWithoutTime(row.original.start_date)}`}
          end={`${convertToTimeUTC(row.original.start_date)} - ${convertToTimeUTC(
            row.original.end_date
          )}`}
        />
      ),
    },
    {
      Header: "Type",
      accessor: "event_type",
      align: "left",
      Cell: ({ row }) => (
        <MDTypography variant="button" color="text" fontWeight="medium">
          {row.original.event_type}
        </MDTypography>
      ),
    },
    {
      Header: "Category",
      accessor: "category",
      align: "left",
      Cell: ({ row }) => (
        <MDTypography variant="button" color="text" fontWeight="medium">
          {row.original.category}
        </MDTypography>
      ),
    },
    {
      Header: "Status",
      accessor: "is_approved",
      align: "center",
      Cell: ({ row }) => (
        <>
          {row.original.is_active ? (
            <Tooltip title="Published" placement="top">
              <MDBox
                color="success"
                sx={{ display: "flex", flexDirection: "row" }}
              >
                <CheckCircleOutlineOutlinedIcon fontSize="medium" />
              </MDBox>
            </Tooltip>
          ) : (
            <Tooltip title="Unpublished" placement="top">
              <MDBox
                color="error"
                sx={{ display: "flex", flexDirection: "row" }}
              >
                <CancelOutlinedIcon fontSize="medium" placement="top" />
              </MDBox>
            </Tooltip>
          )}
        </>
      ),
    },
    {
      Header: "Action",
      accessor: "action",
      align: "center",
      Cell: ({ row }) => (
        <Tooltip title="Update">
          <MDButton
            size="small"
            sx={{ maxWidth: 30, minWidth: 30, width: 30 }}
            color="warning"
            onClick={() =>
              navigate({
                pathname: `/event-table/details`,
                search: createSearchParams({
                  id: row.original.id,
                }).toString(),
              })
            }
          >
            <EditIcon />
          </MDButton>
        </Tooltip>
      ),
    },
  ];

  if (isLoading) {
    return <LoadingSpinner isLoading={isLoading} />;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Modal
        ref={editModalRef}
        children={
          <EditUserModal
            closeModal={editModalRef.current?.close}
            selectedUser={selectedUser}
            setData={setData}
            loading={isLoading}
            setMaxPage={setMaxPage}
            setLoading={setIsLoading}
            page={page}
            limit={limit}
          />
        }
        customStyle={{
          width: { xs: "90%", md: "60%" },
          maxHeight: "90vh",
          overflow: "auto",
        }}
      />

      <MDBox>
        <Table
          title="Events"
          isLoading={isLoading}
          limit={limit}
          setLimit={setLimit}
          setPage={setPage}
          page={page}
          setIsLoading={setIsLoading}
          maxPage={maxPage}
          prevFunc={() => setPage(page - 1)}
          nextFunc={() => setPage(page + 1)}
          columns={columns}
          search={search}
          setSearch={setSearch}
          rows={data}
          filter={filter}
          setFilter={setFilter}
          options={eventType}
          isFilter={true}
       /*   excelButton={{
            activeExcel: true,
            excelFunc: () => console.log("excel"),
          }}*/
          customButton={{
            active: true,
            name: "Add Event",
            func: () => navigate("/event-table/add"),
          }}
        />
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default EventTable;
