import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";

//3rd libraries
import { toast } from "react-toastify";

//@mui
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import MDButton from "../../../components/MDButton";
import Modal from "../../../components/Modal";

// components
import Footer from "../../../components/Footer";
import Table from "../../../components/Tables/Table";
import {
  InstitutionAndCity,
  Status,
  User,
} from "../../user-table/components/tableItems";
import { LoadingSpinner } from "../../../components/LoadingSpinner";
import { EditUserModal } from "./components/editModal";

// Services
import { getEventParticipant } from "../../../services/appService";
import { convertToTimeUTC, convertToDate } from "../../utils/time";
import { Stack } from "@mui/material";

const EventParticipant = () => {
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [filter, setFilter] = useState("all");
  const editModalRef = useRef(null);

  const [searchParams] = useSearchParams();
  const event = searchParams.get("id");

  useEffect(() => {
    fetchData();
  }, [page, limit, filter]);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const { data, pagination_info } = await getEventParticipant({
        page,
        limit,
        filter,
        event,
      });
      setData(data);
      setMaxPage(pagination_info.total_page);
      setIsLoading(false);
    } catch (error) {
      toast.error("Error occured while fetching data");
    } finally {
      setIsLoading(false);
    }
  };

  const excelColumns = [
    "ID",
    "Title",
    "Name Surname",
    "Email",
    "TCKN",
    "gsm",
    "Branch",
    "Institution",
    "City",
    "Event",
    "Is Approved",
    "Is Reminder",
    "Is Watched",
    "Created At",
    "Joined At",
  ];

  const excelData = data.map((item) => {
    const watched_date = item.watched_date
      ? `${convertToDate(item.watched_date)}  ${convertToTimeUTC(
          item.watched_date
        )}`
      : "";
    return [
      item.id,
      item.user?.user_profile?.title?.name,
      item.user?.user_profile?.name_surname,
      item.user?.email,
      item.user?.user_profile?.tckn,
      item.user?.user_profile?.gsm,
      item.user?.user_profile?.branch?.name,
      item.user?.user_profile?.institution,
      item.user?.user_profile?.city?.name,
      item.event?.title,
      item.is_approved,
      item.is_reminder,
      item.is_watched,
      convertToDate(item.created_at),
      watched_date,
    ];
  });
  const editModal = async (item) => {
    setSelectedUser({ id: item.id, is_approved: item.is_approved });
    editModalRef.current?.open();
  };

  const columns = [
    {
      Header: "Name Surname",
      accessor: "name_surname",
      width: "25%",
      align: "left",
      Cell: ({ row }) => (
        <User
          title={row.original.user?.user_profile?.title.name ?? "..."}
          name_surname={row.original.user?.user_profile?.name_surname ?? "..."}
          email={row.original.user?.email ?? "..."}
        />
      ),
    },
    {
      Header: "Organization",
      accessor: "email",
      align: "left",
      width: "20%",
      Cell: ({ row }) => (
        <InstitutionAndCity
          institution={row.original.user?.user_profile?.institution ?? "..."}
          city={row.original.user?.user_profile?.city.name ?? "..."}
        />
      ),
    },

    {
      Header: "Branch",
      accessor: "branch",
      align: "left",
      width: "25%",
      Cell: ({ row }) => (
        <MDTypography variant="button" color="text" fontWeight="medium">
          {row.original.user?.user_profile?.branch.name ?? "..."}
        </MDTypography>
      ),
    },
    {
      Header: "Register At",
      accessor: "created_at",
      align: "left",
      Cell: ({ row }) => (
        <Stack flexDirection="column">
          <MDTypography variant="button" color="text" fontWeight="medium">
            {convertToDate(row.original.created_at)}
          </MDTypography>
          <MDTypography variant="caption">
            {convertToTimeUTC(row.original.created_at)}
          </MDTypography>
        </Stack>
      ),
    },
    {
      Header: "Joined At",
      accessor: "is_watched",
      align: "center",
      width: "20%",
      Cell: ({ row }) => {
        const date = row.original.watched_date
          ? convertToDate(row.original.watched_date)
          : "";
        const time = row.original.watched_date
          ? convertToTimeUTC(row.original.watched_date)
          : "";
        return (
          <Stack flexDirection="column">
            <MDTypography variant="button" color="text" fontWeight="medium">
              {date}
            </MDTypography>
            <MDTypography variant="caption">{time}</MDTypography>
          </Stack>
        );
      },
    },
    {
      Header: "Status",
      accessor: "is_approved",
      align: "center",
      Cell: ({ row }) => (
        <Status
          status={
            row.original.is_approved === "approve"
              ? "Approved"
              : row.original.is_approved === "pending"
              ? "Pending"
              : row.original.is_approved === "reject"
              ? "Reject"
              : ""
          }
        />
      ),
    },
    {
      Header: "Action",
      width: "15%",
      align: "left",
      Cell: ({ row }) => (
        <MDButton
          disabled={isLoading}
          color="info"
          size="small"
          onClick={() => editModal(row.original)}
        >
          Detail
        </MDButton>
      ),
    },
  ];

  if (isLoading) {
    return <LoadingSpinner isLoading={isLoading} />;
  }

  return (
    <>
      <Modal
        ref={editModalRef}
        children={
          <EditUserModal
            filter={filter}
            closeModal={editModalRef.current?.close}
            selectedUser={selectedUser}
            setData={setData}
            loading={isLoading}
            setMaxPage={setMaxPage}
            setLoading={setIsLoading}
            page={page}
            limit={limit}
          />
        }
        customStyle={{
          width: { xs: "90%", md: "60%" },
          maxHeight: "90vh",
          overflow: "auto",
        }}
      />
      <MDBox>
        <Table
          title="Event Participant"
          isLoading={isLoading}
          limit={limit}
          setLimit={setLimit}
          setPage={setPage}
          page={page}
          setIsLoading={setIsLoading}
          maxPage={maxPage}
          prevFunc={() => setPage(page - 1)}
          nextFunc={() => setPage(page + 1)}
          columns={columns}
          search={search}
          setSearch={setSearch}
          rows={data}
          excelButton={{
            activeExcel: true,
            excelFunc: () => console.log("excel"),
            excelColumns: excelColumns,
            exportData: excelData,
          }}
        />
      </MDBox>
      <Footer />
    </>
  );
};
export default EventParticipant;
