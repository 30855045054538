import { useEffect, useState } from "react";

//3rd libraries
import { toast } from "react-toastify";

import Table from "../../../components/Tables/Table";
import { Organization, Users } from "./style";

// services
import { getSpeakers } from "../../../services/speakersService";
import { LoadingSpinner } from "../../../components/LoadingSpinner";
import MDTypography from "../../../components/MDTypography";
import { convertToDate } from "../../utils/time";

const DashboardSpeakerTable = () => {
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");

  useEffect(() => {
    fetchData();
  }, [page, limit]);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const { data, pagination_info } = await getSpeakers({ page, limit });
      setData(
        data.map((item) => ({
          id: item.id,
          image: item.image,
          institution: item.institution,
          name_surname: item.name_surname,
          title: item.title.name,
          branch: item.branch.name,
          created_at: convertToDate(item.created_at),
        }))
      );
      setMaxPage(pagination_info.total_page);
    } catch (error) {
      toast.error("Speaker list failed.");
    } finally {
      setIsLoading(false);
    }
  };

  const excelColumns = [
    "ID",
    "Title",
    "Name Surname",
    "Institution",
    "Branch",
    "Image",
    "Created At",
    "Updated At",
  ];

  const excelData = data.map((item) => {
    return [
      item.id,
      item.title,
      item.name_surname,
      item.institution,
      item.branch,
      item.image,
      item.created_at,
      convertToDate(item.updated_at),
    ];
  });

  const columns = [
    {
      Header: "Speaker",
      accessor: "name_surname",
      align: "left",
      Cell: ({ row }) => (
        <Users
          image={row.original.image}
          name_surname={row.original.name_surname}
          title={row.original.title}
        />
      ),
    },
    {
      Header: "branch",
      accessor: "branch",
      align: "left",
      Cell: ({ row }) => <Organization value={row.original.branch} />,
    },
    {
      Header: "institution",
      accessor: "institution",
      align: "left",
      Cell: ({ row }) => (
        <Organization
          value={
            row.original.institution.length > 60
              ? row.original.institution.substring(0, 60) + "..."
              : row.original.institution
          }
        />
      ),
    },
    {
      Header: "Register Date",
      accessor: "created_ad",
      align: "left",
      Cell: ({ row }) => (
        <MDTypography variant="button" color="text" fontWeight="medium">
          {row.original.created_at}
        </MDTypography>
      ),
    },
  ];

  if (isLoading) return <LoadingSpinner isLoading={isLoading} />;

  return (
    <Table
      title="Speakers"
      isLoading={isLoading}
      limit={limit}
      setLimit={setLimit}
      setPage={setPage}
      page={page}
      setIsLoading={setIsLoading}
      maxPage={maxPage}
      prevFunc={() => setPage(page - 1)}
      nextFunc={() => setPage(page + 1)}
      columns={columns}
      search={search}
      setSearch={setSearch}
      rows={data}
      excelButton={{
        activeExcel: true,
        excelFunc: () => console.log("excel"),
        excelColumns: excelColumns,
        exportData: excelData,
      }}
    />
  );
};

export default DashboardSpeakerTable;
