import React, { useEffect, useState } from "react";

// 3rd libraries
import { toast } from "react-toastify";
import { useFormik } from "formik";
import Select from "react-select";

// @mui
import { Divider, Grid } from "@mui/material";
import { styled } from "@mui/system";

// components
import MDBox from "../../../components/MDBox";
import MDInput from "../../../components/MDInput";
import MDButton from "../../../components/MDButton";
import Uploader from "../../../components/Uploader";

// services
import { getProducts, updateProduct } from "../../../services/productService";
import { getColorTypes } from "../../../services/appService";

// validation
import { validationSchema } from "./validationSchema";

// styles
import { styles } from "../../event/addEvent/styles";

const Text = styled("h4")({
   color: "#203662",
});

export const UpdateProduct = ({ closeModal, setData, data, setMaxPage, limit, page }) => {
   const [isLoading, setIsLoading] = useState(false);
   const [fileList, setFileList] = useState([{ preview: data.logo }]);
   const [image, setImage] = useState(null);
   const [colorTypes, setColorTypes] = useState([]);

   useEffect(() => {
      getColorType();
   }, []);


   const getColorType = async () => {
      try {
         const data = await getColorTypes();
         setColorTypes(data);
      } catch (error) {
         console.log(error);
      }
   };

   const formik = useFormik({
      initialValues: {
         name: data.name,
         logo: [{ preview: data.logo }],
         colorType: { label: data.color_type.name, value: data.color_type.id },
         solidColor: data.color,
         gradient: data.color,
      },
      validationSchema: validationSchema,
      enableReinitialize: true,
      onSubmit: async (values) => {
         if (!values.logo && !image) return toast.error("Please upload an image");
         const payload = {
            id: data.id,
            name: values.name,
            color_type: values.colorType.value,
            color: values.colorType.value === "63edd13ae5cbaaf834958317" ? values.gradient : values.solidColor,
         };
         const payloadWithImage = {
            id: data.id,
            name: values.name,
            logo: image,
            color_type: values.colorType.value,
            color: values.colorType.value === "63edd13ae5cbaaf834958317" ? values.gradient : values.solidColor,
         };

         try {
            setIsLoading(true);
            if (image) {
               await updateProduct(payloadWithImage);
            }
            await updateProduct(payload);
            const { data, pagination_info } = await getProducts({ limit, page });
            setData(data);
            setMaxPage(pagination_info.total_page);
            toast.success("Product added successfully.");
            closeModal?.();
         } catch (error) {
            toast.error(`Something went wrong. ${error.message}`);
         } finally {
            setIsLoading(false);
         }
      },
   });

   return (
      <MDBox bgColor="white" sx={{ borderRadius: 4 }}>
         <Text>Update Product</Text>
         <Divider />
         <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2} mt={1}>
               <Grid item xs={12}>
                  <Uploader
                     fileList={fileList}
                     onFileChange={(e) => setFileList(e)}
                     setImage={setImage}
                     loading={isLoading}
                     setLoading={setIsLoading}
                     name="Logo"
                     width="386px"
                     height="112px"
                  />
               </Grid>
               <Grid item xs={12} md={12}>
                  <MDInput
                     fullWidth
                     label="Name"
                     name="name"
                     value={formik.values.name}
                     onChange={formik.handleChange}
                     error={formik.touched.name && Boolean(formik.errors.name)}
                     helperText={formik.touched.name && formik.errors.name}
                  />
               </Grid>

               <Grid item xs={4}>
                  <Select
                     fullWidth
                     placeholder="Color Type"
                     styles={styles}
                     noOptionsMessage={({ inputValue }) => `${inputValue || "Color type"} not found`}
                     value={formik.values.colorType}
                     onChange={(value) => formik.setFieldValue("colorType", value)}
                     options={colorTypes?.map((item) => {
                        return {
                           label: item.name,
                           value: item.id,
                        };
                     })}
                     error={formik.touched.colorType && Boolean(formik.errors.colorType)}
                     helperText={formik.touched.institution && formik.errors.institution}
                  />
                  {formik.touched.colorType && formik.errors.colorType && (
                     <div
                        style={{
                           color: "#DF2B1C",
                           marginTop: ".1rem",
                           fontSize: 12,
                           marginLeft: 15,
                           fontWeight: "lighter",
                        }}
                     >
                        {formik.errors.colorType}
                     </div>
                  )}
               </Grid>

               {formik.values.colorType.label === "Solid" && (
                  <Grid item xs={8}>
                     <MDInput
                        fullWidth
                        label="Solid"
                        name="solidColor"
                        value={formik.values.solidColor}
                        onChange={formik.handleChange}
                        error={formik.touched.solidColor && Boolean(formik.errors.solidColor)}
                        helperText={formik.touched.solidColor && formik.errors.solidColor}
                     />
                  </Grid>
               )}
               {formik.values.colorType.label === "Gradient" && (
                  <Grid item xs={8}>
                     <MDInput
                        fullWidth
                        label="Gradient"
                        name="gradient"
                        value={formik.values.gradient}
                        onChange={formik.handleChange}
                        error={formik.touched.gradient && Boolean(formik.errors.gradient)}
                        helperText={formik.touched.gradient && formik.errors.gradient}
                     />
                  </Grid>
               )}

               <Grid item container xs={12} spacing={2}>
                  <Grid item xs={7} md={8}>
                     <MDButton disabled={isLoading} variant="contained" color="info" fullWidth type="submit">
                        Save
                     </MDButton>
                  </Grid>
                  <Grid item xs={5} md={4}>
                     <MDButton disabled={isLoading} variant="outlined" color="error" fullWidth onClick={closeModal}>
                        Cancel
                     </MDButton>
                  </Grid>
               </Grid>
            </Grid>
         </form>
      </MDBox>
   );
};
