import { productInstance } from "./http";
import errorHandler from "./errorHandler";

export const getProducts = async ({ limit, page, lang }) => {
  try {
    const { data } = await productInstance(lang).get(
      `?pagination=1&page=${page}&limit=${limit}&order_desc=1`
    );
    return data;
  } catch (error) {
    throw errorHandler(error);
  }
};

export const getProduct = async ({ id, lang }) => {
  try {
    const { data } = await productInstance(lang).get(`/${id}`);
    return data;
  } catch (error) {
    throw errorHandler(error);
  }
};

export const createProduct = async ({
  name,
  color_type,
  color,
  logo,
  lang,
}) => {
  const payload = { name, logo, color, color_type };
  try {
    const { data } = await productInstance(lang).post(`/`, payload);
    return data;
  } catch (error) {
    throw errorHandler(error);
  }
};

export const updateProduct = async ({
  id,
  name,
  color_type,
  color,
  logo,
  lang,
}) => {
  const payload = { name, logo, color_type, color };
  try {
    const { data } = await productInstance(lang).put(`/${id}`, payload);
    return data;
  } catch (error) {
    throw errorHandler(error);
  }
};

export const deleteProduct = async ({ id, lang }) => {
  try {
    const { data } = await productInstance(lang).delete(`/${id}`);
    return data;
  } catch (error) {
    throw errorHandler(error);
  }
};
