
export const customStyles = {
  control: (base, state) => ({
    ...base,
    fontSize: "12px",
    color: "#495057",
    borderRadius: "12px",
    background: "#FAFBFC",
    height: 35,
    maxHeight: 35,
    borderColor: state.isFocused ? "rgba(0, 0, 0, 0.17)" : "rgba(0, 0, 0, 0.47)",
  }),
  placeholder: (base) => ({
    ...base,
    fontSize: "12px",
    color: "#7b809a",
  }),
  option: (base) => ({
    ...base,
    fontSize: "12px",
  }),
};
