import { eventInstance } from "./http";
import errorHandler from "./errorHandler";

export const getEvents = async ({
  page,
  limit,
  lang,
  filter,
  participant_count,
}) => {
  let query = {
    pagination: 1,
    order_desc: 1,
  };
  try {
    if (filter) query.event_type = filter;
    if (page) query.page = page;
    if (limit) query.limit = limit;
    if (participant_count) query.participant_count = 1;
    const queryParams = new URLSearchParams(query).toString();
    const { data } = await eventInstance(lang).get(`/?${queryParams}`);
    return data;
  } catch (error) {
    throw errorHandler(error);
  }
};

export const getEvent = async ({ id, lang }) => {
  try {
    const { data } = await eventInstance(lang).get(`/${id}?is_url=1`);
    return data;
  } catch (error) {
    throw errorHandler(error);
  }
};
export const createEvent = async ({
  name,
  image,
  title,
  event_type,
  product,
  description,
  url_info,
  url_type,
  start_date,
  end_date,
  branch,
  city,
  category,
  banner_type,
  banner,
  lang,
}) => {
  const payload = {
    name,
    image,
    title,
    event_type,
    product,
    description,
    url_info,
    url_type,
    start_date,
    end_date,
    branch,
    city,
    category,
    banner_type,
    banner,
  };
  try {
    const { data } = await eventInstance(lang).post(`/`, payload);
    return data;
  } catch (error) {
    throw errorHandler(error);
  }
};

export const updateEvent = async ({
  id,
  name,
  image,
  title,
  event_type,
  product,
  description,
  url_info,
  url_type,
  start_date,
  end_date,
  branch,
  city,
  category,
  banner_type,
  banner,
  lang,
}) => {
  const payload = {
    name,
    image,
    title,
    event_type,
    product,
    description,
    url_info,
    url_type,
    start_date,
    end_date,
    branch,
    city,
    category,
    banner_type,
    banner,
  };
  try {
    const { data } = await eventInstance(lang).put(`/${id}`, payload);
    return data;
  } catch (error) {
    throw errorHandler(error);
  }
};

export const deleteEvent = async ({ id, lang }) => {
  try {
    const { data } = await eventInstance(lang).delete(`/${id}`);
    return data;
  } catch (error) {
    throw errorHandler(error);
  }
};

export const eventActive = async ({ id, lang }) => {
  try {
    const { data } = await eventInstance(lang).put(`/active-trigger/${id}`);
    return data;
  } catch (error) {
    throw errorHandler(error);
  }
};
