import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

//3rd libraries
import { toast } from "react-toastify";

//@mui
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";

// components
import Footer from "../../../components/Footer";
import Table from "../../../components/Tables/Table";
import { User } from "../../user-table/components";
import { LoadingSpinner } from "../../../components/LoadingSpinner";

// Services
import { getEventQuestion } from "../../../services/appService";
import { TitleContainer } from "./tableItems";
import { convertToTimeUTC, convertToDate} from "../../utils/time";
import {Stack} from "@mui/material";

const EventQuestion = () => {
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");

  const [searchParams] = useSearchParams();
  const event = searchParams.get("id");

  useEffect(() => {
    fetchData();
  }, [page, limit]);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const { data, pagination_info } = await getEventQuestion({
        page,
        limit,
        event,
      });
      setData(data);
      setMaxPage(pagination_info.total_page);
    } catch (error) {
      toast.error("Error occured while fetching data");
    } finally {
      setIsLoading(false);
    }
  };

  const excelColumns = [
    "ID",
    "Title",
    "Name Surname",
    "Email",
    "Branch",
    "Institution",
    "City",
    "Message",
    "Event",
    "Created At",
    "Updated At",
  ];

  const excelData = data.map(item => {
    return [
      item.id,
      item.user?.user_profile?.title?.name,
      item.user?.user_profile?.name_surname,
      item.user?.email,
      item.user?.user_profile?.branch?.name,
      item.user?.user_profile?.institution,
      item.user?.user_profile?.city?.name,
      item.message,
      item.event?.title,
      convertToDate(item.created_at),
      convertToDate(item.updated_at),
    ];
  });

  const columns = [
    {
      Header: "User",
      accessor: "name_surname",
      width: "25%",
      align: "left",
      Cell: ({ row }) => (
        <User
          name_surname={row.original.user?.user_profile?.name_surname ?? "..."}
          title={row.original.user?.user_profile?.title.name ?? "..."}
          email={row.original.user?.email ?? "..."}
        />
      ),
    },
    {
      Header: "Branch",
      accessor: "branch",
      align: "left",
      width: "25%",
      Cell: ({ row }) => (
        <MDTypography variant="button" color="text" fontWeight="medium">
          {row.original.user?.user_profile?.branch.name ?? "..."}
          {/*{user.user_profile.branch?.name.length > 25
                    ? user.user_profile.branch.name.substring(0, 25) + "..."
                    : user.user_profile.branch?.name}*/}
        </MDTypography>
      ),
    },
    {
      Header: "Date",
      accessor: "created_at",
      align: "left",
      Cell: ({ row }) => (
        <Stack flexDirection="column">
          <MDTypography variant="button" color="text" fontWeight="medium">
            {convertToDate(row.original.created_at)}
          </MDTypography>
          <MDTypography variant="caption">
            {convertToTimeUTC(row.original.created_at)}
          </MDTypography>
        </Stack>
      ),
    },
    {
      Header: "Message",
      accessor: "messages",
      align: "left",
      Cell: ({ row }) => (
        <TitleContainer>
          <MDTypography fontSize={14} fontWeight="regular">
            {row.original.message}
          </MDTypography>
        </TitleContainer>
      ),
    },
    /*    {
          Header: "Action",
          width: "15%",
          align: "left",
          Cell: ({ row }) => (
            <MDButton
              disabled={isLoading}
              color="info"
              size="small"
              onClick={() => editModal(row.original)}
            >
              Detail
            </MDButton>
          ),
        },*/
  ];

  if (isLoading) {
    return <LoadingSpinner isLoading={isLoading} />;
  }

  return (
    <>
      <MDBox>
        <Table
          title="Questions"
          isLoading={isLoading}
          limit={limit}
          setLimit={setLimit}
          setPage={setPage}
          page={page}
          setIsLoading={setIsLoading}
          maxPage={maxPage}
          prevFunc={() => setPage(page - 1)}
          nextFunc={() => setPage(page + 1)}
          columns={columns}
          search={search}
          setSearch={setSearch}
          rows={data}
          excelButton={{
            activeExcel: true,
            excelFunc: () => console.log("excel"),
            excelColumns: excelColumns,
            exportData: excelData,
          }}
        />
      </MDBox>
      <Footer />
    </>
  );
};

export default EventQuestion;
