import React from "react";
import MUIModal from "@mui/material/Modal";
import { Box } from "@mui/system";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#fff",
  p: 4,
  outline: "none",
  borderRadius: "15px",
  // overflow: 'hidden'
};

const Modal = ({ onClose, beforeOpen, children, customStyle, closed}, ref) => {
  const [isVisible, setVisible] = React.useState(false);

  // ref sometimes doesn't work. because if we open modal from another component, ref is not defined yet. So we need to use useEffect
  // this useEffect will be called when ref is defined
  // This is a workaround. I don't know why ref doesn't work
  React.useEffect(() => {
    if (closed) {
      openModal();
    } else {
      closeModal();
    }
  }, [closed]);

  React.useImperativeHandle(ref, () => ({
    open: () => {
      openModal();
    },
    close: () => {
      closeModal();
    },
  }));

  const openModal = async () => {
    setVisible(true);
    await beforeOpen?.();
  };

  const closeModal = async () => {
    setVisible(false);
    await onClose?.();
  };



  return (
    <MUIModal ref={ref} open={isVisible} onClose={closeModal}>
      <Box sx={{ ...style, ...customStyle }}>{children}</Box>
    </MUIModal>
  );
};

export default React.forwardRef(Modal);
