import { Divider, Grid, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";

// 3rd libraries
import { toast } from "react-toastify";
import { useFormik } from "formik";
import Select from "react-select";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// @mui
import { styled } from "@mui/system";

// components
import MDBox from "../../../components/MDBox";
import MDInput from "../../../components/MDInput";
import MDButton from "../../../components/MDButton";

// services
import { createEvent } from "../../../services/eventService";

// validation
import { validationSchema } from "./validationSchema";
import Uploader from "../../../components/Uploader";

import { styles } from "./styles";
import {
  getAppBanner,
  getAppBranches,
  getAppCategories,
  getAppProducts,
  getBannerType,
  getCity,
  getEventType,
  getUrlType,
} from "../../../services/appService";
import MDAvatar from "../../../components/MDAvatar";
import DashboardLayout from "../../../components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../components/Navbars/DashboardNavbar";
import { useNavigate } from "react-router-dom";
import { convertToFullDate } from "../../utils/time";

const Text = styled("h4")({
  color: "#203662",
});

const renderOption = (option) => (
  <div style={{ display: "flex", alignItems: "center" }}>
    <MDAvatar src={option.image} />
    <div style={{ marginLeft: "10px" }}>
      <div>{option.label}</div>
    </div>
  </div>
);

export const AddEvent = ({ closeModal }) => {
  const [loading, setLoading] = useState(false);
  const [fileList, setFiles] = useState([]);
  const [urlTypes, setUrlTypes] = useState([]);
  const [categories, setCategories] = useState([]);
  const [cities, setCities] = useState([]);
  const [branches, setBranches] = useState([]);
  const [eventTypes, setEventTypes] = useState([]);
  const [bannerTypes, setBannerTypes] = useState([]);
  const [banners, setBanners] = useState([]);
  const [products, setProducts] = useState([]);
  const [image, setImage] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const data = await Promise.all([
        getUrlType(),
        getAppCategories(),
        getCity(),
        getAppBranches(),
        getEventType(),
        getBannerType(),
        getAppBanner(),
        getAppProducts(),
      ]);
      setUrlTypes(data[0]);
      setCategories(data[1]);
      setCities(data[2]);
      setBranches(data[3]);
      setEventTypes(data[4]);
      setBannerTypes(data[5]);
      setBanners(data[6]);
      setProducts(data[7]);
    } catch (error) {
      toast.error("data could not be fetched.");
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      title: "",
      image: "",
      description: "",
      start_date: new Date().toISOString(),
      end_date: new Date().toISOString(),
      url: "",
      url_type: null,
      api_key: "",
      meeting_number: "",
      api_secret: "",
      meeting_password: "",
      product: [],
      category: [],
      banner: null,
      banner2: null,
      city: [],
      branch: [],
      banner_type: null,
      event_types: null,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (!image) return toast.error("Please upload an image");
      const payload = {
        title: { tr: values.title },
        image: image,
        description: { tr: values.description },
        event_type: values.event_types.value,
        product: values.product.value,
        url_info: ["Sanal", "Virtual"].includes(values.event_types.label)
          ? {
            tr:
              formik.values?.url_type?.slug === "zoom-sdk"
                ? {
                  url: null,
                  api_key:
                    values.api_key.trim() === "" ? null : values.api_key,
                  meeting_number:
                    values.meeting_number.trim() === ""
                      ? null
                      : values.meeting_number,
                  api_secret:
                    values.api_secret.trim() === ""
                      ? null
                      : values.api_secret,
                  meeting_password:
                    values.meeting_password.trim() === ""
                      ? null
                      : values.meeting_password,
                }
                : {
                  url: values.url.trim() === "" ? null : values.url,
                  api_key: null,
                  meeting_number: null,
                  api_secret: null,
                  meeting_password: null,
                },
          }
          : null,
        url_type: ["Sanal", "Virtual"].includes(values.event_types.label)
          ? values.url_type.value
          : null,
        category: values.category.value,
        banner:
          values.banner_type.label === "Çoğul"
            ? [values.banner2.value, values.banner.value]
            : [values.banner.value],
        banner_type: values.banner_type.value,
        city: values.city.map((item) => item.value).includes("all")
          ? cities.map((item) => item.id)
          : values.city.map((item) => item.value),
        branch: values.branch.map((item) => item.value).includes("all")
          ? branches.map((item) => item.id)
          : values.branch.map((item) => item.value),
        start_date: convertToFullDate(values.start_date),
        end_date: convertToFullDate(values.end_date),
      };
      try {
        setLoading(true);
        await createEvent(payload);
        toast.success("Event added successfully");
        closeModal?.();
        navigate("/event-table");
      } catch (error) {
        toast.error(`Something went wrong. ${error}`);
      } finally {
        setLoading(false);
      }
    },
  });

  const handleCancel = () => {
    navigate("/event-table");
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MDBox bgColor="white" sx={{ borderRadius: 4, padding: 5 }}>
          <Text>Add new event</Text>
          <Divider />
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2} mt={1}>
              <Grid item xs={12} md={4} lg={3}>
                <Uploader
                  fileList={fileList}
                  onFileChange={(e) => setFiles(e)}
                  name="Event Image"
                  setImage={setImage}
                  loading={loading}
                  setLoading={setLoading}
                />
              </Grid>
              <Grid item xs="false" md={1}>
                <Divider orientation="vertical" flexItem />
              </Grid>
              <Grid item container xs={12} md={7} lg={8} spacing={2}>
                <Grid item xs={12}>
                  <MDInput
                    fullWidth
                    label="Title"
                    name="title"
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    error={formik.touched.title && Boolean(formik.errors.title)}
                    helperText={formik.touched.title && formik.errors.title}
                  />
                </Grid>
                <Grid item xs={12}>
                  <MDInput
                    fullWidth
                    multiline
                    rows={4}
                    label="Description"
                    name="description"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.description &&
                      Boolean(formik.errors.description)
                    }
                    helperText={
                      formik.touched.description && formik.errors.description
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <DateTimePicker
                    label="Start Date"
                    value={formik.values.start_date}
                    onChange={(value) =>
                      formik.setFieldValue("start_date", value)
                    }
                    ampm={false}
                    format="dd/MM/yyyy HH:mm"
                    renderInput={(params) => <TextField {...params} />}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.start_date &&
                      Boolean(formik.errors.start_date)
                    }
                  />
                  {!!formik.touched.start_date && formik.errors.start_date && (
                    <div
                      style={{
                        color: "#DF2B1C",
                        marginTop: ".1rem",
                        fontSize: 12,
                        marginLeft: 15,
                        fontWeight: "lighter",
                      }}
                    >
                      {formik.errors.start_date}
                    </div>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <DateTimePicker
                    label="End Date"
                    ampm={false}
                    format="dd/MM/yyyy HH:mm"
                    value={formik.values.end_date}
                    onChange={(value) =>
                      formik.setFieldValue("end_date", value)
                    }
                    renderInput={(params) => <TextField {...params} />}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.end_date && Boolean(formik.errors.end_date)
                    }
                  />
                  {!!formik.touched.end_date && formik.errors.end_date && (
                    <div
                      style={{
                        color: "#DF2B1C",
                        marginTop: ".1rem",
                        fontSize: 12,
                        marginLeft: 15,
                        fontWeight: "lighter",
                      }}
                    >
                      {formik.errors.end_date}
                    </div>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Select
                  fullWidth
                  placeholder="Event Type"
                  styles={styles}
                  noOptionsMessage={({ inputValue }) =>
                    `${inputValue || "Event types"} not found.`
                  }
                  value={formik.values.event_types}
                  onChange={(value) =>
                    formik.setFieldValue("event_types", value)
                  }
                  options={eventTypes?.map((item) => {
                    return {
                      label: item.name,
                      value: item.id,
                    };
                  })}
                  error={
                    formik.touched.event_types &&
                    Boolean(formik.errors.event_types)
                  }
                  helperText={
                    formik.touched.event_types && formik.errors.event_types
                  }
                />
                {formik.touched.event_types && formik.errors.event_types && (
                  <div
                    style={{
                      color: "#DF2B1C",
                      marginTop: ".1rem",
                      fontSize: 12,
                      marginLeft: 15,
                      fontWeight: "lighter",
                    }}
                  >
                    {"Please select event types"}
                  </div>
                )}
              </Grid>
              {["Sanal", "Virtual"].includes(
                formik.values?.event_types?.label
              ) && (
                <>
                  <Grid item xs={12} md={4}>
                    <Select
                      fullWidth
                      placeholder="Url Type"
                      styles={styles}
                      noOptionsMessage={({ inputValue }) =>
                        `${inputValue || "URL type"} not found.`
                      }
                      value={formik.values.url_type}
                      onChange={(value) => {
                        formik.setFieldValue("url_type", value);
                      }}
                      options={urlTypes?.map((item) => {
                        return {
                          label: item.name,
                          value: item.id,
                          slug: item.name_slug,
                        };
                      })}
                      error={
                        formik.touched.url_type &&
                        Boolean(formik.errors.url_type)
                      }
                    />
                    {formik.touched.url_type && formik.errors.url_type && (
                      <div
                        style={{
                          color: "#DF2B1C",
                          marginTop: ".1rem",
                          fontSize: 12,
                          marginLeft: 15,
                          fontWeight: "lighter",
                        }}
                      >
                        {"Url type is required"}
                      </div>
                    )}
                  </Grid>

                  {formik.values?.url_type?.slug !== "zoom-sdk" && (
                    <Grid item xs={12} md={8}>
                      <MDInput
                        fullWidth
                        label="Url"
                        name="url"
                        value={formik.values.url}
                        onChange={formik.handleChange}
                        error={formik.touched.url && Boolean(formik.errors.url)}
                        helperText={formik.touched.url && formik.errors.url}
                      />
                    </Grid>
                  )}
                </>
              )}
              {formik.values?.url_type?.slug === "zoom-sdk" && (
                <>
                  <Grid item xs={12} md={4}>
                    <MDInput
                      fullWidth
                      label="Meeting Number"
                      name="meeting_number"
                      value={formik.values.meeting_number}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.meeting_number &&
                        Boolean(formik.errors.meeting_number)
                      }
                      helperText={
                        formik.touched.meeting_number &&
                        formik.errors.meeting_number
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <MDInput
                      fullWidth
                      label="Meeting Password"
                      name="meeting_password"
                      value={formik.values.meeting_password}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.meeting_password &&
                        Boolean(formik.errors.meeting_password)
                      }
                      helperText={
                        formik.touched.meeting_password &&
                        formik.errors.meeting_password
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MDInput
                      fullWidth
                      label="Api Key"
                      name="api_key"
                      value={formik.values.api_key}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.api_key && Boolean(formik.errors.api_key)
                      }
                      helperText={
                        formik.touched.api_key && formik.errors.api_key
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MDInput
                      fullWidth
                      label="Api Secret"
                      name="api_secret"
                      value={formik.values.api_secret}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.api_secret &&
                        Boolean(formik.errors.api_secret)
                      }
                      helperText={
                        formik.touched.api_secret && formik.errors.api_secret
                      }
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <Select
                  fullWidth
                  placeholder="Category"
                  styles={styles}
                  name="category"
                  noOptionsMessage={({ inputValue }) =>
                    `${inputValue || "Category"} not found.`
                  }
                  value={formik.values.category}
                  onChange={(value) => formik.setFieldValue("category", value)}
                  options={categories?.map((item) => {
                    return {
                      label: item.name,
                      value: item.id,
                    };
                  })}
                  error={
                    formik.touched.category && Boolean(formik.errors.category)
                  }
                  helperText={formik.touched.category && formik.errors.category}
                />
                {formik.touched.category && formik.errors.category && (
                  <div
                    style={{
                      color: "#DF2B1C",
                      marginTop: ".1rem",
                      fontSize: 12,
                      marginLeft: 15,
                      fontWeight: "lighter",
                    }}
                  >
                    {"Please select category"}
                  </div>
                )}
              </Grid>
              <Grid item xs={12}>
                <Select
                  fullWidth
                  placeholder="City"
                  styles={styles}
                  name="city"
                  isMulti
                  noOptionsMessage={({ inputValue }) => {
                    if (
                      formik.values.city
                        .map((item) => item.value)
                        .includes("all")
                    ) {
                      return "All cities are already selected.";
                    } else {
                      return `${inputValue || "City"} not found.`;
                    }
                  }}
                  value={formik.values.city}
                  onChange={(selectedOptions) => {
                    const selectedValues = selectedOptions.map(
                      (option) => option.value
                    );
                    if (selectedValues.includes("all")) {
                      formik.setFieldValue("city", [
                        { label: "Select All", value: "all" },
                      ]);
                    } else {
                      formik.setFieldValue(
                        "city",
                        selectedOptions.filter(
                          (option) => option.value !== "all"
                        )
                      );
                    }
                  }}
                  options={
                    formik.values.city.map((item) => item.value).includes("all")
                      ? [{ label: "Select All", value: "all" }]
                      : [
                          { label: "Select All", value: "all" },
                          ...cities?.map((item) => {
                            return {
                              label: item.name,
                              value: item.id,
                            };
                          }),
                        ]
                  }
                  error={formik.touched.city && Boolean(formik.errors.city)}
                />
                {formik.touched.city && formik.errors.city && (
                  <div
                    style={{
                      color: "#DF2B1C",
                      marginTop: ".1rem",
                      fontSize: 12,
                      marginLeft: 15,
                      fontWeight: "lighter",
                    }}
                  >
                    {"Please select city"}
                  </div>
                )}
              </Grid>
              <Grid item xs={12}>
                <Select
                  fullWidth
                  isMulti
                  placeholder="Branch"
                  styles={styles}
                  name="branch"
                  noOptionsMessage={({ inputValue }) => {
                    if (
                      formik.values.branch
                        .map((item) => item.value)
                        .includes("all")
                    ) {
                      return "All branches are already selected.";
                    } else {
                      return `${inputValue || "Branch"} not found.`;
                    }
                  }}
                  value={formik.values.branch}
                  onChange={(selectedOptions) => {
                    const selectedValues = selectedOptions.map(
                      (option) => option.value
                    );
                    if (selectedValues.includes("all")) {
                      formik.setFieldValue("branch", [
                        { label: "Select All", value: "all" },
                      ]);
                    } else {
                      formik.setFieldValue(
                        "branch",
                        selectedOptions.filter(
                          (option) => option.value !== "all"
                        )
                      );
                    }
                  }}
                  options={
                    formik.values.branch
                      .map((item) => item.value)
                      .includes("all")
                      ? [{ label: "Select All", value: "all" }]
                      : [
                          { label: "Select All", value: "all" },
                          ...branches?.map((item) => {
                            return {
                              label: item.name,
                              value: item.id,
                            };
                          }),
                        ]
                  }
                  error={formik.touched.branch && Boolean(formik.errors.branch)}
                />
                {formik.touched.branch && formik.errors.branch && (
                  <div
                    style={{
                      color: "#DF2B1C",
                      marginTop: ".1rem",
                      fontSize: 12,
                      marginLeft: 15,
                      fontWeight: "lighter",
                    }}
                  >
                    {"Please select branch"}
                  </div>
                )}
              </Grid>

              <Grid item xs={12}>
                <Select
                  fullWidth
                  placeholder="Banner Type"
                  styles={styles}
                  name="banner_type"
                  noOptionsMessage={({ inputValue }) =>
                    `${inputValue || "Banner type"} not found.`
                  }
                  value={formik.values.banner_type}
                  onChange={(value) =>
                    formik.setFieldValue("banner_type", value)
                  }
                  options={bannerTypes?.map((item) => {
                    return {
                      label: item.name,
                      value: item.id,
                    };
                  })}
                  error={
                    formik.touched.banner_type &&
                    Boolean(formik.errors.banner_type)
                  }
                  helperText={
                    formik.touched.banner_type && formik.errors.banner_type
                  }
                />
                {formik.touched.banner_type && formik.errors.banner_type && (
                  <div
                    style={{
                      color: "#DF2B1C",
                      marginTop: ".1rem",
                      fontSize: 12,
                      marginLeft: 15,
                      fontWeight: "lighter",
                    }}
                  >
                    {"Please select banner types"}
                  </div>
                )}
              </Grid>
              <Grid
                item
                xs={formik.values.banner_type?.label === "Çoğul" ? 6 : 12}
              >
                <Select
                  fullWidth
                  placeholder="Banner"
                  styles={styles}
                  name="banner"
                  formatOptionLabel={renderOption}
                  noOptionsMessage={({ inputValue }) =>
                    `${inputValue || "Banner"} not found.`
                  }
                  value={formik.values.banner}
                  onChange={(value) => formik.setFieldValue("banner", value)}
                  options={banners?.map((item) => {
                    return {
                      label: item.name,
                      value: item.id,
                      image: item.image,
                    };
                  })}
                  error={formik.touched.banner && Boolean(formik.errors.banner)}
                  helperText={formik.touched.banner && formik.errors.banner}
                />
                {formik.touched.banner && formik.errors.banner && (
                  <div
                    style={{
                      color: "#DF2B1C",
                      marginTop: ".1rem",
                      fontSize: 12,
                      marginLeft: 15,
                      fontWeight: "lighter",
                    }}
                  >
                    {"Please select banner"}
                  </div>
                )}
              </Grid>
              {formik.values.banner_type?.label === "Çoğul" && (
                <Grid item xs={6}>
                  <Select
                    fullWidth
                    placeholder="Banner"
                    styles={styles}
                    name="banner2"
                    formatOptionLabel={renderOption}
                    noOptionsMessage={({ inputValue }) =>
                      `${inputValue || "Banner"} not found.`
                    }
                    value={formik.values.banner2}
                    onChange={(value) => formik.setFieldValue("banner2", value)}
                    options={banners?.map((item) => {
                      return {
                        label: item.name,
                        value: item.id,
                        image: item.image,
                      };
                    })}
                    error={
                      formik.touched.banner2 && Boolean(formik.errors.banner2)
                    }
                  />
                  {formik.touched.banner2 && formik.errors.banner2 && (
                    <div
                      style={{
                        color: "#DF2B1C",
                        marginTop: ".1rem",
                        fontSize: 12,
                        marginLeft: 15,
                        fontWeight: "lighter",
                      }}
                    >
                      {"Please select banner"}
                    </div>
                  )}
                </Grid>
              )}
              <Grid item xs={12}>
                <Select
                  fullWidth
                  placeholder="Product"
                  styles={styles}
                  name="product"
                  formatOptionLabel={renderOption}
                  noOptionsMessage={({ inputValue }) =>
                    `${inputValue || "product"} not found.`
                  }
                  value={formik.values.product}
                  onChange={(value) => formik.setFieldValue("product", value)}
                  options={products?.map((item) => {
                    return {
                      label: item.name,
                      value: item.id,
                      image: item.logo,
                    };
                  })}
                  error={
                    formik.touched.product && Boolean(formik.errors.product)
                  }
                  helperText={formik.touched.product && formik.errors.product}
                />
                {formik.touched.product && formik.errors.product && (
                  <div
                    style={{
                      color: "#DF2B1C",
                      marginTop: ".1rem",
                      fontSize: 12,
                      marginLeft: 15,
                      fontWeight: "lighter",
                    }}
                  >
                    {"Please select product"}
                  </div>
                )}
              </Grid>
              <Grid item container xs={12} spacing={2}>
                <Grid item xs={7} md={8}>
                  <MDButton
                    disabled={loading}
                    variant="contained"
                    color="info"
                    fullWidth
                    type="submit"
                  >
                    Save
                  </MDButton>
                </Grid>
                <Grid item xs={5} md={4}>
                  <MDButton
                    disabled={loading}
                    variant="outlined"
                    color="error"
                    fullWidth
                    onClick={handleCancel}
                  >
                    Cancel
                  </MDButton>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </MDBox>
      </LocalizationProvider>
    </DashboardLayout>
  );
};
