import { useEffect, useRef, useState } from "react";

//3rd libraries
import { toast } from "react-toastify";

// @mui material components


// Material Dashboard 2 React components
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import Footer from "../../components/Footer";
import Table from "../../components/Tables/Table";
import MDButton from "../../components/MDButton";

// components
import DashboardLayout from "../../components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../components/Navbars/DashboardNavbar";
import { InstitutionAndCity, Status, User } from "../../../src/pages/user-table/components/tableItems";

// Data
import { getAllUser, getUserById } from "../../services/userService";
import Modal from "../../components/Modal";
import { EditUserModal } from "./components/editModal";
import {LoadingSpinner} from "../../components/LoadingSpinner";
import {Stack} from "@mui/material";
import { convertToTimeUTC, convertToDate} from "../utils/time";

const UsersTable = () => {
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);

  const editModalRef = useRef(null);

  useEffect(() => {
    fetchData();
  }, [page, limit]);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const { data, pagination_info } = await getAllUser({ page, limit, status: "all" });

      setData(
        data.map((item) => ({
          id: item.id,
          email: item.email,
          is_approved: item.is_approved,
          un_approve_message: item.un_approve_message,
          created_at: item.created_at,
          name_surname: item.user_profile.name_surname,
          tckn: item.user_profile.tckn,
          gsm: item.user_profile.gsm,
          title: item.user_profile.title?.name ?? "",
          institution: item.user_profile.institution,
          city: item.user_profile.city?.name ?? "",
          branch: item.user_profile.branch?.name ?? "",
        }))
      );
      setMaxPage(pagination_info.total_page);
      setIsLoading(false);
    } catch (error) {
      toast.error("Error occured while fetching data");
    } finally {
      setIsLoading(false);
    }
  };

  const excelColumns = [
    "ID",
    "Title",
    "Name Surname",
    "Email",
    "TCKN",
    "GSM",
    "Branch",
    "Institution",
    "City",
    "Is Approved",
    "Unapprove Message",
    "Created At",
  ];

  const excelData = data.map(item => {
    return [
      item.id,
      item.title,
      item.name_surname,
      item.email,
      item.tckn,
      item.gsm,
      item.branch,
      item.institution,
      item.city,
      item.is_approved,
      item.un_approve_message,
      convertToDate(item.created_at),
    ];
  });

  const editModal = async (id) => {
    const data = await getUserById({ id });
    setSelectedUser(data);
    editModalRef.current?.open();
  };

  const columns = [
    {
      Header: "Name Surname",
      accessor: "name",
      width: "25%",
      align: "left",
      Cell: ({ row }) => (
        <>
          <User name_surname={row.original.name_surname} email={row.original.email} title={row.original.title} />
        </>
      ),
    },
    {
      Header: "Organization",
      accessor: "email",
      align: "left",
      width: "20%",
      Cell: ({ row }) => (
        <>
          <InstitutionAndCity
            institution={row.original.institution?.length > 30 ? row.original.institution.substring(0, 30) + "..." : row.original.institution}
            city={row.original.city}
          />
        </>
      ),
    },
    {
      Header: "Branch",
      accessor: "branch",
      align: "left",
      width: "25%",
      Cell: ({ row }) => (
        <MDTypography variant="button" color="text" fontWeight="medium">
          {row.original.branch?.length > 25 ? row.original.branch.substring(0, 25) + "..." : row.original.branch}
        </MDTypography>
      ),
    },
    {
      Header: "ID",
      accessor: "tckn",
      align: "left",
      width: "25%",
      Cell: ({ row }) => (
        <MDTypography variant="button" color="text" fontWeight="medium">
          {row.original.tckn}
        </MDTypography>
      ),
    },
    {
      Header: "GSM",
      accessor: "gsm",
      align: "left",
      width: "25%",
      Cell: ({ row }) => (
        <MDTypography variant="button" color="text" fontWeight="medium">
          {row.original.gsm}
        </MDTypography>
      ),
    },
    {
      Header: "Register Date",
      accessor: "created_ad",
      align: "left",
      width: "17%",
      Cell: ({ row }) => (
          <Stack flexDirection="column">
            <MDTypography variant="button" color="text" fontWeight="medium">
              {convertToDate(row.original.created_at)}
            </MDTypography>
            <MDTypography variant="caption">
              {convertToTimeUTC(row.original.created_at)}
            </MDTypography>
          </Stack>
      ),
    },
    {
      Header: "Status",
      accessor: "is_approved",
      align: "center",
      Cell: ({ row }) => (
        <Status
          status={
            row.original.is_approved === "approve"
              ? "Approved"
              : row.original.is_approved === "pending"
              ? "Pending"
              : row.original.is_approved === "reject"
              ? "Reject"
              : row.original.is_approved === "un-approve"
              ? "Un approved"
              : ""
          }
        />
      ),
    },
    {
      Header: "Detail",
      width: "15%",
      align: "left",
      Cell: ({ row }) => (
        <>
          {row.original.is_approved !== "reject" && (
            <MDButton disabled={isLoading} color="info" size="small" onClick={() => editModal(row.original.id)}>
              Detail
            </MDButton>
          )}
        </>
      ),
    },
  ];

  if (isLoading) return <LoadingSpinner isLoading={isLoading} />;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Modal
        ref={editModalRef}
        children={
          <EditUserModal
            closeModal={editModalRef.current?.close}
            selectedUser={selectedUser}
            setData={setData}
            loading={isLoading}
            setMaxPage={setMaxPage}
            setLoading={setIsLoading}
            page={page}
            limit={limit}
          />
        }
        customStyle={{
          width: { xs: "90%", md: "40%" },
          maxHeight: "90vh",
          overflow: "auto",
        }}
      />

      <MDBox>
        <Table
          title="Users"
          isLoading={isLoading}
          limit={limit}
          setLimit={setLimit}
          setPage={setPage}
          page={page}
          setIsLoading={setIsLoading}
          maxPage={maxPage}
          prevFunc={() => setPage(page - 1)}
          nextFunc={() => setPage(page + 1)}
          columns={columns}
          search={search}
          setSearch={setSearch}
          rows={data}
          excelButton={{
            activeExcel: true,
            excelFunc: () => console.log("excel"),
            excelColumns: excelColumns,
            exportData: excelData,
          }}
        />
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default UsersTable;
