import { Divider, Grid } from "@mui/material";
import React, { useState } from "react";

// 3rd libraries
import { toast } from "react-toastify";
import { useFormik } from "formik";

// @mui
import { styled } from "@mui/system";

// components
import MDBox from "../../components/MDBox";
import MDInput from "../../components/MDInput";
import MDButton from "../../components/MDButton";

// services
import { createCategory } from "../../services/categoryService";

// validation
import { validationSchema } from "./validationSchema";

const Text = styled("h4")({
  color: "#203662",
});

export const AddCategory = ({ closeModal, setData }) => {
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const payload = {
          name: { tr: values.name },
        };
        const data = await createCategory(payload);
        setData((prevState) => [...prevState, data]);
        toast.success("Category added successfully");
        closeModal?.();
      } catch (error) {
        console.log(error)
        toast.error(`Something went wrong: ${error}`);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <MDBox bgColor="white" sx={{ borderRadius: 4 }}>
      <Text>Add new category</Text>
      <Divider />
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12}>
            <MDInput
              fullWidth
              label="Name"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </Grid>

          <Grid item container xs={12} spacing={2}>
            <Grid item xs={7} md={8}>
              <MDButton
                disabled={loading}
                variant="contained"
                color="info"
                fullWidth
                type="submit"
              >
                Save
              </MDButton>
            </Grid>
            <Grid item xs={5} md={4}>
              <MDButton
                disabled={loading}
                variant="outlined"
                color="error"
                fullWidth
                onClick={closeModal}
              >
                Cancel
              </MDButton>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </MDBox>
  );
};
