import * as yup from "yup";

export const validationSchema = yup.object({
  status: yup
    .object()
    .nullable()
    .shape({
      label: yup.string().required("Status is required"),
      value: yup.string().required("Status is required"),
    })
    .required(),
  name: yup.string().when(["status.label", "status.value"], {
    is: (label, value) => value === "un-approve",
    then: yup.string().required("Name is required"),
    otherwise: yup.string().notRequired(),
  }),
});
