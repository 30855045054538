import { branchInstance } from "./http";
import errorHandler from "./errorHandler";

export const getBranches = async (lang) => {
  try {
    const { data } = await branchInstance(lang).get();
    return data;
  } catch (error) {
    const err = errorHandler(error);
    throw err;
  }
};
