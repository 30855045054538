import { Tooltip, styled, Box } from "@mui/material";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import EditIcon from "@mui/icons-material/Edit";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import UnpublishedOutlinedIcon from "@mui/icons-material/UnpublishedOutlined";
import PendingOutlinedIcon from "@mui/icons-material/PendingOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

const Container = styled(Box)({
  position: "relative",
  width: 40,
  height: 55,
  borderRadius: 7,
});

const SmallImage = styled(Box)(({ src }) => ({
  backgroundImage: `url(${src})`,
  backgroundSize: "cover",
  width: "100%",
  height: "100%",
  position: "relative",
  zIndex: 1,
  borderRadius: 7,
}));

export const ImageContainer = ({ url, alt }) => {
  return (
      <Container>
        <SmallImage src={url} alt={alt} />
      </Container>
  );
};

export const ImageContainers = styled("div")({
  width: 50,
  height: 60,
  display: "flex",
  justifyContent: "center",
  borderRadius: "15px",
});

export const TitleContainer = styled("div")({
  maxWidth: 250,
  height: 75,
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "wrap",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
});
export const User = ({ surname, name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDBox lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {`${name} ${surname}`}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
);

export const Date = ({ start, end }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDBox lineHeight={1}>
        <MDTypography variant="subtitle1" fontSize={12} fontWeight="light">
          {start}
        </MDTypography>
        <MDTypography fontSize={14} fontWeight="bold" color="possibleBlue">
          {end}
        </MDTypography>
      </MDBox>
    </MDBox>
);
export const Status = ({ status }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDBox lineHeight={1}>
        {status === "Approved" ? (
            <Tooltip title="Approved" placement="top">
              <MDBox color="success">
                <CheckCircleOutlineOutlinedIcon fontSize="medium" />
              </MDBox>
            </Tooltip>
        ) : status === "Pending" ? (
            <Tooltip title="Pending" placement="top">
              <MDBox color="warning">
                <PendingOutlinedIcon fontSize="medium" />
              </MDBox>
            </Tooltip>
        ) : status === "Reject" ? (
            <Tooltip title="Reject" placement="top">
              <MDBox color="primary">
                <CancelOutlinedIcon fontSize="medium" />
              </MDBox>
            </Tooltip>
        ) : status === "Un approved" ? (
            <Tooltip title="Un approved" placement="top">
              <MDBox color="error">
                <UnpublishedOutlinedIcon fontSize="medium" />
              </MDBox>
            </Tooltip>
        ) : (
            <MDBox color="info">
              <EditIcon fontSize="medium" />
            </MDBox>
        )}
      </MDBox>
    </MDBox>
);

export const Users = ({ image, name_surname, title }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <img src={image} alt={name_surname} width="45px" height="65px" style={{borderRadius: 8}}/>
      <MDBox ml={2} lineHeight={1}>
        <MDTypography variant="caption">{title}</MDTypography>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name_surname}
        </MDTypography>
      </MDBox>
    </MDBox>
);

export const Organization = ({ value }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDBox lineHeight={1}>
        <MDTypography variant="button" color="text" fontWeight="medium">
          {value}
        </MDTypography>
      </MDBox>
    </MDBox>
);

export const DUser = ({ name_surname, email, title }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDBox lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {`${title} ${name_surname}`}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
);

export const InstitutionAndCity = ({ institution, city }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography
          display="block"
          variant="caption"
          color="text"
          fontWeight="medium"
      >
        {institution ?? "No Institution"}
      </MDTypography>
      <MDTypography variant="caption">{city ?? "No City"}</MDTypography>
    </MDBox>
);

export const DStatus = ({ status }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDBox lineHeight={1}>
        {status === "Approved" ? (
            <Tooltip title="Approved" placement="top">
              <MDBox color="success">
                <CheckCircleOutlineOutlinedIcon fontSize="medium" />
              </MDBox>
            </Tooltip>
        ) : status === "Pending" ? (
            <Tooltip title="Pending" placement="top">
              <MDBox color="warning">
                <PendingOutlinedIcon fontSize="medium" />
              </MDBox>
            </Tooltip>
        ) : status === "Reject" ? (
            <Tooltip title="Reject" placement="top">
              <MDBox color="primary">
                <CancelOutlinedIcon fontSize="medium" />
              </MDBox>
            </Tooltip>
        ) : status === "Un approved" ? (
            <Tooltip title="Un approved" placement="top">
              <MDBox color="error">
                <UnpublishedOutlinedIcon fontSize="medium" />
              </MDBox>
            </Tooltip>
        ) : (
            <MDBox color="info">
              <EditIcon fontSize="medium" />
            </MDBox>
        )}
      </MDBox>
    </MDBox>
);
