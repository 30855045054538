import React from "react";
import PropTypes from "prop-types";
import { Container, EmptyContainer } from "./style";
import Preview from "../Preview";
import { toast } from "react-toastify";
import { awsUploadS3 } from "../../services/appService";

const imageTypes = [
  "image/png",
  "image/jpeg",
  "image/gif",
  "image/svg+xml",
  "image/webp",
];
const videoTypes = ["video/mp4"];
// status: 'inited' | 'uploading' | 'error' | 'finished';
const Uploader = ({
  onFileChange,
  fileList,
  multi,
  contentType,
  withContainer,
  loading,
  name,
  isDragDrop,
  jsKey,
  uploadFunction,
  setLoading,
  setImage,
  width,
  height,
}) => {
  const inputRef = React.useRef(null);

  const [isLoading, setIsLoading] = React.useState(false);
  const [isDisabled, setDisabled] = React.useState(false);
  const [isDragging, setDragging] = React.useState(false);

  React.useEffect(() => {
    setDisabled(fileList.length === 0);
  }, [fileList]);

  const fileListChange = async (files) => {
    const fileArr = multi ? (fileList ? [...fileList] : []) : [];
    const newFiles = multi ? files : [files?.[0]];
    // const res = await toBase64(files);
    // let i = 0;
    for (const f of newFiles) {
      const extension = f.name.split(".").pop();
      const fileName = f.name.split(".").shift();
      const type = f.type.split("/").shift();
      if (type === contentType || contentType === "all") {
        const preview = await toBase64Single(f);
        fileArr.push({
          file: f,
          fileName,
          extension,
          type,
          status: "inited",
          // preview: res[i++],
          preview,
          bufferForDecision: 10,
          // idx: fileArr.length > 0 ? fileArr.length + 1 : i
        });
      }
    }
    onFileChange?.(fileArr);
  };

  const toBase64Single = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = async (event) => {
        try {
          const response = event.target.result;
          resolve(response);
        } catch (err) {
          reject(err);
        }
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  };

  const dropHandler = (ev) => {
    setDragging(false);
    ev.preventDefault();
    fileListChange(ev.dataTransfer.files);
  };

  const dragOverHandler = React.useCallback((e) => {
    !isDragging && setDragging(true);
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleDragEnter = React.useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();

    setDragging(true);
    return false;
  }, []);

  const handleDragLeave = React.useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();

    setDragging(false);
    return false;
  }, []);

  const handleRemoveFiles = () => {
    onFileChange?.([]);
  };

  const autoFileUpload = async (files) => {
    if(files && files[0].size >= 2097152) return toast.error("File size must be less than 2MB");
    if (files.length > 0) {
      try {
        setLoading(true);
        fileListChange([...files]);
        try {
          const file = files[0];
          const data = await awsUploadS3({ file: file });
          setImage(data);
          toast.success("Image uploaded successfully");
        } catch (error) {
          toast.error("Image upload failed");
        }
      } catch (error) {
        toast.error("Image upload failed");
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Container
      onDrop={(e) => isDragDrop && !isLoading && dropHandler(e)}
      onDragOver={(e) => isDragDrop && !isLoading && dragOverHandler(e)}
    >
      {isDragging && (
        <div
          onDragEnter={(e) => isDragDrop && !isLoading && handleDragEnter(e)}
          onDragLeave={(e) => isDragDrop && !isLoading && handleDragLeave(e)}
          style={{
            background: "rgb(255,255,255, .4)",
            width: "95%",
            height: "95%",
            position: "absolute",
            zIndex: 1,
          }}
        />
      )}
      {withContainer && (!Array.isArray(fileList) || fileList.length === 0) ? (
        <EmptyContainer
          w={width}
          h={height}
          onClick={() => inputRef.current.click()}
        >
          <span>{name ?? "Photo"}</span>
        </EmptyContainer>
      ) : (
        <Preview
          fileList={fileList}
          editList={onFileChange}
          loading={loading}
          width={width}
          height={height}
        />
      )}

      <input
        ref={inputRef}
        id={jsKey ? jsKey : "file-uploader"}
        type="file"
        accept={
          contentType === "all"
            ? [...imageTypes, ...videoTypes]
            : contentType === "image"
            ? imageTypes
            : videoTypes
        }
        multiple={multi}
        onChange={(e) => autoFileUpload(e.target.files)}
        onClick={(e) => (e.target.value = null)}
      />
    </Container>
  );
};

Uploader.propTypes = {
  onFileChange: PropTypes.func.isRequired,
  fileList: PropTypes.array,
  multi: PropTypes.bool,
  contentType: PropTypes.string,
  withContainer: PropTypes.bool,
  child: PropTypes.node,
  isDragDrop: PropTypes.bool,
  endpoint: PropTypes.string,
};

Uploader.defaultProps = {
  onFileChange: () => {},
  fileList: [],
  multi: false,
  contentType: "image",
  withContainer: true,
  child: null,
  isDragDrop: true,
  endpoint: "",
};

export default Uploader;
