import { useContext, useState } from "react";

// react-router-dom
import { Navigate, useNavigate } from "react-router-dom";

//3rd party libraries
import { toast } from "react-toastify";
import { useFormik } from "formik";

// Context
import { AuthContext } from "../../../context/auth";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import MDInput from "../../../components/MDInput";
import MDButton from "../../../components/MDButton";

// Authentication layout components
import BasicLayout from "../components/BasicLayout";

// Formik validation schema
import { validationSchema } from "./validationSchema";

// Images
import Logo from "../../../assets/images/logo-ct.png";

function SignIn() {
  const [isLoading, setIsLoading] = useState(false);
  const { login, authState } = useContext(AuthContext);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        const payload = {
          email: values.email,
          password: values.password,
        };
        const isLogged = await login(payload);
        if (isLogged) {
          navigate("/");
          toast.success("Login successful.");
        } else {
          toast.error("Login failed. Please check your credentials.");
        }
      } catch (error) {
        toast.error("Login failed. Please check your credentials.");
      } finally {
        setIsLoading(false);
      }
    },
  });

  const handleKeyDown = (event, actions) => {
    if (event.key === "Enter") {
      event.preventDefault();
      actions.submitForm();
    }
  };

  return authState.user ? (
    <Navigate to="/" />
  ) : (
    <BasicLayout>
      <MDBox
        mb={5}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src={Logo} width={100} height={120} alt="logo" />
      </MDBox>
      <Card>
        <MDBox
          bgColor="warning"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Admin Panel
          </MDTypography>
        </MDBox>
        <form onSubmit={formik.handleSubmit}>
          <MDBox pt={4} pb={3} px={3}>
            <MDBox role="form">
              <MDBox mb={2}>
                <MDInput
                  type="email"
                  label="Email"
                  name="email"
                  fullWidth
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type="password"
                  label="Password"
                  name="password"
                  fullWidth
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                />
              </MDBox>
              <MDBox
                mt={4}
                mb={1}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <MDButton
                  color="info"
                  sx={{ width: "100%" }}
                  disabled={isLoading}
                  type="submit"
                >
                  {isLoading ? "Loading..." : "Login"}
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
        </form>
      </Card>
    </BasicLayout>
  );
}

export default SignIn;
