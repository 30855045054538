import dayjs from "dayjs";
import moment from "moment/moment";

//TODO: moment admin panel gösterimlerinde kullanılıyor
//TODO: dayjs ise backende göndermek için kullanılıyor

export function convertToTimeUTC(date) {
  return moment(date).utc().format("HH:mm");
}

/*20 February 2023 Monday | 00:00*/
export const convertToUtcLongDate = (date) => {
  return moment(date).utc().format("DD MMMM YYYY dddd | HH:mm");
};

/*20 February 2023 Monday */
export const convertToUtcWithoutTime = (date) => {
  return moment(date).utc().format("DD MMMM YYYY dddd");
};

export const convertToFullDate = (date) => {
  return dayjs(date).format("YYYY-MM-DD HH:mm:ss")
}
/*2021-02-20*/
export const convertToDate = (date) => {
  return moment(date).utc().format("DD-MM-YYYY");
};

export const convertToDateTime = (date) => {
  return moment(date).utc().format("YYYY-MM-DD HH:mm:ss");
};
