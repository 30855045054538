export const styles = {
  control: (base, state) => ({
    ...base,
    borderColor: state.isFocused ? "info" : "rgba(0, 0, 0, 0.17)",
    fontSize: "16px",
    color: "#495057",
    borderRadius: "5px",
  }),
  placeholder: (base) => ({
    ...base,
    fontSize: "14px",
    color: "#7b809a",
    position: "absolute",
    overflow: "hidden",
  }),
  option: (base) => ({
    ...base,
    fontSize: "14px",
  }),
  noOptionsMessage: (base) => ({
    ...base,
    fontSize: "14px",
    color: "#7b809a",
    backgroundColor: "white",
  }),
  singleValue: (base) => ({
    ...base,
    color: "red",
    fontSize: "14px",
    fontHeight: "1.4375em",
  }),
  container: (base) => ({
    ...base,
    backgroundColor: "white",
  }),
  multiValue: (styles) => {
    return {
      ...styles,
      backgroundColor: "rgba(43, 99, 187, 0.12)",
    };
  },
  multiValueLabel: (styles) => {
    return {
      ...styles,
      color: "rgba(43, 99, 187, 1)",
    };
  },
  multiValueRemove: (styles) => {
    return {
      ...styles,
      color: "rgba(43, 99, 187, 1)",
    };
  },
};
