import { Select, Tooltip, styled } from "@mui/material";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import EditIcon from "@mui/icons-material/Edit";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import PendingOutlinedIcon from "@mui/icons-material/PendingOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";


export const CustomSelect = styled(Select)({
  position: 'absolute',
  width: '228px',
  height: '40px',
  left: '362px',
  top: '477px',
  background: '#FAFBFC',
  borderRadius: '24px',
});

export const FilterInput = styled("input")({
  position: "absolute",
  width: "228px",
  height: "40px",
  left: "362px",
  top: "477px",
  background: "#FAFBFC",
  borderRadius: "24px",
});

export const TitleContainer = styled("div")({
  maxWidth: 650,
  height: "auto",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "wrap",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
});
export const User = ({ surname, name, email }) => (
  <MDBox display="flex" alignItems="center" lineHeight={1}>
    <MDBox lineHeight={1}>
      <MDTypography display="block" variant="button" fontWeight="medium">
        {`${name} ${surname}`}
      </MDTypography>
      <MDTypography variant="caption">{email}</MDTypography>
    </MDBox>
  </MDBox>
);

export const InstitutionAndCity = ({ institution, city }) => (
  <MDBox lineHeight={1} textAlign="left">
    <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
      {institution}
    </MDTypography>
    <MDTypography variant="caption">{city}</MDTypography>
  </MDBox>
);

export const Status = ({ status }) => (
  <MDBox display="flex" alignItems="center" lineHeight={1}>
    <MDBox lineHeight={1}>
      {status === "Approved" ? (
        <Tooltip title="Approved" placement="top">
          <MDBox color="success">
            <CheckCircleOutlineOutlinedIcon fontSize="medium" />
          </MDBox>
        </Tooltip>
      ) : status === "Pending" ? (
        <Tooltip title="Pending" placement="top">
          <MDBox color="warning">
            <PendingOutlinedIcon fontSize="medium" />
          </MDBox>
        </Tooltip>
      ) : status === "Reject" ? (
        <Tooltip title="Reject" placement="top">
          <MDBox color="primary">
            <CancelOutlinedIcon fontSize="medium" />
          </MDBox>
        </Tooltip>
      ) : (
        <MDBox color="info">
          <EditIcon fontSize="medium" />
        </MDBox>
      )}
    </MDBox>
  </MDBox>
);
