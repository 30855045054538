import { authInstance } from "./http";
import errorHandler from "./errorHandler";
import { getDeviceId } from "../helpers";

export const authLogin = async ({ email, password, lang }) => {
  try {
    const payload = {
      email,
      password,
      device_uuid: getDeviceId(),
    };
    const headers = {
      "x-panel": "admin",
    };
    const { data } = await authInstance(lang).post(`/login`, payload, { headers });
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

export const authLogout = async ({ lang }) => {
  try {
    const payload = {
      device_uuid: getDeviceId(),
    };
    const { data } = await authInstance(lang).post(`/logout`, payload);
    return data;
  } catch (error) {
    throw errorHandler(error);
  }
};

export const refreshToken = async ({ refresh_token, lang }) => {
  try {
    const payload = {
      refresh_token,
      device_uuid: getDeviceId(),
    };
    const { data } = await authInstance(lang).post("/refresh/tokens", payload);
    return data;
  } catch (error) {
    throw errorHandler(error);
  }
};
