import { useEffect, useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";

//3rd libraries
import { toast } from "react-toastify";

// @mui material components
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { Tooltip } from "@mui/material";

// components
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import Table from "../../../components/Tables/Table";
import { Date } from "./style";
import { getEvents } from "../../../services/eventService";

import { LoadingSpinner } from "../../../components/LoadingSpinner";
import {
  convertToTimeUTC,
  convertToUtcWithoutTime,
  convertToDate, convertToUtcLongDate,
} from "../../utils/time";
import {
  ImageContainer,
  TitleContainer,
} from "../../event/event-table/components/tableItems";

const DashboardEventTable = () => {
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, [page, limit]);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const { data, pagination_info } = await getEvents({
        page,
        limit,
        participant_count: 1,
      });
      setData(
        data.map((item) => ({
          id: item.id,
          title: item.title,
          category: item.category.name ?? "",
          start_date: item.start_date,
          end_date: item.end_date,
          event_type: item.event_type.name ?? "",
          is_active: item.is_active,
          image: item.image ?? "",
          participant_count: item.participant_count ?? [],
          // branch: item.branch.name ?? {},
          created_at: convertToDate(item.created_at),
          updated_at: convertToDate(item.updated_at),
          // speaker: item.event_speaker ?? [],
          description: item.description ?? "",
        }))
      );
      setMaxPage(pagination_info.total_page);
    } catch (error) {
      setTimeout(() => {
        toast.error("Something went wrong");
      }, 2000);
    }
    setIsLoading(false);
  };

  const excelColumns = [
    "ID",
    "Title",
    "Category",
    "Start Date",
    "End Date",
    "Event Type",
    "Is Active",
    "Image",
    "Participant Count",
    // b "Branch",
    "Created At",
    "Updated At",
    // sp "Speaker",
    "Description",
  ];

  const excelData = data.map(item => {
    return [
      item.id,
      item.title,
      item.category,
      convertToUtcLongDate(item.start_date),
      convertToUtcLongDate(item.end_date),
      item.event_type,
      item.is_active,
      item.image,
      item.participant_count[0]?.count ?? 0,
      item.created_at,
      item.updated_at,
      item.description
    ];
  });

  const columns = [
    {
      Header: "Image",
      accessor: "image",
      align: "left",
      Cell: ({ row }) => (
        <ImageContainer url={row.original.image} alt="event" />
      ),
    },
    {
      Header: "Title",
      accessor: "name",
      align: "left",
      Cell: ({ row }) => (
        <TitleContainer>
          <MDTypography
            sx={{
              "&:hover": { textDecoration: "underline", cursor: "pointer" },
            }}
            onClick={() =>
              navigate({
                pathname: `/event-table/details`,
                search: createSearchParams({
                  id: row.original.id,
                }).toString(),
              })
            }
            fontSize={14}
            fontWeight="bold"
            color="possibleBlue"
          >
            {row.original.title.length > 70
              ? row.original.title.substring(0, 70) + "..."
              : row.original.title}
          </MDTypography>
        </TitleContainer>
      ),
    },
    {
      Header: "Description",
      accessor: "description",
      align: "left",
      Cell: ({ row }) => (
        <TitleContainer>
          <MDTypography variant="subtitle1" fontSize={12} fontWeight="light">
            {row.original.description.length > 85
              ? row.original.description.substring(0, 85) + "..."
              : row.original.description}
          </MDTypography>
        </TitleContainer>
      ),
    },
    {
      Header: "Date",
      accessor: "start_date",
      align: "left",
      Cell: ({ row }) => (
        <Date
          start={`${convertToUtcWithoutTime(row.original.start_date)}`}
          end={`${convertToTimeUTC(
            row.original.start_date
          )} - ${convertToTimeUTC(row.original.end_date)}`}
        />
      ),
    },
    {
      Header: "Type",
      accessor: "event_type",
      align: "left",
      Cell: ({ row }) => (
        <MDTypography variant="button" color="text" fontWeight="medium">
          {row.original.event_type}
        </MDTypography>
      ),
    },
    {
      Header: "Category",
      accessor: "category",
      align: "left",
      Cell: ({ row }) => (
        <MDTypography variant="button" color="text" fontWeight="medium">
          {row.original.category}
        </MDTypography>
      ),
    },
    {
      Header: "Status",
      accessor: "is_approved",
      align: "center",
      Cell: ({ row }) => (
        <>
          {row.original.is_active ? (
            <Tooltip title="Published" placement="top">
              <MDBox
                color="success"
                sx={{ display: "flex", flexDirection: "row" }}
              >
                <CheckCircleOutlineOutlinedIcon fontSize="medium" />
              </MDBox>
            </Tooltip>
          ) : (
            <Tooltip title="Unpublished" placement="top">
              <MDBox
                color="error"
                sx={{ display: "flex", flexDirection: "row" }}
              >
                <CancelOutlinedIcon fontSize="medium" placement="top" />
              </MDBox>
            </Tooltip>
          )}
        </>
      ),
    },
    {
      Header: "Participant",
      accessor: "participant_count",
      align: "left",
      Cell: ({ row }) => (
        <MDBox
          sx={{ display: "flex", flexDirection: "column" }}
          onClick={() =>
            navigate({
              pathname: `/event-table/details`,
              search: createSearchParams({
                id: row.original.id,
                tabs: 2,
              }).toString(),
            })
          }
        >
          {row.original.participant_count.map((item) => (
            <MDTypography
              key={item._id}
              textTransform="capitalize"
              variant="text"
              color="text"
              fontWeight="medium"
              sx={{
                "&:hover": { textDecoration: "underline", cursor: "pointer" },
              }}
            >
              {item._id}: {item.count}
            </MDTypography>
          ))}
        </MDBox>
      ),
    },
  ];

  if (isLoading) {
    return <LoadingSpinner isLoading={isLoading} />;
  }

  return (
    <Table
      title="Events"
      isLoading={isLoading}
      limit={limit}
      setLimit={setLimit}
      setPage={setPage}
      page={page}
      setIsLoading={setIsLoading}
      maxPage={maxPage}
      prevFunc={() => setPage(page - 1)}
      nextFunc={() => setPage(page + 1)}
      columns={columns}
      search={search}
      setSearch={setSearch}
      rows={data}
      excelButton={{
        activeExcel: true,
        excelFunc: () => console.log("excel"),
        excelColumns: excelColumns,
        exportData: excelData,
      }}
    />
  );
};

export default DashboardEventTable;
