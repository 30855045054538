import DashboardLayout from "../../components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../components/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {getContents, isActivatedContent, updateContent} from "../../services/contentService";
import { ImageContainer, TitleContainer } from "./components/tableItems";
import MDTypography from "../../components/MDTypography";
import { createSearchParams, useNavigate } from "react-router-dom";
import { Tooltip } from "@mui/material";
import MDButton from "../../components/MDButton";
import EditIcon from "@mui/icons-material/Edit";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import Table from "../../components/Tables/Table";
import { CustomSwitch } from "../event/eventDetails/sectionCrud/tableItems";

export const Consent = () => {
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);


  const navigate = useNavigate();
  useEffect(() => {
    fetchData();
  }, [page, limit]);
  const fetchData = async () => {
    try {
      setIsLoading(true);
      const { data, pagination_info } = await getContents({
        page,
        limit,
      });
      setData(data);
      setMaxPage(pagination_info.total_page);
    } catch (error) {
      toast.error("Something went wrong");
    }
    setIsLoading(false);
  };

  const handleChangeStatus = async (id) => {
    console.log(id)
    try {
      setIsLoading(true)
      await isActivatedContent({ id });
      fetchData();
      toast.success("Content status changed successfully");
    } catch (error) {
      toast.error("Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  const columns = [
    {
      Header: "Image",
      accessor: "image",
      align: "left",
      width: 50,
      Cell: ({ row }) => (
        <ImageContainer url={row.original.image} alt="event" />
      ),
    },
    {
      Header: "Title",
      accessor: "name",
      align: "left",
      width: 100,
      Cell: ({ row }) => (
        <TitleContainer>
          <MDTypography fontSize={14} fontWeight="bold" color="possibleBlue">
            {row.original.title.length > 100
              ? row.original.title.substring(0, 100) + "..."
              : row.original.title}
          </MDTypography>
        </TitleContainer>
      ),
    },
    {
      Header: "Description",
      accessor: "description",
      align: "left",
      Cell: ({ row }) => (
        <TitleContainer>
          <MDTypography variant="subtitle1" fontSize={12} fontWeight="light">
            {row.original.description.length > 120
              ? row.original.description.substring(0, 120) + "..."
              : row.original.description}
          </MDTypography>
        </TitleContainer>
      ),
    },
    {
      Header: "Status",
      accessor: "is_active",
      align: "left",
      cWidth: 50,
      Cell: ({ row }) => (
        <Tooltip title={row.original.is_active ? "active" : "passive"}>
        <CustomSwitch
          color="possibleBlue"
          checked={row.original.is_active}
          onChange={() => handleChangeStatus(row.original.id)}
          name="switch"
        />
        </Tooltip>
      ),
    },
    {
      Header: "Action",
      accessor: "action",
      align: "center",
      Cell: ({ row }) => (
        <Tooltip title="Update">
          <MDButton
            size="small"
            sx={{ maxWidth: 30, minWidth: 30, width: 30 }}
            color="warning"
            onClick={() =>
              navigate({
                pathname: `/content/update?id=${row.original.id}`,
              })
            }
          >
            <EditIcon />
          </MDButton>
        </Tooltip>
      ),
    },
  ];

  if (isLoading) {
    return <LoadingSpinner isLoading={isLoading} />;
  }

  return (
    <div>
      <DashboardLayout>
        <DashboardNavbar />
        <Table
          title="Contents"
          isLoading={isLoading}
          limit={limit}
          setLimit={setLimit}
          setPage={setPage}
          page={page}
          setIsLoading={setIsLoading}
          maxPage={maxPage}
          prevFunc={() => setPage(page - 1)}
          nextFunc={() => setPage(page + 1)}
          columns={columns}
          rows={data}
          customButton={{
            active: true,
            name: "Add content",
            func: () => navigate("/content/add"),
          }}
        />
      </DashboardLayout>
    </div>
  );
};
