import { useEffect, useRef, useState } from "react";

//3rd libraries
import { toast } from "react-toastify";

// @mui
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import MDBox from "../../components/MDBox";
import MDButton from "../../components/MDButton";
import Modal from "../../components/Modal";


// Material Dashboard 2 React example components
import DashboardLayout from "../../components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../components/Navbars/DashboardNavbar";
import Footer from "../../components/Footer";
import Table from "../../components/Tables/Table";
import { Tooltip } from "@mui/material";
import { AddSpeaker } from "./components/addSpeakerModal";
import { UpdateSpeaker } from "./components/updateSpeakerModal";
import { Organization, Users } from "./components/tableItems";

// services
import { deleteSpeaker, getSpeakers } from "../../services/speakersService";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { ConfirmModal } from "../../components/ConfirmModal";

const SpeakersTable = () => {
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [close, setClose] = useState(false);
  const [idX, setIdX] = useState("");

  const addModalRef = useRef();
  const updateModalRef = useRef();
  const deleteSectionRef = useRef(null);

  const handleConfirm = (id) => {
    deleteSectionRef?.current.open();
    setIdX(id);
  };

  useEffect(() => {
    fetchData();
  }, [page, limit]);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const { data, pagination_info } = await getSpeakers({ page, limit });
      setData(
        data.map((item) => ({
          id: item.id,
          image: item.image,
          institution: item.institution,
          name_surname: item.name_surname,
          title: item.title.name,
          branch: item.branch.name,
        }))
      );
      setMaxPage(pagination_info.total_page);
      setIsLoading(false);
    } catch (error) {
      toast.error("Speaker list failed.");
      setIsLoading(false);
    }
  };

  const updateSpeaker = (data) => {
    setSelectedUser(data);
    updateModalRef.current?.open();
  };

  const removeSpeaker = async () => {
    const payload = {
      id: idX,
    };
    try {
      await deleteSpeaker(payload);
      toast.success("Speaker deleted successfully.");
      fetchData();
    } catch (error) {
      if (
        error === "Bu konuşmacı altında bir etkinlik olduğu için silemezsiniz"
      ) {
        toast.error("Speaker deleted failed. Speaker registered to an event!");
      } else {
        toast.error("Speaker deleted failed.");
      }
    }
  };

  const columns = [
    {
      Header: "Speaker",
      accessor: "name_surname",
      align: "left",
      Cell: ({ row }) => (
        <Users
          image={row.original.image}
          name_surname={row.original.name_surname}
          title={row.original.title}
        />
      ),
    },
    {
      Header: "branch",
      accessor: "branch",
      align: "left",
      Cell: ({ row }) => <Organization value={row.original.branch} />,
    },
    {
      Header: "institution",
      accessor: "institution",
      align: "left",
      width: "100%",
      Cell: ({ row }) => (
        <Organization
          value={
            row.original.institution.length > 60
              ? row.original.institution.substring(0, 60) + "..."
              : row.original.institution
          }
        />
      ),
    },
    {
      Header: "Actions",
      align: "center",
      Cell: ({ row }) => (
        <>
          <Tooltip title="Update">
            <MDButton
              size="small"
              sx={{marginRight: 1, maxWidth: 30, minWidth: 30, width: 30 }}
              color="warning"
              onClick={() => updateSpeaker(row)}
            >
              <EditIcon />
            </MDButton>
          </Tooltip>
          <Tooltip title="Delete">
            <MDButton
              size="small"
              sx={{ maxWidth: 30, minWidth: 30, width: 30 }}
              color="error"
              onClick={() => handleConfirm(row.original.id)}
            >
              <DeleteIcon />
            </MDButton>
          </Tooltip>
        </>
      ),
    },
  ];

  if (isLoading) return <LoadingSpinner isLoading={isLoading} />;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Modal
        closed={close}
        ref={addModalRef}
        customStyle={{
          width: { xs: "90%", md: "60%" },
          maxHeight: "90vh",
          overflow: "auto",
        }}
        children={
          <AddSpeaker
            setData={setData}
            closeFunc={() => setClose((prevState) => !prevState)}
            closeModal={addModalRef.current?.close}
          />
        }
      />
      <Modal
        ref={updateModalRef}
        children={
          <UpdateSpeaker
            closeModal={updateModalRef.current?.close}
            setData={setData}
            selectedUser={selectedUser}
            setMaxPage={setMaxPage}
            page={page}
            limit={limit}
          />
        }
        customStyle={{
          width: { xs: "90%", md: "60%" },
          maxHeight: "90vh",
          overflow: "auto",
        }}
      />
      <Modal
        ref={deleteSectionRef}
        children={
          <ConfirmModal
            closeModal={deleteSectionRef.current?.close}
            deleteFunc={() => removeSpeaker()}
            title="Are you sure that you wish to delete this speaker?"
          />
        }
      />
      <MDBox>
        <MDBox>
          <Table
            title="Speakers"
            isLoading={isLoading}
            limit={limit}
            setLimit={setLimit}
            setPage={setPage}
            page={page}
            setIsLoading={setIsLoading}
            maxPage={maxPage}
            prevFunc={() => setPage(page - 1)}
            nextFunc={() => setPage(page + 1)}
            columns={columns}
            search={search}
            setSearch={setSearch}
            rows={data}
            customButton={{
              active: true,
              name: "Add new speaker",
              func: () => setClose(true),
            }}
            // excelButton={{
            //   activeExcel: true,
            //   excelFunc: () => console.log("excel"),
            // }}
          />
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default SpeakersTable;
