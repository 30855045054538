import { speakerInstance } from "./http";
import errorHandler from "./errorHandler";

export const getAllSpeaker = async (lang) => {
  try {
    const { data } = await speakerInstance(lang).get();
    return data;
  } catch (error) {
    const err = errorHandler(error);
    throw err;
  }
};

export const getSpeakers = async ({ page, limit, lang}) => {
  try {
    const { data } = await speakerInstance(lang).get(
      `?pagination=${1}&page=${page}&limit=${limit}&order_desc=1`
    );
    return data;
  } catch (error) {
    const err = errorHandler(error);
    throw err;
  }
};

export const getSpeaker = async ({ id, lang }) => {
  try {
    const { data } = await speakerInstance(lang).get(`/${id}`);
    return data;
  } catch (error) {
    const err = errorHandler(error);
    throw err;
  }
};

export const createSpeaker = async ({ image, name_surname, institution, branch, title, lang }) => {
  const payload = { image, name_surname, institution, branch, title };
  try {
    const { data } = await speakerInstance(lang).post(`/`, payload);
    return data;
  } catch (error) {
    const err = errorHandler(error);
    throw err;
  }
};

export const updateSpeaker = async ({ id, image, name_surname, institution, branch, title, lang }) => {
  const payload = { image, name_surname, institution, branch, title };
  try {
    const { data } = await speakerInstance(lang).put(`/${id}`, payload);
    return data;
  } catch (error) {
    const err = errorHandler(error);
    throw err;
  }
};

export const deleteSpeaker = async ({ id, lang }) => {
  try {
    const { data } = await speakerInstance(lang).delete(`/${id}`);
    return data;
  } catch (error) {
    const err = errorHandler(error);
    throw err;
  }
};
