import { Card } from "@mui/material";
import { styled } from "@mui/material/styles";
import pxToRem from "../../../../assets/theme/functions/pxToRem";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

export const EventDetailContainer = styled(Card)(({ theme }) => {
  const { palette, functions } = theme;

  const { white } = palette;
  const { pxToRem } = functions;

  return {
    color: white.main,
    padding: pxToRem(20),
    textAlign: "center",
  };
});

export const Container = styled("div")((props) => ({
  background: "white",
  textAlign: "center",
  padding: pxToRem(30),
  borderRadius: pxToRem(12),
}));

export const Image = styled("img")(({ theme, ...props }) => ({
  maxWidth: props.mW || 180,
  width: props.w || 180,
  height: props.h || 220,
  borderRadius: props.br || pxToRem(12.5),
  float: props.float || "left",
  marginRight: pxToRem(20),
  [theme.breakpoints.down("md")]: {
    marginBottom: pxToRem(10),
  },
}));

export const Title = styled("div")(({ theme }) => ({
  textAlign: "justify",
}));

export const StyledTabs = styled((props) => <Tabs {...props} TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }} />)({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    display: "none",
    maxWidth: 60,
    width: "100%",
    backgroundColor: "#0064A8",
  },
});

export const StyledTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: "none",
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.pxToRem(16),
  paddingLeft: 0,
  color: "#B0B7C3",
  "&.Mui-selected": {
    color: "#0064A8",
  },
  "&.Mui-focusVisible": {
    backgroundColor: "rgba(100, 95, 228, 0.32)",
  },
}));
