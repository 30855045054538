import { Stack, Tooltip } from "@mui/material";
import MDTypography from "../../../../components/MDTypography";
import { convertToTimeUTC} from "../../../utils/time";
import MDButton from "../../../../components/MDButton";
import EditIcon from "@mui/icons-material/Edit";
import React, {  useRef, useState } from "react";
import MDBox from "../../../../components/MDBox";
import Modal from "../../../../components/Modal";
import { UpdateSection } from "../sectionCrud/updateSectionModal";
import DeleteIcon from "@mui/icons-material/Delete";
import { deleteSection } from "../../../../services/eventSpeakerService";
import { getEvent } from "../../../../services/eventService";
import { toast } from "react-toastify";
import { ConfirmModal } from "../../../../components/ConfirmModal";
import {useSearchParams} from "react-router-dom";

const EventSpeakerList = ({
  event_speaker,
  setData,
  setLoading,
  data,
}) => {
  const [value, setValue] = useState([]);
  const [sectionId, setSectionId] = useState("");

  const updateSectionRef = useRef(null);
  const deleteSectionRef = useRef(null);

  const updateSection = (speaker) => {
    setValue(speaker);
    updateSectionRef.current?.open();
  };

  const [searchParams] = useSearchParams();
  const event = searchParams.get("id");
  const handleConfirm = (item) => {
    deleteSectionRef?.current.open();
    setSectionId(item.id);
  };

  const sortedEventSpeaker = event_speaker ? event_speaker.sort((a, b) => {
    if (b.is_moderator && !a.is_moderator) return 1;
    if (!b.is_moderator && a.is_moderator) return -1;
    return 0;
  }) : [];

  const handleDelete = async () => {
    const payload = {
      id: sectionId,
    };
    try {
      setLoading(true);
      await deleteSection(payload);
      const data = await getEvent({ id: event });
      setData(data);
      toast.success("Session deleted successfully.");
    } catch (err) {
      toast.error("Session delete failed.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal
        ref={updateSectionRef}
        children={
          <UpdateSection
            closeModal={updateSectionRef.current?.close}
            event={value}
            setData={setData}
            data={data}
          />
        }
        customStyle={{
          width: { xs: "80%", md: "35%" },
          maxHeight: "90vh",
          overflow: "auto",
        }}
      />
      <Modal
        ref={deleteSectionRef}
        children={
          <ConfirmModal
            closeModal={deleteSectionRef.current?.close}
            deleteFunc={() => handleDelete()}
            title = "Are you sure that you wish to delete this session?"
          />
        }
      />
      <Stack mt={2} component="div">
        {sortedEventSpeaker.map((item, i) => {
          return (
            <Stack flexDirection="row" key={i} mt={1} alignItems="flex-start">
              {item.is_moderator ? (
                <MDTypography
                  mr={2}
                  sx={{ minWidth: 130 }}
                  fontSize={16}
                  fontWeight="medium"
                  color="possibleBlue"
                >
                  Moderator name
                </MDTypography>
              ) : (
                <MDTypography
                  mr={2}
                  sx={{ minWidth: 130 }}
                  fontSize={16}
                  fontWeight="medium"
                  color="possibleBlue"
                >
                  {`${convertToTimeUTC(item.start_date)} - ${convertToTimeUTC(
                    item.end_date
                  )}`}
                </MDTypography>
              )}
              {item.speakers.map((speaker, j) => (
                <Stack
                  key={j}
                  flexDirection="column"
                  mr={4}
                  sx={{ overflow: "hidden", wordWrap: "break-word" }}
                >
                  <MDTypography
                    variant="subtitle1"
                    fontSize={15}
                    fontWeight="light"
                  >
                    {`${speaker.title.name} ${speaker.name_surname}`}
                  </MDTypography>
                  <MDTypography
                    variant="subtitle1"
                    fontSize={15}
                    fontWeight="light"
                  >
                    {speaker.branch.name}
                  </MDTypography>
                  <MDTypography
                    variant="subtitle1"
                    fontSize={15}
                    fontWeight="light"
                  >
                    {speaker.institution}
                  </MDTypography>
                </Stack>
              ))}
              <MDBox
                sx={{
                  marginLeft: "auto",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Tooltip title="Update">
                  <MDButton
                    sx={{ marginLeft: 2, marginRight: 1 }}
                    color="warning"
                    onClick={() => updateSection(item)}
                  >
                    <EditIcon />
                  </MDButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <MDButton color="error" onClick={() => handleConfirm(item)}>
                    <DeleteIcon />
                  </MDButton>
                </Tooltip>
              </MDBox>
            </Stack>
          );
        })}
      </Stack>
    </>
  );
};
export default EventSpeakerList;
