import { contentInstance } from "./http";
import errorHandler from "./errorHandler";

export const getContents = async ({page, limit, lang}) => {
  try {
    const { data } = await contentInstance(lang).get(`/?pagination=1&page=${page}&limit=${limit}&order_desc=1`);
    return data;
  } catch (error) {
    const err = errorHandler(error);
    throw err;
  }
}

export const getContent = async ({ id, lang, isCrud }) => {
  const payload = {
    isCrud,
  };
  try {
    const { data } = await contentInstance(lang).get(`/${id}`, payload);
    return data;
  } catch (error) {
    const err = errorHandler(error);
    throw err;
  }
}
export const createContent = async ({ title, description, image, lang }) => {
  const payload = {
    title,
    description,
    image,
  };
  try {
    const { data } = await contentInstance(lang).post(`/`, payload);
    return data;
  } catch (error) {
    const err = errorHandler(error);
    throw err;
  }

}

export const updateContent = async ({ id, title, description, image, lang }) => {
  const payload = {
    title,
    description,
    image,
  };
  try {
    const { data } = await contentInstance(lang).put(`/${id}`, payload);
    return data;
  } catch (error) {
    const err = errorHandler(error);
    throw err;
  }
}

export const isActivatedContent = async ({ id, lang }) => {
  try {
    const { data } = await contentInstance(lang).patch(`/${id}`);
    return data;
  } catch (error) {
    const err = errorHandler(error);
    throw err;
  }
}