import { appInstance } from "./http";
import errorHandler from "./errorHandler";

export const getUserCount = async (lang) => {
  try {
    const { data } = await appInstance(lang).get(`/dashboard/user-count`);
    return data;
  } catch (error) {
    throw errorHandler(error);
  }
};

export const getSpeakerCount = async (lang) => {
  try {
    const { data } = await appInstance(lang).get(`/dashboard/speaker-count`);
    return data;
  } catch (error) {
    throw errorHandler(error);
  }
};

export const getProductsCount = async (lang) => {
  try {
    const { data } = await appInstance(lang).get(`/dashboard/product-count`);
    return data;
  } catch (error) {
    throw errorHandler(error);
  }
};

export const getEventsCount = async (lang) => {
  try {
    const { data } = await appInstance(lang).get(`/dashboard/event-count`);
    return data;
  } catch (error) {
    throw errorHandler(error);
  }
};
