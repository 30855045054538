import React from "react";
import {Link, useNavigate} from "react-router-dom";
import PageLayout from "../../components/LayoutContainers/PageLayout";
import {Stack} from "@mui/material";
import MDTypography from "../../components/MDTypography";
import Icon from "@mui/material/Icon";
import MDButton from "../../components/MDButton";

function NotFound() {
  const navigate = useNavigate();
  return (
    <PageLayout>
      <Stack display="flex" alignItems="center" justifyContent="center" height="100vh" p={5}>
        <Icon fontSize="large" color="error">error</Icon>
        <MDTypography mt={3} variant="h2">404</MDTypography>
        <MDTypography variant="h4" >NOT FOUND</MDTypography>
        <MDTypography mt={1} mb={3} fontWeight="regular" variant="caption" >The page you are looking for does not exist. How you got here is a mystery. But you can click the button below to go back to the homepage.</MDTypography>
        <MDButton mt={5} onClick={() => navigate("/")} color={"info"}>Home Page</MDButton>
      </Stack>
    </PageLayout>
  );
}

export default NotFound;
