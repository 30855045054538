import { Switch, styled } from "@mui/material";
import MDBox from "../../../../components/MDBox";
import MDAvatar from "../../../../components/MDAvatar";
import MDTypography from "../../../../components/MDTypography";

export const TextTitle = styled("h4")({
  color: "#203662",
});
export const CustomSwitch = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
    "&:before": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.secondary.main)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    "&:after": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
  },
}));
export const Users = ({ image, name_surname, title }) => (
  <MDBox display="flex" alignItems="center" lineHeight={1}>
    <MDAvatar src={image} name={name_surname} size="sm" />
    <MDBox ml={2} lineHeight={1}>
      <MDTypography variant="caption">{title}</MDTypography>
      <MDTypography display="block" variant="button" fontWeight="medium">
        {name_surname}
      </MDTypography>
    </MDBox>
  </MDBox>
);

export const Organization = ({ value, branch }) => (
  <MDBox display="flex" alignItems="center" lineHeight={1}>
    <MDBox lineHeight={1}>
      <MDTypography variant="caption">{branch}</MDTypography>
      <MDTypography display="block" variant="button" fontWeight="medium">
        {value}
      </MDTypography>
    </MDBox>
  </MDBox>
);

export const Time = ({ value }) => (
  <MDBox display="flex" alignItems="center" lineHeight={1}>
    <MDTypography display="block" color="possibleBlue" fontWeight="medium">
      {value}
    </MDTypography>
  </MDBox>
);
