import { useEffect, useRef, useState } from "react";

//3rd libraries
import { toast } from "react-toastify";

// @mui material components
import MDTypography from "../../../components/MDTypography";
import Table from "../../../components/Tables/Table";

// Data
import { getAllUser, getUserById } from "../../../services/userService";
import { LoadingSpinner } from "../../../components/LoadingSpinner";
import { DStatus, DUser, InstitutionAndCity } from "./style";
import { Stack } from "@mui/material";
import { convertToTimeUTC, convertToDate } from "../../utils/time";

const DashboardUserTable = () => {
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");

  useEffect(() => {
    fetchData();
  }, [page, limit]);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const { data, pagination_info } = await getAllUser({
        page,
        limit,
        status: "all",
      });

      setData(
        data.map((item) => ({
          id: item.id,
          email: item.email,
          is_approved: item.is_approved,
          un_approve_message: item.un_approve_message,
          created_at: item.created_at,
          updated_at: item.updated_at,
          name_surname: item.user_profile.name_surname,
          tckn: item.user_profile.tckn,
          gsm: item.user_profile.gsm,
          title: item.user_profile.title?.name ?? "",
          institution: item.user_profile.institution,
          city: item.user_profile.city?.name ?? "",
          branch: item.user_profile.branch?.name ?? "",
        }))
      );
      setMaxPage(pagination_info.total_page);
      setIsLoading(false);
    } catch (error) {
      toast.error("Error occured while fetching data");
    } finally {
      setIsLoading(false);
    }
  };

  const excelColumns = [
    "ID",
    "Title",
    "Name Surname",
    "Email",
    "TCKN",
    "GSM",
    "Branch",
    "Institution",
    "City",
    "Is Approved",
    "Unapprove Message",
    "Created At",
    "Updated At",
  ];

  const excelData = data.map(item => {
    return [
      item.id,
      item.title,
      item.name_surname,
      item.email,
      item.tckn,
      item.gsm,
      item.branch,
      item.institution,
      item.city,
      item.is_approved,
      item.un_approve_message,
      convertToDate(item.created_at),
      convertToDate(item.updated_at),
    ];
  });


    const columns = [
    {
      Header: "Name Surname",
      accessor: "name",
      align: "left",
      Cell: ({ row }) => (
        <>
          <DUser
            name_surname={row.original.name_surname}
            email={row.original.email}
            title={row.original.title}
          />
        </>
      ),
    },
    {
      Header: "Organization",
      accessor: "email",
      align: "left",
      Cell: ({ row }) => (
        <>
          <InstitutionAndCity
            institution={
              row.original.institution?.length > 30
                ? row.original.institution.substring(0, 30) + "..."
                : row.original.institution
            }
            city={row.original.city}
          />
        </>
      ),
    },
    {
      Header: "Branch",
      accessor: "branch",
      align: "left",
      Cell: ({ row }) => (
        <MDTypography variant="button" color="text" fontWeight="medium">
          {row.original.branch?.length > 25
            ? row.original.branch.substring(0, 25) + "..."
            : row.original.branch}
        </MDTypography>
      ),
    },
    {
      Header: "Register Date",
      accessor: "created_ad",
      align: "left",
      Cell: ({ row }) => (
        <Stack flexDirection="column">
          <MDTypography variant="button" color="text" fontWeight="medium">
            {convertToDate(row.original.created_at)}
          </MDTypography>
          <MDTypography variant="caption">
            {convertToTimeUTC(row.original.created_at)}
          </MDTypography>
        </Stack>
      ),
    },
    {
      Header: "Status",
      accessor: "is_approved",
      align: "center",
      Cell: ({ row }) => (
        <DStatus
          status={
            row.original.is_approved === "approve"
              ? "Approved"
              : row.original.is_approved === "pending"
              ? "Pending"
              : row.original.is_approved === "reject"
              ? "Reject"
              : row.original.is_approved === "un-approve"
              ? "Un approved"
              : ""
          }
        />
      ),
    },
  ];

  if (isLoading) return <LoadingSpinner isLoading={isLoading} />;

  return (
    <Table
      title="Users"
      isLoading={isLoading}
      limit={limit}
      setLimit={setLimit}
      setPage={setPage}
      page={page}
      setIsLoading={setIsLoading}
      maxPage={maxPage}
      prevFunc={() => setPage(page - 1)}
      nextFunc={() => setPage(page + 1)}
      columns={columns}
      search={search}
      setSearch={setSearch}
      rows={data}
      excelButton={{
        activeExcel: true,
        excelFunc: () => console.log("excel"),
        excelColumns: excelColumns,
        exportData: excelData,
      }}
    />
  );
};

export default DashboardUserTable;
