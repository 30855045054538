import React, { useRef } from "react";
import { useEffect, useState } from "react";
// react-router components
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

// Material Dashboard 2 React components
import EditIcon from "@mui/icons-material/Edit";
import { Box, Divider, Stack, Tooltip } from "@mui/material";
import MDTypography from "../../../components/MDTypography";
import MDButton from "../../../components/MDButton";
import Modal from "../../../components/Modal";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

// Material Dashboard 2 React example components
import DashboardLayout from "../../../components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../components/Navbars/DashboardNavbar";
import EventCalender from "./components/EventCalender";
import EventSpeakerList from "./components/eventSpeaker";
import { TabPanel } from "./components/tabPanel";
import EventParticipant from "../event-participant";
import { AddEventSection } from "./sectionCrud/addSectionModal";
import { LoadingSpinner } from "../../../components/LoadingSpinner";

//services
import { eventActive, getEvent } from "../../../services/eventService";

/* Styled Components */
import {
  Container,
  Image,
  StyledTab,
  StyledTabs,
  Title,
} from "./components/styled";

/* utils */
import { convertToTimeUTC, convertToUtcLongDate } from "../../utils/time";
import { toast } from "react-toastify";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { ConfirmModal } from "../../../components/ConfirmModal";
import EventQuestion from "../question-answer";

export const EventDetails = () => {
  const [loading, setLoading] = useState(false);
  const [event, setEvent] = useState([]);
  const [changeStatus, setChangeStatus] = useState("");
  const [modal, setModal] = useState(false);

  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const tabs = searchParams.get("tabs");

  const [value, setValue] = React.useState(tabs ? Number(tabs) : 0);

  const addSectionRef = useRef(null);
  const confirmSectionRef = useRef(null);

  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  const handleConfirm = (id) => {
    confirmSectionRef?.current.open();
    setChangeStatus(id);
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const data = await Promise.all([getEvent({ id })]);
      setEvent(data[0]);
    } catch (error) {
      toast.error("data could not be fetched.");
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //TODO: if we want to check session times we can use this function. Event session time must be between event start and end time.

  // function checkSessionTimes(event) {
  //   const { start_date, end_date, event_speaker } = event;
  //
  //   for (let i = 0; i < event_speaker.length; i++) {
  //     const speaker = event_speaker[i];
  //
  //     if (speaker.start_date < start_date || speaker.end_date > end_date) {
  //       return false;
  //     }
  //     return true;
  //   }
  // }

  const handleActive = async () => {
    const payload = {
      id: changeStatus,
    };
    try {
      setLoading(true);
      // if (event?.is_active === false) {
      //   if (checkSessionTimes(event) === false) {
      //     toast.error("Session time is not valid");
      //     setLoading(false);
      //     return false;
      //   }
      // }
      await eventActive(payload);
      fetchData();
      toast.success("Event status changed successfully.");
    } catch (error) {
      toast.error("Event status change failed.");
    }
    setLoading(false);
  };

  if (loading) {
    return <LoadingSpinner isLoading={loading} />;
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Modal
        ref={addSectionRef}
        closed={modal}
        children={
          <AddEventSection
            event={event}
            closeModal={() => setModal((prev) => !prev)}
            setData={setEvent}
          />
        }
        customStyle={{
          width: { xs: "80%", md: "35%" },
          maxHeight: "90vh",
          overflow: "auto",
        }}
      />
      <Modal
        ref={confirmSectionRef}
        children={
          <ConfirmModal
            closeModal={confirmSectionRef.current?.close}
            deleteFunc={() => handleActive()}
            title="Are you sure that you wish to change event status?"
            buttonTitle="Change"
          />
        }
      />
      {event && (
        <Container>
          <div>
            <Image src={event.image} alt={event.title} />
          </div>
          <Title>
            {event && event.product && (
              <Image float="none" src={event.product.logo} w={194} h={56} />
            )}
            <MDButton
              sx={{ marginRight: 1, float: "right" }}
              variant="contained"
              disabled={loading}
              color={event.is_active === true ? "error" : "success"}
              onClick={() => handleConfirm(event.id)}
            >
              {event.is_active === true ? "Unpublish" : "publish"}
            </MDButton>
            <MDButton
              sx={{ marginRight: 1, float: "right" }}
              color="warning"
              onClick={() =>
                navigate({
                  pathname: `/event-table/update`,
                  search: createSearchParams({
                    id: id,
                  }).toString(),
                })
              }
            >
              <EditIcon />
            </MDButton>
            <MDTypography fontSize={20} fontWeight="bold" color="possibleBlue">
              {event && event.title && event.title.length > 250
                ? event.title.substring(0, 250) + "..."
                : event.title}
            </MDTypography>
            <MDTypography
              mt={1}
              fontSize={16}
              fontWeight="bold"
              variant="subtitle1"
            >
              {`${convertToUtcLongDate(event.start_date)}- ${convertToTimeUTC(
                event.end_date
              )}`}
            </MDTypography>
            <MDTypography
              mt={1}
              variant="subtitle1"
              fontSize={16}
              fontWeight="light"
              component="p"
            >
              {event.description}
            </MDTypography>
            <MDTypography
              mt={2}
              fontSize={15}
              fontWeight="bold"
              color="possibleBlue"
            >
              Event type
            </MDTypography>
            <Stack
              sx={{
                my: 1,
                backgroundColor: "rgba(248, 248, 248, 1)",
                borderRadius: 2,
                padding: 1,
              }}
            >
              <MDTypography
                variant="subtitle1"
                fontSize={16}
                fontWeight="light"
              >
                {event.event_type?.name}
                {event.event_type?.name === "Sanal" && (
                  <a
                    style={{ marginLeft: "auto" }}
                    href={event.url_info?.url}
                    target="_blank"
                  >
                    <OpenInNewIcon />
                  </a>
                )}
              </MDTypography>
            </Stack>

            <MDTypography
              mr={1}
              fontSize={15}
              fontWeight="bold"
              color="possibleBlue"
            >
              Categories
            </MDTypography>
            <Stack
              sx={{
                my: 1,
                backgroundColor: "rgba(248, 248, 248, 1)",
                borderRadius: 2,
                padding: 1,
              }}
            >
              <MDTypography
                variant="subtitle1"
                fontSize={16}
                fontWeight="light"
              >
                {event?.category?.name}
              </MDTypography>
            </Stack>
            <MDTypography
              mr={1}
              fontSize={15}
              fontWeight="bold"
              color="possibleBlue"
            >
              Cities
            </MDTypography>
            <Stack
              sx={{
                my: 1,
                backgroundColor: "rgba(248, 248, 248, 1)",
                borderRadius: 2,
                padding: 1,
              }}
            >
              <MDTypography
                variant="subtitle1"
                fontSize={16}
                fontWeight="light"
              >
                {event.city?.length > 80
                  ? "All Cities"
                  : event.city?.map((city) => city.name).join(", ")}
              </MDTypography>
            </Stack>
            <Box sx={{ width: "100%" }}>
              <Box>
                <StyledTabs
                  value={value}
                  onChange={handleChange}
                  aria-label="styled tabs example"
                >
                  <StyledTab label="Event calender" />
                  <StyledTab label="Speakers" />
                  <StyledTab label="Participants" />
                  <StyledTab label="Questions" />
                </StyledTabs>
                <Divider sx={{ margin: 0 }} />
                <TabPanel value={value} index={0}>
                  <div>
                    <Tooltip title="Add session">
                      <MDButton
                        sx={{
                          cursor: "pointer",
                          fontWeight: "bold",
                          marginTop: 2,
                        }}
                        color="success"
                        onClick={() => setModal(true)}
                      >
                        <AddCircleOutlineIcon />
                      </MDButton>
                    </Tooltip>
                    <EventCalender
                      event_speaker={event?.event_speaker}
                      setData={setEvent}
                      setLoading={setLoading}
                      data={event}
                    />
                  </div>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <Tooltip title="Add session">
                    <MDButton
                      sx={{
                        cursor: "pointer",
                        fontWeight: "bold",
                        marginTop: 2,
                        marginLeft: "auto",
                      }}
                      color="success"
                      onClick={() => setModal(true)}
                    >
                      <AddCircleOutlineIcon />
                    </MDButton>
                  </Tooltip>
                  <EventSpeakerList
                    event_speaker={event?.event_speaker}
                    setData={setEvent}
                    setLoading={setLoading}
                    data={event}
                  />
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <EventParticipant />
                </TabPanel>
                <TabPanel value={value} index={3}>
                  <EventQuestion />
                </TabPanel>
                <Box />
              </Box>
            </Box>
          </Title>
        </Container>
      )}
    </DashboardLayout>
  );
};
