import {
  Autocomplete,
  Card,
  CircularProgress,
  IconButton,
  Pagination,
  Stack,
  Tooltip,
} from "@mui/material";
import React from "react";
import MDBox from "../MDBox";
import MDInput from "../MDInput";
import MDTypography from "../MDTypography";
import DataTable from "./DataTable";
import MDButton from "../MDButton";
import image from "../../assets/images/icons/xlsx.png";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import * as XLSX from "xlsx";
import Select from "react-select";
import { customStyles } from "./style";

function Table({
  title = "Projects",
  limit = 5,
  setLimit,
  setPage,
  options,
  setFilter,
  page = 1,
  maxPage = 1,
  customButton = {
    active: false,
    name: "Add",
    func: () => {},
  },
  excelButton = {
    activeExcel: false,
    excelFunc: () => {},
    excelColumns: [],
  },
  columns,
  rows,
  filter,
  isFilter,
  isLoading = false,
}) {
  const { active, name, func } = customButton;
  const { activeExcel, excelFunc, excelColumns, exportData } = excelButton;

  const entries = ["5", "10", "15", "20", "25"];


  const handleChange = (event, value) => {
    setPage(value);
  };

  function s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) {
      view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
  }

  function handleDownloadExcel() {
    const workbook = XLSX.utils.book_new();
    const sheet = XLSX.utils.aoa_to_sheet([excelColumns, ...exportData]);
    XLSX.utils.book_append_sheet(workbook, sheet, 'Sheet1');
    const wBout = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });
    const blob = new Blob([s2ab(wBout)], { type: 'application/octet-stream' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'data.xlsx';
    a.click();
    window.URL.revokeObjectURL(url);
  }

  const handleChangeFilter = (e) => {
    setFilter(e.value);
  };

  return (
    <Card>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={3}
        pb={1}
      >
        <MDBox
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <MDTypography mr={3} variant="h6" gutterBottom>
            {title}
          </MDTypography>
          <MDBox sx={{ width: 120 }}>
            {isFilter && (
              <Select
                fullWidth
                options={options}
                styles={customStyles}
                placeholder="Filter"
                value={options.find((option) => option.value === filter)}
                onChange={handleChangeFilter}
              />
            )}
          </MDBox>
        </MDBox>
        <MDBox color="text">
          {rows.length > 0 && activeExcel === true && (
            <Tooltip title="Export">
              <IconButton onClick={handleDownloadExcel}>
                <img src={image} width={40} height={40} alt="image" />
              </IconButton>
            </Tooltip>
          )}
          {active === true && (
            <Tooltip title={name}>
              <MDButton
                size="small"
                sx={{ maxWidth: 50, minWidth: 50, width: 50 }}
                color="success"
                onClick={func}
              >
                <AddCircleOutlineIcon />
              </MDButton>
            </Tooltip>
          )}
        </MDBox>
      </MDBox>

      <MDBox>
        {rows.length ? (
          <DataTable
            table={{ columns, rows }}
            showTotalEntries={false}
            isSorted={false}
            noEndBorder
            entriesPerPage={{ defaultValue: limit }}
          />
        ) : (
          <Stack
            sx={{
              padding: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {isLoading ? (
              <CircularProgress color="info" />
            ) : (
              <MDTypography fontSize={20} color="info">
                Data not found
              </MDTypography>
            )}
          </Stack>
        )}

        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p={3}
          p3={1}
        >
          <MDBox display="flex" alignItems="center">
            <Autocomplete
              disableClearable
              value={limit.toString()}
              options={entries}
              onChange={(event, newValue) => {
                setPage(1);
                setLimit(parseInt(newValue, 10));
              }}
              size="small"
              sx={{ width: "4rem" }}
              renderInput={(params) => <MDInput {...params} />}
            />
            <MDTypography variant="caption" color="secondary">
              &nbsp;&nbsp;entries per page
            </MDTypography>
          </MDBox>
          <MDBox pb={2}>
            <Pagination
              size="small"
              onChange={handleChange}
              page={page}
              count={maxPage}
              showFirstButton
              showLastButton
              shape="rounded"
              color="info"
              siblingCount={0}
              boundaryCount={1}
            />
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default Table;
