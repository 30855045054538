import styled from "@emotion/styled";
import Icon from "@mui/material/Icon";

export const PreviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  width: ${props => `calc(${props.w || '220px'} + 30px)`};
  height: ${props => `calc(${props.h || '220px'})`};
`;

export const FileContent = styled.div`
  height: ${props => props.h || '220px'};
  width: ${props => `calc(${props.w || '220px'})`};
  position: relative;
  border: 1px solid #21212150;
  border-radius: ${props => props.br || "20px"};
  display: grid;
  /* grid-template-columns: repeat(3, 33.3% [col-start]); */
  grid-template-columns: 30% 50% calc(20% - 20px);
  overflow: hidden;
  column-gap: 10px;
`;

export const VideoSrc = styled.video`
  /* width: 40%; */
  width: 100%;
  height: 100%;
  max-height: 200px;
  object-fit: cover;
`;

export const ImageSrc = styled.img`
  height: ${props => props.h || '220px'};
  width: ${props => props.w || '220px'};
  object-fit: cover;
  pointer-events: none;
  user-select: none;
`;

export const FileInputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;
`;

export const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  justify-self: flex-end;
  padding: 10px 0;
  overflow: hidden;
`;

export const CloseIcon = styled(Icon)`
  margin-left: 2px;
  cursor: pointer;
  &:hover {
    color: #c70000;
  }
`;

export const StatusText = styled.p`
  font-size: 15px;
  overflow: hidden;
  line-break: normal;
  margin-right: 10px;
  color: ${({ $color }) => $color};
`;
