import { categoryInstance } from "./http";
import errorHandler from "./errorHandler";

export const getCategories = async ({page, limit, lang}) => {
  try {
    const { data } = await categoryInstance(lang).get(`/?pagination=1&page=${page}&limit=${limit}&order_desc=1`);
    return data;
  } catch (error) {
    const err = errorHandler(error);
    throw err;
  }
};

export const getCategory = async ({ id, lang, isCrud }) => {
  const payload = {
    isCrud,
  };
  try {
    const { data } = await categoryInstance(lang).get(`/${id}`, payload);
    return data;
  } catch (error) {
    const err = errorHandler(error);
    throw err;
  }
};
export const createCategory = async ({ name, lang }) => {
  const payload = {
    name,
  };
  try {
    const { data } = await categoryInstance(lang).post(`/`, payload);
    return data;
  } catch (error) {
    const err = errorHandler(error);
    throw err;
  }
};

export const updateCategory = async ({ id, name, lang }) => {
  const payload = {
    name,
  };
  try {
    const { data } = await categoryInstance(lang).put(`/${id}`, payload);
    return data;
  } catch (error) {
    const err = errorHandler(error);
    throw err;
  }
};

export const deleteCategory = async ({ id, lang }) => {
  try {
    const { data } = await categoryInstance(lang).delete(`/${id}`);
    return data;
  } catch (error) {
    const err = errorHandler(error);
    throw err;
  }
};
