import { useEffect, useMemo, useRef, useState } from "react";

// 3rd libraries
import { toast } from "react-toastify";

// @mui material components
import { Stack, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

// Material Dashboard 2 React components
import Modal from "../../components/Modal";
import DashboardLayout from "../../components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../components/Navbars/DashboardNavbar";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import MDButton from "../../components/MDButton";
import Table from "../../components/Tables/Table";

// components
import { AddProduct } from "./components/AddProduct";
import { UpdateProduct } from "./components/updateProduct";

// services
import { deleteProduct, getProducts } from "../../services/productService";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { ConfirmModal } from "../../components/ConfirmModal";

export const Products = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [maxPage, setMaxPage] = useState();
  const [data, setData] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [search, setSearch] = useState("");
  const [fileList, setFileList] = useState([]);
  const [modal, setModal] = useState(false);
  const [idX, setIdX] = useState("");

  const addProductRef = useRef(null);
  const updateProductRef = useRef(null);
  const deleteSectionRef = useRef(null);

  useEffect(() => {
    fetchData();
  }, [page, limit]);

  const handleConfirm = (id) => {
    deleteSectionRef?.current.open();
    setIdX(id);
  };
  const fetchData = async () => {
    try {
      setIsLoading(true);
      const { data, pagination_info } = await getProducts({ page, limit });
      setData(data);
      setFileList([{ preview: data.logo }]);
      setMaxPage(pagination_info.total_page);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const removeProduct = async () => {
    const payload = { id: idX };
    try {
      setIsLoading(true);
      await deleteProduct(payload);
      fetchData();
      toast.success("Product deleted successfully");
    } catch (error) {
      if (error === "Bu ürünün altında bir etkinlik olduğu için silemezsiniz") {
        toast.error("Something went wrong, this product has an event");
      } else {
        toast.error("Something went wrong");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const updateProduct = (e) => {
    setSelectedProduct(e);
    updateProductRef.current?.open();
  };

  const columns = useMemo(
    () => [
      {
        Header: "Logo",
        accessor: "image",
        align: "left",
        width: "100px",
        Cell: ({ row }) => (
          <>
            <MDBox display="flex" alignItems="center" lineHeight={1}>
              <img
                src={row.original.logo}
                alt={row.original.name}
                style={{ width: 100, borderRadius: 8 }}
              />
            </MDBox>
          </>
        ),
      },
      {
        Header: "Name",
        accessor: "name",
        align: "left",
        width: "100%",
        Cell: ({ row }) => (
          <MDBox lineHeight={1}>
            <MDTypography display="block" variant="button" fontWeight="medium">
              {row.original.name}
            </MDTypography>
          </MDBox>
        ),
      },
      {
        Header: "Color",
        accessor: "color",
        align: "center",
        width: "50px",
        Cell: ({ row }) => (
          <Stack flexDirection="row">
            <MDBox
              display="flex"
              alignItems="center"
              lineHeight={1}
              sx={{
                background: row.original.color,
                width: "80px",
                height: "30px",
                borderRadius: 2,
              }}
            ></MDBox>
          </Stack>
        ),
      },
      {
        Header: "Actions",
        align: "center",
        width: "70px",
        Cell: ({ row }) => (
          <>
            <Tooltip title="Update">
              <MDButton
                size="small"
                sx={{ marginRight: 1, maxWidth: 30, minWidth: 30, width: 30 }}
                color="warning"
                onClick={() => updateProduct(row.original)}
              >
                <EditIcon />
              </MDButton>
            </Tooltip>
            <Tooltip title="Delete">
              <MDButton
                size="small"
                sx={{ maxWidth: 30, minWidth: 30, width: 30 }}
                ml={1}
                color="error"
                onClick={() => handleConfirm(row.original.id)}
              >
                <DeleteIcon />
              </MDButton>
            </Tooltip>
          </>
        ),
      },
    ],
    []
  );

  if (isLoading) return <LoadingSpinner isLoading={isLoading} />;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Modal
        ref={addProductRef}
        closed={modal}
        children={
          <AddProduct
            closeModal={() => setModal((prev) => !prev)}
            setData={setData}
          />
        }
        customStyle={{
          width: { xs: "90%", md: "60%" },
          maxHeight: "90vh",
          overflow: "auto",
        }}
      />

      <Modal
        ref={updateProductRef}
        children={
          <UpdateProduct
            closeModal={updateProductRef.current?.close}
            setData={setData}
            data={selectedProduct}
            setFileList={setFileList}
            fileList={fileList}
            setMaxPage={setMaxPage}
            limit={limit}
            page={page}
          />
        }
        customStyle={{
          width: { xs: "90%", md: "60%" },
          maxHeight: "90vh",
          overflow: "auto",
        }}
      />

      <Modal
        ref={deleteSectionRef}
        children={
          <ConfirmModal
            closeModal={deleteSectionRef.current?.close}
            deleteFunc={() => removeProduct()}
            title="Are you sure that you wish to delete this product?"
          />
        }
      />

      <MDBox>
        <Table
          title="Products"
          isLoading={isLoading}
          limit={limit}
          setLimit={setLimit}
          setPage={setPage}
          page={page}
          setIsLoading={setIsLoading}
          maxPage={maxPage}
          prevFunc={() => setPage(page - 1)}
          nextFunc={() => setPage(page + 1)}
          columns={columns}
          search={search}
          setSearch={setSearch}
          rows={data}
          customButton={{
            active: true,
            name: "Add Product",
            func: () => setModal(true),
          }}
        />
      </MDBox>
    </DashboardLayout>
  );
};
