import { createContext, useEffect, useReducer, useState } from "react";
import { authLogin } from "../../services/authService";
import { getLocalStorage, setLocalStorage } from "../../helpers";

export const AuthContext = createContext();

const initialState = {
  user: localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null,
  tokens: getLocalStorage('tokens'),
  loading: false,
  error: null,
  isAuth: false,
  device_uuid: null,
};

const type = {
  SET_TOKENS: 'SET_TOKENS',
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILED: "LOGIN_FAILED",
  LOADING_TRUE: "LOADING_TRUE",
  LOGOUT: "LOGOUT",
};

const reducer = (state, action) => {
  switch (action.type) {
    case type.SET_TOKENS:
      return {
        ...state,
        tokens: action.payload.tokens
      };
    case type.LOGIN_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.token,
        loading: false,
        isAuth: true,
      };
    case type.LOGIN_FAILED:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
        isAuth: false,
      };
    case type.LOADING_TRUE:
      return {
        ...state,
        loading: true,
      };
      case type.LOADING_TRUE:
        return {
          ...state,
          loading: false,
        };
    case type.LOGOUT:
      return {
        ...state,
        user: null,
        token: null,
        isAuth: false,
        loading: false,
        device_uuid: null,
      };
    default:
      throw new Error();
  }
};

export const AuthProvider = ({ children }) => {
  const [authState, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const tokens = getLocalStorage('tokens');
    if (tokens) {
      dispatch({
        type: type.SET_TOKENS,
        payload: { tokens }
      });
    }
  }, [])

  const login = async (email, password) => {
    dispatch({
      type: "LOADING_TRUE",
    });
    const data = await authLogin(email, password);
    if (data.error) {
      dispatch({
        type: type.LOGIN_FAILED,
        payload: {
          error: data.message,
        },
      });
      dispatch({
        type: "LOADING_FALSE",
      });
      return false;
    } else {
      setLocalStorage('tokens', { access_token: data.access_token, refresh_token: data.refresh_token });
      localStorage.setItem("user", JSON.stringify(data.user));
      dispatch({
        type: type.LOGIN_SUCCESS,
        payload: {
          user: data.user,
          tokens: { access_token: data.access_token, refresh_token: data.refresh_token },
        },
      });
      return true;
    }
  };

  const logout = () => {
    dispatch({
      type: type.LOGOUT,
    });
    localStorage.removeItem("user");
    localStorage.removeItem("tokens");
    localStorage.removeItem("device_uuid");
    window.location.href = "/authentication/sign-in";
    return true;
  };

  return (
    <AuthContext.Provider value={{ authState, dispatch, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
