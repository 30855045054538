import { Chip, Divider, Grid } from "@mui/material";
import MDTypography from "../../../../components/MDTypography";
import Select from "react-select";
import MDButton from "../../../../components/MDButton";
import { styled } from "@mui/system";
import { styles } from "./style";
import { useFormik } from "formik";
import MDInput from "../../../../components/MDInput";
import { validationSchema } from "./validationSchema";
import MDBox from "../../../../components/MDBox";
import { approveUser, getAllUser, rejectUser, unApproveUser } from "../../../../services/userService";
import { toast } from "react-toastify";

const Text = styled("h4")({
  color: "#203662",
});

export const EditUserModal = ({ selectedUser, closeModal, setData, loading, setLoading, setMaxPage, page, limit }) => {
  const formik = useFormik({
    initialValues: {
      name: "",
      status: [],
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const payload = {
        message: values.name,
      };
      try {
        setLoading(true);
        if (values.status.value === "approve") {
          await approveUser({ id: selectedUser.id });
        } else if (values.status.value === "reject") {
          await rejectUser({ id: selectedUser.id });
        } else if (values.status.value === "un-approve") {
          await unApproveUser({ id: selectedUser.id, message: payload.message });
        }
        const { data, pagination_info } = await getAllUser({ page, limit, status: "all" });
        setData(
          data.map((item) => ({
            id: item.id,
            email: item.email,
            name: item.user_profile.name,
            surname: item.user_profile.surname,
            name_surname: item.name_surname,
            created_at: item.created_at,
            title: item.user_profile.title,
            institution: item.user_profile.institution,
            city: item.user_profile.city,
            branch: item.user_profile.branch,
            is_approved: item.is_approved,
          }))
        );
        setMaxPage(pagination_info.total_page);
        toast.success("User update successfully");
        closeModal?.();
      } catch (error) {
        toast.error(`Something went wrong. ${error}`);
      } finally {
        setLoading(false);
      }
    },
  });

  let options = [];

  switch (selectedUser.is_approved) {
    case "approve":
      options = [
        { value: "reject", label: "Reject" },
        { value: "un-approve", label: "Un approve" },
      ];
      break;
    case "pending":
      options = [
        { value: "approve", label: "Approve" },
        { value: "reject", label: "Reject" },
      ];
      break;
    case "un-approve":
      options = [
        { value: "approve", label: "Approve" },
        { value: "reject", label: "Reject" },
      ];
      break;
    default:
      options = [];
      break;
  }

  if (selectedUser.is_approved === "un-approve") {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Text>Un approve message</Text>
          <MDBox sx={{ my: 3, backgroundColor: "rgba(248, 248, 248, 1)", borderRadius: 2, padding: 3 }}>
            <MDTypography color="inherit" variant="subheading" fontSize={15}>
              {selectedUser.un_approve_message}
            </MDTypography>
          </MDBox>
          <MDButton disabled={loading} variant="outlined" color="error" fullWidth onClick={closeModal}>
            Cancel
          </MDButton>
        </Grid>
      </Grid>
    );
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Text>Update user status</Text>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <MDBox
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <MDTypography mr={1} variant="caption" color="dark" fontWeight="medium" fontSize={15}>
              Status:
            </MDTypography>
            <Chip
              color={selectedUser.is_approved === "approve" ? "success" : selectedUser.is_approved === "pending" ? "warning" : "error"}
              label={selectedUser.is_approved}
            />
          </MDBox>
        </Grid>
        <Grid item xs={12}>
          <Select
            fullWidth
            placeholder="Status"
            styles={styles}
            menuPlacement="top"
            noOptionsMessage={({ inputValue }) => `${inputValue || "Status"} not found`}
            value={formik.values.status}
            onChange={(value) => formik.setFieldValue("status", value)}
            options={options}
            error={formik.touched.status && Boolean(formik.errors.status)}
          />
          {formik.touched.status && formik.errors.status && (
            <div
              style={{
                color: "#DF2B1C",
                marginTop: ".1rem",
                fontSize: 12,
                marginLeft: 15,
                fontWeight: "lighter",
              }}
            >
              {formik.errors.status}
            </div>
          )}
        </Grid>
        {formik.values.status.value === "un-approve" && (
          <Grid item xs={12}>
            <MDInput
              fullWidth
              label="Message"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </Grid>
        )}
        <Grid item container xs={12} spacing={2}>
          <Grid item xs={7} md={8}>
            <MDButton disabled={loading} variant="contained" color="info" fullWidth type="submit">
              Save
            </MDButton>
          </Grid>
          <Grid item xs={5} md={4}>
            <MDButton disabled={loading} variant="outlined" color="error" fullWidth onClick={closeModal}>
              Cancel
            </MDButton>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};
