import React from "react";

// 3rd libraries
import Select from "react-select";
import { useFormik } from "formik";
import { toast } from "react-toastify";

// @mui
import { Divider, Grid } from "@mui/material";
import MDButton from "../../../../components/MDButton";
import { styled } from "@mui/system";

//styles
import { styles } from "./style";

// validation
import { validationSchema } from "./validationSchema";

//services
import { approveParticipant, getEventParticipant, rejectParticipant } from "../../../../services/appService";
import {useSearchParams} from "react-router-dom";

const Text = styled("h4")({
  color: "#203662",
});

export const EditUserModal = ({
  selectedUser,
  closeModal,
  setData,
  loading,
  setLoading,
  setMaxPage,
  page,
  limit,
  filter,
}) => {

  const [searchParams] = useSearchParams();
  const event = searchParams.get("id");

  const formik = useFormik({
    initialValues: {
      status: [],
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        if (values.status.value === "approve") {
          await approveParticipant({ id: selectedUser.id });
        } else {
          await rejectParticipant({ id: selectedUser.id });
        }
        const { data, pagination_info } = await getEventParticipant({ page, limit, filter, event });
        setData(data);
        setMaxPage(pagination_info.total_page);
        toast.success("Participant status updated successfully.");
        closeModal?.();
      } catch (error) {
        toast.error(`Something went wrong. ${error.message}`);
      } finally {
        setLoading(false);
      }
    },
  });

  let options = [];

  switch (selectedUser.is_approved) {
    case "approve":
      options = [
        { value: "reject", label: "Reject" },
      ];
      break;
    case "pending":
      options = [
        { value: "approve", label: "Approve" },
        { value: "reject", label: "Reject" },
      ];
      break;
    case "reject":
      options = [
        { value: "approve", label: "Approve" },
      ];
      break;
    default:
      options = [];
      break;
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Text>Event Participant Management</Text>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Select
            fullWidth
            placeholder="Status"
            styles={styles}
            menuPlacement="top"
            noOptionsMessage={({ inputValue }) => `${inputValue || "Status"} not found`}
            value={formik.values.status}
            onChange={(value) => formik.setFieldValue("status", value)}
            options={options}
            error={formik.touched.status && Boolean(formik.errors.status)}
          />
          {formik.touched.status && formik.errors.status && (
            <div
              style={{
                color: "#DF2B1C",
                marginTop: ".1rem",
                fontSize: 12,
                marginLeft: 15,
                fontWeight: "lighter",
              }}
            >
              {formik.errors.status}
            </div>
          )}
        </Grid>
        <Grid item container xs={12} spacing={2}>
          <Grid item xs={7} md={8}>
            <MDButton disabled={loading} variant="contained" color="info" fullWidth type="submit">
              Save
            </MDButton>
          </Grid>
          <Grid item xs={5} md={4}>
            <MDButton disabled={loading} variant="outlined" color="error" fullWidth onClick={closeModal}>
              Cancel
            </MDButton>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};
