import axios from "axios";
import { getLocalStorage } from "../helpers/index";
export const BASE_API = process.env.REACT_APP_NODE_API_BASE_URL;

const defaultLang = "tr";

export const withToken = async (config) => {
  const tokens = getLocalStorage("tokens");
  if (tokens?.access_token) {
    config.headers.Authorization = `Bearer ${tokens.access_token}`;
  }
  return config;
};

// appInstance is used in src/services/appService.js
const _appInstance = axios.create({
  baseURL: `${BASE_API}/api/v1/${defaultLang}/`,
});

export const appInstance = (lang = defaultLang) => {
  _appInstance.defaults.baseURL = `${BASE_API}/api/v1/${lang}/`;
  _appInstance.interceptors.request.use(withToken);
  return _appInstance;
};

// authInstance is used in src/services/authService.js
const _authInstance = axios.create({
  baseURL: `${BASE_API}/api/v1/${defaultLang}/auth`,
});

export const authInstance = (lang = defaultLang) => {
  _authInstance.defaults.baseURL = `${BASE_API}/api/v1/${lang}/auth`;
  _authInstance.interceptors.request.use(withToken);
  return _authInstance;
};

// userInstance is used in src/services/userService.js
const _userInstance = axios.create({
  baseURL: `${BASE_API}/api/v1/${defaultLang}/user`,
});

export const userInstance = (lang = defaultLang) => {
  _userInstance.defaults.baseURL = `${BASE_API}/api/v1/${lang}/user`;
  _userInstance.interceptors.request.use(withToken);
  return _userInstance;
};

// speakerInstance is used in src/services/speakersService.js
const _speakerInstance = axios.create({
  baseURL: `${BASE_API}/api/v1/${defaultLang}/speaker`,
});

export const speakerInstance = (lang = defaultLang) => {
  _speakerInstance.defaults.baseURL = `${BASE_API}/api/v1/${lang}/speaker`;
  _speakerInstance.interceptors.request.use(withToken);
  return _speakerInstance;
};

// productInstance is used in src/services/productsService.js
const _productInstance = axios.create({
  baseURL: `${BASE_API}/api/v1/${defaultLang}/product`,
});

export const productInstance = (lang = defaultLang) => {
  _productInstance.defaults.baseURL = `${BASE_API}/api/v1/${lang}/product`;
  _productInstance.interceptors.request.use(withToken);
  return _productInstance;
};

// categoryInstance is used in src/services/categoryService.js
const _categoryInstance = axios.create({
  baseURL: `${BASE_API}/api/v1/${defaultLang}/category`,
});

export const categoryInstance = () => {
  _categoryInstance.defaults.baseURL = `${BASE_API}/api/v1/tr/category`;
  _categoryInstance.interceptors.request.use(withToken);
  return _categoryInstance;
};

// titleInstance is used in src/services/titleService.js
const _titleInstance = axios.create({
  baseURL: `${BASE_API}/api/v1/${defaultLang}/title`,
});

export const titleInstance = (lang = defaultLang) => {
  _titleInstance.defaults.baseURL = `${BASE_API}/api/v1/${lang}/title`;
  _titleInstance.interceptors.request.use(withToken);
  return _titleInstance;
};

// branchInstance is used in src/services/branchService.js
export const _branchInstance = axios.create({
  baseURL: `${BASE_API}/api/v1/${defaultLang}/branch`,
});

export const branchInstance = (lang = defaultLang) => {
  _branchInstance.defaults.baseURL = `${BASE_API}/api/v1/${lang}/branch`;
  _branchInstance.interceptors.request.use(withToken);
  return _branchInstance;
};

// bannerInstance is used in src/services/bannerService.js
const _bannerInstance = axios.create({
  baseURL: `${BASE_API}/api/v1/${defaultLang}/banner`,
});

export const bannerInstance = (lang = defaultLang) => {
  _bannerInstance.defaults.baseURL = `${BASE_API}/api/v1/${lang}/banner`;
  _bannerInstance.interceptors.request.use(withToken);
  return _bannerInstance;
};

// contentInstance is used in src/services/contentService.js
const _contentInstance = axios.create({
  baseURL: `${BASE_API}/api/v1/${defaultLang}/content`,
});

export const contentInstance = (lang = defaultLang) => {
  _contentInstance.defaults.baseURL = `${BASE_API}/api/v1/${lang}/content`;
  _contentInstance.interceptors.request.use(withToken);
  return _contentInstance;
}

// eventInstance is used in src/services/eventService.js
const _eventInstance = axios.create({
  baseURL: `${BASE_API}/api/v1/${defaultLang}/event`,
});

export const eventInstance = (lang = defaultLang) => {
  _eventInstance.defaults.baseURL = `${BASE_API}/api/v1/${lang}/event`;
  _eventInstance.interceptors.request.use(withToken);
  return _eventInstance;
};
