import dayjs from "dayjs";
import * as yup from "yup";

export const validationSchema = yup.object({
  title: yup.string().required("Title is required"),
  description: yup.string().required("Description is required"),
  start_date: yup
    .date()
    .min(dayjs().subtract(0, "hour"), "Start date cannot be in the past")
    .required(),
  end_date: yup
    .date()
    .min(yup.ref("start_date"), "End date must be after start date")
    .required(),
  url_type: yup.object().when("event_types.label", {
    is: "Sanal",
    then: yup
      .object()
      .shape({
        label: yup.string().required("Url type is required"),
        value: yup.string().required("Url type is required"),
        slug: yup.string().required("Url type is required"),
      })
      .required("Url type is required"),
  }),
  url: yup.string().when("url_type.slug", {
    is: ["vimeo-embed", "youtube-embed", "rtmps"],
    then: yup.string().required("Url is required"),
    otherwise: yup.string().nullable(),
  }),
  api_key: yup.string().when("url_type.slug", {
    is: "zoom-sdk",
    then: yup.string().required("Api key is required"),
  }),
  api_secret: yup.string().when("url_type.slug", {
    is: "zoom-sdk",
    then: yup.string().required("Api secret key is required"),
  }),
  meeting_number: yup.string().when("url_type.slug", {
    is: "zoom-sdk",
    then: yup.string().required("Meeting number is required"),
  }),
  meeting_password: yup.string().when("url_type.slug", {
    is: "zoom-sdk",
    then: yup.string().required("Meeting Password is required"),
  }),
  product: yup
    .array()
    .nullable()
    .min(1, "Product is required")
    .of(
      yup.object().shape({
        label: yup.string().required(),
        value: yup.string().required(),
      })
    ),
  category: yup
    .array()
    .nullable()
    .min(1, "Category is required")
    .of(
      yup.object().shape({
        label: yup.string().required(),
        value: yup.string().required(),
      })
    ),
  banner_type: yup
    .array()
    .nullable()
    .min(1, "Banner type is required")
    .of(
      yup.object().shape({
        label: yup.string().required(),
        value: yup.string().required(),
        imageCount: yup.number().required(),
      })
    ),
  banner: yup
    .array()
    .nullable()
    .min(1, "Banner is required")
    .of(
      yup.object().shape({
        label: yup.string().required(),
        value: yup.string().required(),
      })
    ),
  city: yup
    .array()
    .nullable()
    .min(1, "City is required")
    .of(
      yup.object().shape({
        label: yup.string().required(),
        value: yup.string().required(),
      })
    ),
  branch: yup
    .array()
    .nullable()
    .min(1, "Branch is required")
    .of(
      yup.object().shape({
        label: yup.string().required(),
        value: yup.string().required(),
      })
    ),
  event_types: yup
    .array()
    .nullable()
    .min(1, "Event types is required")
    .of(
      yup.object().shape({
        label: yup.string().required(),
        value: yup.string().required(),
      })
    ),
});
