import { Box, styled } from "@mui/material";

const Container = styled(Box)({
  position: "relative",
  width: 50,
  height: 35,
  borderRadius: 2,
});

const SmallImage = styled(Box)(({ src }) => ({
  backgroundImage: `url(${src})`,
  backgroundSize: "cover",
  width: "100%",
  height: "100%",
  position: "relative",
  zIndex: 1,
  borderRadius: 2,
}));

const LargeImage = styled(Box)(({ src }) => ({
  backgroundImage: `url(${src})`,
  backgroundSize: "cover",
  width: 500,
  height: 500,
  position: "absolute",
  top: 0,
  left: "100%",
  zIndex: 2,
  opacity: 0,
  transition: "opacity 0.3s ease-in-out",
}));

const HoverArea = styled(Box)({
  position: "absolute",
  top: 0,
  left: 0,
  width: 500,
  height: 500,
  zIndex: 1,
  "&:hover": {
    "& ~ div": {
      opacity: 1,
      left: "100%",
    },
  },
});

export const ImageContainer = ({ url, alt }) => {
  return (
    <Container>
      <SmallImage src={url} alt={alt}/>
    </Container>
  );
};
