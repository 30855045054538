import { useEffect, useRef, useState } from "react";

// 3rd libraries
import { toast } from "react-toastify";

// @mui material components
import { Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

// Material Dashboard 2 React components
import Modal from "../../components/Modal";
import DashboardLayout from "../../components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../components/Navbars/DashboardNavbar";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import MDButton from "../../components/MDButton";
import Table from "../../components/Tables/Table";

// components
import { AddBanner } from "./components/AddBanner";
import { UpdateBanner } from "./components/updateBanner";

// services
import { deleteBanner, getBanners } from "../../services/bannerService";
import { ImageContainer } from "./components/styled";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { ConfirmModal } from "../../components/ConfirmModal";

export const Banners = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [maxPage, setMaxPage] = useState(1);
  const [data, setData] = useState([]);
  const [selectedBanner, setSelectedBanner] = useState([]);
  const [search, setSearch] = useState("");
  const [closeModal, setCloseModal] = useState(false);
  const [idX, setIdX] = useState("");

  const addBannerRef = useRef(null);
  const updateBannerRef = useRef(null);
  const deleteSectionRef = useRef(null);

  useEffect(() => {
    fetchData();
  }, []);

  const handleConfirm = (id) => {
    deleteSectionRef?.current.open();
    setIdX(id);
  };
  const fetchData = async () => {
    try {
      setIsLoading(true);
      const { data, pagination_info } = await getBanners({ page, limit });
      setData(data);
      setMaxPage(pagination_info.total_page);
      setIsLoading(false);
    } catch (error) {
      console.log("err", error);
      setIsLoading(false);
    }
  };

  const removeBanner = async () => {
    const payload = { id: idX };
    try {
      setIsLoading(true);
      await deleteBanner(payload);
      fetchData();
      toast.success("Banner deleted successfully");
    } catch (error) {
      console.log("err", error);
      if (error === "banner.not_delete") {
        toast.error("Something went wrong, banner has an active event");
      } else {
        toast.error("Something went wrong");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const updateBanner = (e) => {
    setSelectedBanner(e);
    updateBannerRef.current?.open();
  };

  const columns = [
    {
      Header: "Banner",
      accessor: "image",
      align: "left",
      width: "10%",
      Cell: ({ row }) => (
        <>
          <ImageContainer alt={row.original.name} url={row.original.image} />
        </>
      ),
    },
    {
      Header: "Name",
      accessor: "name",
      width: "80%",
      align: "left",
      Cell: ({ row }) => (
        <MDTypography display="block" variant="button" fontWeight="medium">
          {row.original.name}
        </MDTypography>
      ),
    },
    {
      Header: "Actions",
      align: "left",
      Cell: ({ row }) => (
        <>
          <Tooltip title="Update">
            <MDButton
              size="small"
              sx={{ marginRight: 1, maxWidth: 30, minWidth: 30, width: 30 }}
              color="warning"
              onClick={() => updateBanner(row.original)}
            >
              <EditIcon />
            </MDButton>
          </Tooltip>
          <Tooltip title="Delete">
            <MDButton
              size="small"
              sx={{ maxWidth: 30, minWidth: 30, width: 30 }}
              color="error"
              onClick={() => handleConfirm(row.original.id)}
            >
              <DeleteIcon />
            </MDButton>
          </Tooltip>
        </>
      ),
    },
  ];

  if (isLoading) return <LoadingSpinner isLoading={isLoading} />;
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Modal
        ref={addBannerRef}
        closed={closeModal}
        children={
          <AddBanner
            closeModal={() => setCloseModal((prevState) => !prevState)}
            setData={setData}
          />
        }
        customStyle={{
          width: { xs: "90%", md: "60%" },
          maxHeight: "90vh",
          overflow: "auto",
        }}
      />
      <Modal
        ref={updateBannerRef}
        children={
          <UpdateBanner
            closeModal={updateBannerRef.current?.close}
            setData={setData}
            data={selectedBanner}
            setMaxPage={setMaxPage}
            limit={limit}
            page={page}
          />
        }
        customStyle={{
          width: { xs: "90%", md: "60%" },
          maxHeight: "90vh",
          overflow: "auto",
        }}
      />
      <Modal
        ref={deleteSectionRef}
        children={
          <ConfirmModal
            closeModal={deleteSectionRef.current?.close}
            deleteFunc={() => removeBanner()}
            title="Are you sure that you wish to delete this banner?"
          />
        }
      />
      <MDBox>
        <Table
          title="Banners"
          isLoading={isLoading}
          limit={limit}
          setLimit={setLimit}
          setPage={setPage}
          page={page}
          setIsLoading={setIsLoading}
          maxPage={maxPage}
          prevFunc={() => setPage(page - 1)}
          nextFunc={() => setPage(page + 1)}
          columns={columns}
          search={search}
          setSearch={setSearch}
          rows={data}
          customButton={{
            active: true,
            name: "Add Banner",
            func: () => setCloseModal(true),
          }}
        />
      </MDBox>
    </DashboardLayout>
  );
};
